export const removeDupFromArrayByProp = <T>(
  arr: T[],
  propName: keyof T
): T[] => {
  const uniqueValues = new Set();

  return arr.filter(item => {
    const value = item[propName];
    if (!uniqueValues.has(value)) {
      return !!uniqueValues.add(value);
    }
  });
};
