import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { capitaliseFirst } from '../../../functions/capitaliseFirst';
import { TaskModel, TaskModelStatusEnum } from '../../../swagger';
import { Grid } from '../../../types/grid';
import { SortableTableHeader } from '../../SortableTableHeader/SortableTableHeader';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import './ComplianceTasksList.scss';
import AdoptechOverflowLine from '../../AdoptechOverflowLine/AdoptechOverflowLine';

interface ComplianceTasksListProps {
  onEdit: (task: TaskModel) => void;
  tasks: TaskModel[];
}

export const ComplianceTasksList: React.FC<ComplianceTasksListProps> = ({
  onEdit,
  tasks,
}) => {
  const baseCss = 'complianceTasksList';

  return (
    <>
      <div className={baseCss}>
        <div className={baseCss + '--header'}>
          <div></div>
          <SortableTableHeader<TaskModel>
            columnName="name"
            grid={Grid.ComplianceTasks}
            label="Check"
            className={baseCss + '--name'}
            notInTable
          />{' '}
          <SortableTableHeader<TaskModel>
            columnName="assignee"
            grid={Grid.ComplianceTasks}
            label="Owner"
            className={baseCss + '--assignee'}
            notInTable
          />
        </div>
        <div>
          {tasks?.map(task => {
            return (
              <div
                key={task.id}
                className={baseCss + '--task'}
                onClick={() => onEdit(task)}
              >
                <div
                  className={baseCss + '--status' + ' tooltipHost'}
                  data-title={capitaliseFirst(task.status)}
                >
                  {task.status === TaskModelStatusEnum.Pending && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={`${baseCss}--statusPending`}
                    />
                  )}
                  {task.status === TaskModelStatusEnum.Compliant && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={`${baseCss}--statusCompliant`}
                    />
                  )}
                  {task.status === TaskModelStatusEnum.Failed && (
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className={`${baseCss}--statusFailed`}
                    />
                  )}
                </div>

                <div className={baseCss + '--name'}>
                  <AdoptechOverflowLine>{task.name}</AdoptechOverflowLine>
                </div>

                <div className={baseCss + '--assigneeColumn'}>
                  {task.assignee ? (
                    <UserAvatar
                      showTooltip
                      user={task.assignee}
                      size="small"
                      hideText
                    />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
