import { useDispatch, useSelector } from 'react-redux';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import React, { useEffect, useRef, useState } from 'react';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { fetchVendorActions } from '../store/calendarThunks';
import {
  CalendarItemModel,
  CalendarItemModelStatusEnum,
  LegalRegisterModel,
  PestelRegisterModel,
  VendorActionModel,
  VendorActionModelStatusEnum,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import '../CalendarPage/CalendarPageTable.scss';
import './CalendarHS.scss';
import { calendarRoute } from '../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { Grid } from '../../../types/grid';
import { selectSortSettings } from '../../../selectors/selectSortSettings';
import { sortCalendarItems } from '../selectors/selectCalendarHSItems';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { CalendarItemsList } from '../CalendarItemsList/CalendarItemsList';

interface CalendarHSProps {
  onClose: () => void;
  title: string;
  registerId: LegalRegisterModel['id'] | PestelRegisterModel['id'];
  registerType: 'LegalRegister' | 'PestelRegister';
}

export const mapVendorActionToItem = (
  vendorAction: VendorActionModel
): CalendarItemModel => {
  const mapStatus = (
    status: VendorActionModelStatusEnum
  ): CalendarItemModelStatusEnum => {
    if (status === VendorActionModelStatusEnum.Completed)
      return CalendarItemModelStatusEnum.Completed;
    if (status === VendorActionModelStatusEnum.Overdue)
      return CalendarItemModelStatusEnum.Overdue;
    return CalendarItemModelStatusEnum.Pending;
  };
  return {
    id: vendorAction.id,
    description: vendorAction.description,
    type: 'VendorAction',
    name: vendorAction.name,
    date: vendorAction.dueDate,
    status: mapStatus(vendorAction.status),
    owner: vendorAction.owner,
    eventType: vendorAction.actionType,
  };
};

export const CalendarHS: React.FC<CalendarHSProps> = ({
  title,
  registerId,
  registerType,
  onClose,
}) => {
  const baseCss = 'calendarHS';
  const drawerBackgroundRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: ApplicationState) => state.calendar.isFetchingVendorActions
  );
  const [items, setItems] = useState<CalendarItemModel[]>(null);

  const sortSetting = useSelector(selectSortSettings(Grid.Calendar));
  const sortedItems = (items || []).sort((a, b) =>
    sortCalendarItems(a, b, sortSetting.columnSort)
  );
  useEffect(() => {
    const onSuccess = (result: VendorActionModel[]) => {
      const calendarItems = result.map(vendorAction =>
        mapVendorActionToItem(vendorAction)
      );
      setItems(calendarItems);
    };
    dispatch(fetchVendorActions(registerId, registerType, onSuccess));
  }, []);
  return (
    <AdoptechHS
      title={title}
      show
      ref={drawerBackgroundRef}
      showConfirmationWarning={false}
      onClose={onClose}
      footer={
        <>
          <AdoptechButton
            onClick={() => drawerBackgroundRef.current.click()}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={onClose}
            variant={AdoptechButtonVariant.Primary}
          >
            Done
          </AdoptechButton>
        </>
      }
      extraClass="adoptechHS--medium"
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className={baseCss + '--header'}>
            <div className={baseCss + '--counter'}>
              Actions <Lozenge value={(items || []).length} />
            </div>
            <div className={baseCss + '--calendarButton'}>
              <AdoptechButton
                variant={AdoptechButtonVariant.White}
                extraClass="adoptechButton-smallRounded"
                onClick={() => dispatch(push(calendarRoute))}
              >
                Go to Actions & Events Calendar
              </AdoptechButton>
            </div>
          </div>
          <CalendarHSList items={sortedItems}></CalendarHSList>
        </div>
      )}
    </AdoptechHS>
  );
};

interface CalendarHSListProps {
  items: CalendarItemModel[];
  showAllItems?: boolean;
  extraClass?: 'wide-table';
}

export const CalendarHSList: React.FC<CalendarHSListProps> = props => {
  const dispatch = useDispatch();
  if (props.items.length === 0) {
    return (
      <div style={{ marginTop: '20px' }}>There are no actions created.</div>
    );
  }

  return (
    <CalendarItemsList
      items={props.items}
      onClick={_ => {
        dispatch(
          showGlobalToast(
            'For view details or edit action please visit actions and events page.'
          )
        );
      }}
      showAllItems={props.showAllItems}
      extraClass={props.extraClass}
      showTooltip={false}
    />
  );
};
