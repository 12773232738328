import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { clearCurrentAgreementSignatories } from '../../../store/agreements/agreementsSlice';
import {
  fetchAgreementSignatoriesById,
  sendToReviewAgreement,
} from '../../../store/agreements/agreementsThunks';
import { showGlobalToast } from '../../../store/global/globalSlice';
import {
  AgreementExtended,
  AgreementSignatoryModel,
  AgreementSignatoryModelStatusEnum,
  AgreementSignatoryModelTypeEnum,
  VendorUser,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { AdoptechCheckbox } from '../../AdoptechCheckbox/AdoptechCheckbox';
import AdoptechModal from '../../AdoptechModal/AdoptechModal';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { NoDataText } from '../../NoDataText/NoDataText';
import { agreementsPageRoute } from '../../Routes/Routes';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import './AgreementSendReviewModal.scss';

interface AgreementSendReviewModalProps {
  agreementId: AgreementExtended['id'];
  onCancel: () => void;
}

export const useAgreementSignatories = () => {
  const isRequestingSignatories = useSelector(
    (state: ApplicationState) =>
      state.agreements.isRequestingCurrentAgreementSignatories
  );

  const internalSignatories = useSelector(
    (state: ApplicationState) =>
      state.agreements.currentAgreementSignatories?.filter(
        s => s.type === AgreementSignatoryModelTypeEnum.Internal
      ) || []
  );
  const externalSignatories = useSelector(
    (state: ApplicationState) =>
      state.agreements.currentAgreementSignatories?.filter(
        s => s.type !== AgreementSignatoryModelTypeEnum.Internal
      ) || []
  );

  return { internalSignatories, externalSignatories, isRequestingSignatories };
};

export const signatoryUser = (signatory: AgreementSignatoryModel) => {
  if (signatory.type === AgreementSignatoryModelTypeEnum.Internal)
    return signatory.user;
  const { firstName, lastName, email } = signatory;
  const fullName = firstName ? `${firstName} ${lastName}`.trim() : email;
  const user: VendorUser = { ...signatory, fullName };
  return user;
};

export const AgreementSendReviewModal: React.FC<
  AgreementSendReviewModalProps
> = ({ agreementId, onCancel }) => {
  const baseCss = 'agreementSendReviewModal';
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: ApplicationState) => state.user.userDetails
  );
  useEffect(() => {
    const onSuccess = (signatories: AgreementSignatoryModel[]) => {
      const getDefaultSelectedSignatoriesIds = signatories
        .filter(signatory => signatory.user?.email !== currentUser.email)
        .map(signatory => signatory.id);
      setSelectedSignatoriesIds(getDefaultSelectedSignatoriesIds);
    };
    dispatch(fetchAgreementSignatoriesById(agreementId, onSuccess));
    return () => {
      dispatch(clearCurrentAgreementSignatories());
    };
  }, [agreementId]);

  const { isRequestingSignatories, internalSignatories, externalSignatories } =
    useAgreementSignatories();

  const isSendingToReview = useSelector(
    (state: ApplicationState) => state.agreements.isSendingToReview
  );
  const [selectedSignatoriesIds, setSelectedSignatoriesIds] = useState<
    AgreementSignatoryModel['id'][]
  >([]);

  const signatoriesList = (signatories: AgreementSignatoryModel[]) =>
    signatories.map(signatory => {
      const { id } = signatory;
      return (
        <div key={id} className={baseCss + '--signatoriesRow'}>
          <AdoptechCheckbox
            checked={selectedSignatoriesIds.includes(id)}
            onChange={e => {
              const newSignatories = e.currentTarget.checked
                ? [...selectedSignatoriesIds, id]
                : selectedSignatoriesIds.filter(
                    signatoryId => signatoryId !== id
                  );
              setSelectedSignatoriesIds(newSignatories);
            }}
            id={id}
          />
          <UserAvatar user={signatoryUser(signatory)} />
        </div>
      );
    });

  const handleDone = () => {
    dispatch(
      sendToReviewAgreement({
        agreementId,
        signatoryIds: selectedSignatoriesIds,
        onSuccess: () => {
          onCancel();
          showGlobalToast('Your agreement has been sent for signing.');
          dispatch(push(agreementsPageRoute));
        },
      })
    );
  };

  return (
    <AdoptechModal show onHide={onCancel} className={baseCss}>
      <Modal.Header>Send for review</Modal.Header>
      <Modal.Body>
        {isRequestingSignatories && <LoadingSpinner />}
        {!isRequestingSignatories && (
          <>
            <div className={baseCss + '--description'}>
              Send an email with a link to the agreement.
            </div>
            <div className={baseCss + '--signatoriesHeader'}>Counterpart</div>
            {externalSignatories.length === 0 && (
              <NoDataText text="No external signatories to select" />
            )}
            {signatoriesList(externalSignatories)}
            <div className={baseCss + '--signatoriesHeader'}>
              Internal signatories
            </div>
            {internalSignatories.length === 0 && (
              <NoDataText text="No internal signatories to select" />
            )}
            {signatoriesList(internalSignatories)}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          disabledTooltip="You need to select at least one signatory"
          onClick={handleDone}
          busy={isSendingToReview}
          disabled={
            isRequestingSignatories ||
            isSendingToReview ||
            selectedSignatoriesIds.length === 0
          }
          variant={AdoptechButtonVariant.Primary}
        >
          Send
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
