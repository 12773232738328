import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faCheckCircle as solidFaCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { canFeature } from '../../../functions/access';
import { ControlExtended, TaskModelStatusEnum } from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { MeatballMenu } from '../../MeatballMenu/MeatballMenu';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import './ComplianceControlTasksList.scss';
import { useOnTaskViewClick } from '../../../hooks/compliance/useOnTaskViewClick';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';

export interface ComplianceTasksListProps {
  control: ControlExtended;
  onRemoveTask: (control: ControlExtended, taskId: string) => void;
}

export const ComplianceControlTasksList: React.FC<ComplianceTasksListProps> = ({
  control,
  onRemoveTask = () => {},
}) => {
  const baseCss = 'complianceControlTasksList';

  const canManageTasks = canFeature(AccessObject.tasks_manage);
  const { onTaskViewClick } = useOnTaskViewClick();

  return (
    <>
      <div className={baseCss + '--tasks'}>
        {control?.tasks?.map(task => (
          <div
            className={baseCss + '--taskHeader'}
            key={task.id}
            onClick={e => {
              e.stopPropagation();
              onTaskViewClick(task.id);
            }}
          >
            <div className={baseCss + '--icon'}>
              <FontAwesomeIcon
                icon={
                  task.status === TaskModelStatusEnum.Failed
                    ? faExclamationCircle
                    : solidFaCheckCircle
                }
                className={
                  task.status === TaskModelStatusEnum.Compliant
                    ? baseCss + '--greenTick'
                    : task.status === TaskModelStatusEnum.Failed
                      ? baseCss + '--redTick'
                      : baseCss + '--greyTick'
                }
              />
            </div>
            <div className={baseCss + '--name'}>{task.name}</div>
            <div className={baseCss + '--owner'}>
              <UserAvatar user={task.assignee} size="small" />
            </div>
            <MeatballMenu>
              <Dropdown.Item
                onClick={e => {
                  e.stopPropagation();
                  onTaskViewClick(task?.id);
                }}
              >
                <FontAwesomeIcon className="meatballMenu--icon" icon={faPen} />
                Edit
              </Dropdown.Item>
              {canManageTasks && (
                <>
                  <Dropdown.Item
                    key={task?.id}
                    onClick={e => {
                      e.stopPropagation();
                      onRemoveTask(control, task?.id);
                    }}
                  >
                    <FontAwesomeIcon
                      className="meatballMenu--icon"
                      icon={faBan}
                    />
                    Remove
                  </Dropdown.Item>
                </>
              )}
            </MeatballMenu>
          </div>
        ))}
      </div>
    </>
  );
};
