import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedFramework } from '../../../selectors/selectSelectedFramework';
import {
  clearToastMessage,
  setCategoriesFilters,
  setControlFormModel,
  setEditControlMode,
  setSelectedCategoryId,
  setSelectedControlId,
  setSelectedFrameworkId,
  setShowControlDrawer,
  setShowTaskDrawer,
} from '../../../store/compliance/complianceSlice';
import {
  fetchComplianceCategories,
  fetchComplianceControls,
  fetchComplianceFrameworks,
  fetchComplianceTasks,
  fetchNotApplicableComplianceControls,
} from '../../../store/compliance/complianceThunks';
import { ApplicationState } from '../../../types/applicationState';
import { AdoptechAccordionCard } from '../../AdoptechAccordionCard/AdoptechAccordionCard';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { AdoptechTextInput } from '../../AdoptechTextInput/AdoptechTextInput';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Intent, Lozenge } from '../../Lozenge/Lozenge';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import { ComplianceControlsList } from '../ComplianceControlsList/ComplianceControlsList';
import './ComplianceControls.scss';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { selectFilteredCategories } from '../../../selectors/selectFilteredCategories';
import { AdoptechReactSelect } from '../../AdoptechReactSelect/AdoptechReactSelect';
import { useAssigneeFilter } from '../../../hooks/compliance/useAssigneeFilter';
import { SelectionOption } from '../../../types/selectionOption';
import { MessageToast } from '../../MessageToast/MessageToast';
import { EditControl } from '../Types/complianceTypes';
import { ComplianceEditControlDrawer } from '../../../features/compliance/controls/EditControlDrawer/ComplianceEditControlDrawer/ComplianceEditControlDrawer';
import { ComplianceEditTaskDrawer } from '../../../features/compliance/controls/EditControlCheckDrawer/ComplianceEditTaskDrawer/ComplianceEditTaskDrawer';
import { fetchPolicies } from '../../../store/policies/policiesThunks';
import { NotApplicableControls } from '../../NotApplicableControls/NotApplicableControls';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import { getCategoryCompletionRate } from '../../../functions/getCategoryCompletionRate';
import { setPageHeaderLabel } from '../../../store/global/globalSlice';
import { EditControlMode } from '../../../store/compliance/complianceState';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { AdoptechDropdownToggle } from '../../AdoptechDropdownToggle/AdoptechDropdownToggle';
import { push } from 'connected-react-router';
import {
  reportsRouteWithType,
  reportsSoaRoute,
} from '../../Reports/ReportsPage/ReportsPage';
import { ReportCreatePayloadReportFrameworkEnum } from '../../../swagger';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { PaymentLandingPageChecker } from '../../../features/pricing/PaymentLandingPageChecker/PaymentLandingPageChecker';
import NoValidRoleText from '../../../features/pricing/NoValidRoleText/NoValidRoleText';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { useParams } from 'react-router';

export const ComplianceControls: React.FC = () => {
  const baseCss = 'complianceControls';
  const dispatch = useDispatch();

  const selectedFramework = useSelector(selectSelectedFramework);

  const categories = useSelector(
    (state: ApplicationState) => state.compliance.categories
  );

  const isFetchingCategories = useSelector(
    (state: ApplicationState) => state.compliance.isFetchingCategories
  );

  const isFetchingControls = useSelector(
    (state: ApplicationState) => state.compliance.isFetchingControls
  );

  const isFetchingPolicies = useSelector(
    (state: ApplicationState) => state.policies.isFetchingPolicies
  );

  const queryParams = useQueryParams();
  const [search, setSearch] = useState(queryParams.get('q') || '');
  const assigneeId = queryParams.get('assignee_id');
  const params = useParams() as { id: string };

  useEffect(() => {
    dispatch(setCategoriesFilters({ assigneeId }));
  }, [assigneeId]);

  const filters = useSelector(
    (state: ApplicationState) => state.compliance.categoriesFilters
  );
  const { assigneeSelectOptions, currentAssignee } = useAssigneeFilter(
    categories.map(category => category.controls).flat(),
    filters
  );
  const filteredCategories = useSelector(selectFilteredCategories(search));

  const controlsCount = useMemo(() => {
    return filteredCategories?.reduce((acc, category) => {
      acc += category.controls.length;
      return acc;
    }, 0);
  }, [filteredCategories]);

  const canSeeControls = canFeature(AccessObject.controls);

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  const vendorUser = useSelector(selectCurrentVendorUser);

  const toastMessage = useSelector(
    (state: ApplicationState) => state.compliance.toastMessage
  );

  const toastVariant = useSelector(
    (state: ApplicationState) => state.compliance.toastVariant
  );

  const showControlDrawer = useSelector(
    (state: ApplicationState) => state.compliance.showControlDrawer
  );

  const showTaskDrawer = useSelector(
    (state: ApplicationState) => state.compliance.showTaskDrawer
  );

  const selectedCategory = useSelector((state: ApplicationState) =>
    state.compliance.categories?.find(
      c => c.id === state.compliance.selectedCategoryId
    )
  );

  const selectedControl = useSelector((state: ApplicationState) =>
    selectedCategory?.controls
      ?.concat(state.compliance.notApplicableControls)
      .find(c => c.id === state.compliance.selectedControlId)
  );

  useEffect(() => {
    if (!selectedFramework) return;
    dispatch(setPageHeaderLabel(selectedFramework.name));

    if (!canSeeControls) return;

    dispatch(fetchComplianceControls({ vendorId }));
    dispatch(fetchComplianceCategories(vendorId, selectedFramework.id));
    dispatch(fetchComplianceTasks({ vendorId }));
    dispatch(fetchNotApplicableComplianceControls(vendorId));
    dispatch(fetchPolicies());
  }, [vendorId, selectedFramework?.id, vendorUser.roles]);

  useEffect(() => {
    const fetchFrameworks = async () => {
      await dispatch(fetchComplianceFrameworks(vendorId));
      dispatch(setSelectedFrameworkId(params.id)); // fixes empty framework after page reload
    };
    fetchFrameworks();
  }, [vendorId, vendorUser.roles]);

  const canManageReports = canFeature(AccessObject.reports_manage);
  const canManageControls = canFeature(AccessObject.controls_manage);

  if (!canSeeControls) {
    return <NoValidRoleText />;
  }

  if (isFetchingCategories || isFetchingControls || isFetchingPolicies) {
    return <LoadingSpinner />;
  }

  return (
    <PaymentLandingPageChecker type="complianceFrameworks">
      <div className={baseCss}>
        <div className={baseCss + '--header'}>
          <div className={baseCss + '--title'}>
            {selectedFramework?.name} Controls
            <Lozenge intent={Intent.None} value={controlsCount} />
          </div>
          <div className={baseCss + '--search'}>
            <AdoptechTextInput
              id="search"
              value={search}
              onChange={e => setSearch(e.target.value)}
              type="text"
              placeholder="Search controls"
              icon={faSearch}
              additionalClass="adoptechTextInput-search"
            />
          </div>
          {Object.values(ReportCreatePayloadReportFrameworkEnum).includes(
            selectedFramework?.identifier as ReportCreatePayloadReportFrameworkEnum
          ) && (
            <div className={baseCss + '--buttons'}>
              <Dropdown className={baseCss + '--reportsButton'}>
                <Dropdown.Toggle as={AdoptechDropdownToggle}>
                  <AdoptechButton
                    variant={AdoptechButtonVariant.Default}
                    icon={faEye}
                    iconSize="large"
                    rounded
                    extraClass={`${baseCss}--reports-btn`}
                  >
                    Reports
                  </AdoptechButton>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {canManageReports && (
                    <Dropdown.Item
                      onClick={() =>
                        dispatch(
                          push(
                            reportsSoaRoute(
                              selectedFramework?.identifier as ReportCreatePayloadReportFrameworkEnum
                            )
                          )
                        )
                      }
                    >
                      Add report
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => dispatch(push(reportsRouteWithType('soa')))}
                  >
                    View reports
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {canManageControls && (
                <AdoptechButton
                  onClick={() => {
                    dispatch(setEditControlMode(EditControlMode.Normal));
                    dispatch(setSelectedControlId(undefined));
                    dispatch(setControlFormModel(EditControl.createNew()));
                    dispatch(setShowControlDrawer(true));
                  }}
                  variant={AdoptechButtonVariant.PrimaryOrange}
                  icon={faPlus}
                  iconSize="large"
                  rounded
                >
                  Add control
                </AdoptechButton>
              )}
            </div>
          )}
        </div>
        <div className={baseCss + '--description'}>
          Controls are processes that you implement to modify risks. They
          describe how you are going to meet policies.{' '}
        </div>

        <div className={baseCss + '--filters'}>
          {assigneeSelectOptions.length > 0 && (
            <div className={baseCss + '--assigneeSelect'}>
              <AdoptechReactSelect
                id="assigneeSelect"
                options={[{ label: 'Select owner', value: '' }].concat(
                  assigneeSelectOptions
                )}
                onChange={(option: SelectionOption) => {
                  dispatch(setCategoriesFilters({ assigneeId: option.value }));
                }}
                value={currentAssignee}
                placeholder="Select owner"
                showUserAvatar
              />
            </div>
          )}
        </div>
        <MessageToast
          autohide
          variant={toastVariant}
          delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
          onClose={() => dispatch(clearToastMessage())}
          show={Boolean(toastMessage)}
        >
          {toastMessage}
        </MessageToast>
        <ComplianceEditControlDrawer
          show={showControlDrawer}
          control={selectedControl}
          onClose={() => dispatch(setShowControlDrawer(false))}
        />
        <ComplianceEditTaskDrawer
          show={showTaskDrawer}
          onClose={() => dispatch(setShowTaskDrawer(false))}
        />
        {selectedFramework ? (
          <div className="mt-2">
            <Accordion key={selectedFramework.id}>
              {filteredCategories?.map(cat => {
                const percentage = getCategoryCompletionRate(cat);
                return (
                  <AdoptechAccordionCard
                    headerClass={baseCss + '--accordionCategoryHeader'}
                    key={cat.id}
                    index={cat.id}
                    scrollDisabled
                    callbackOnOpen={categoryId =>
                      dispatch(setSelectedCategoryId(categoryId))
                    }
                    title={
                      <div className={baseCss + '--categoryHeader'}>
                        <div>{cat.name}</div>
                        <div className={baseCss + '--categoryHeader-progress'}>
                          <div>{percentage.toFixed(0)}%</div>
                          <div
                            className={baseCss + '--categoryHeader-progressBar'}
                          >
                            <ProgressBar progress={percentage} />
                          </div>
                        </div>
                      </div>
                    }
                    nested
                  >
                    <ComplianceControlsList category={cat} />
                  </AdoptechAccordionCard>
                );
              })}
            </Accordion>
          </div>
        ) : (
          <div>No controls to display</div>
        )}

        <NotApplicableControls vendorId={vendorId} />
      </div>
    </PaymentLandingPageChecker>
  );
};
