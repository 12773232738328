import { Theme } from 'react-select';

export const reactSelectCustomTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#666665',
    primary25: '#cccccc',
    primary50: '#f1881f',
  },
});

export const reactSelectLightTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#ccc',
    primary25: '#cccccc',
    primary50: '#f1881f',
  },
});

export const reactSelectLightStyle = {
  control: {
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #ddd',
    height: '32px',
    minHeight: '32px',
  },
  indicatorSeparator: {
    display: 'none',
  },
  valueContainer: {
    height: '32px',
  },
  indicatorsContainer: {
    height: '32px',
  },
};

export const reactSelectLightStyleSmall = {
  ...reactSelectLightStyle,
  control: {
    ...reactSelectLightStyle.control,
    paddingLeft: 10,
    fontSize: 14,
  },
};
