import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudDownload,
  faSpinner,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { ApplicationState } from '../../../../types/applicationState';
import { AdoptechFileInput } from '../../../../components/AdoptechFileInput/AdoptechFileInput';
import { AdoptechTextInput2 } from '../../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { isValidUrl } from '../../../../functions/isValidUrl';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import {
  downloadActionAttachment,
  downloadCorrectiveActionAttachment,
  downloadEventAttachment,
  downloadIncidentAttachment,
} from '../../store/calendarThunks';
import {
  ActionFormType,
  CorrectiveActionFormType,
  EventFormType,
  IncidentFormType,
} from '../../CalendarPage/CalendarPage';

export type CalendarModelExtended =
  | EventFormType
  | ActionFormType
  | CorrectiveActionFormType
  | IncidentFormType;
interface CalendarAttachmentsProps {
  model: CalendarModelExtended;
  type: 'event' | 'action' | 'incident' | 'corrective-action';
  setModel: React.Dispatch<React.SetStateAction<CalendarModelExtended>>;
  baseCss: string;
  readonlyMode: boolean;
}

const CalendarAttachments: React.FC<CalendarAttachmentsProps> = ({
  model,
  setModel,
  baseCss,
  readonlyMode,
  type,
}) => {
  const dispatch = useAppDispatch();
  const {
    downloadEventAttachmentStatus,
    downloadActionAttachmentStatus,
    downloadIncidentAttachmentStatus,
    downloadCorrectiveActionAttachmentStatus,
  } = useSelector((state: ApplicationState) => state.calendar);
  const handleDownloadAttachment = async (
    e: React.MouseEvent,
    attachment: CalendarAttachmentsProps['model']['attachments'][0]
  ) => {
    const notSavedYet = !attachment.id;
    if (notSavedYet) {
      const tempLink = document.createElement('a');
      tempLink.href = attachment.url;
      tempLink.download = attachment.filename;
      tempLink.target = '_blank';
      tempLink.click();
      return;
    }
    const thunks = {
      event: downloadEventAttachment,
      action: downloadActionAttachment,
      incident: downloadIncidentAttachment,
      'corrective-action': downloadCorrectiveActionAttachment,
    };

    const thunk = thunks[type];
    const attachmentDetails = await dispatch(
      thunk({
        id: model.id,
        attachmentId: attachment.id,
      })
    ).unwrap();
    const tempLink = document.createElement('a');
    tempLink.href = attachmentDetails.url;
    tempLink.download = attachmentDetails.filename;
    tempLink.target = '_blank';
    tempLink.click();
  };

  const handleRemoveAttachment = (
    e: React.MouseEvent,
    attachment: CalendarAttachmentsProps['model']['attachments'][0]
  ) => {
    setModel(prevModel => {
      const newAttachments = prevModel.attachments.filter(
        attachmentDetails => attachmentDetails !== attachment
      );
      const deletedAttachmentIds = [
        ...(prevModel.deletedAttachmentIds || []),
        attachment.id,
      ].filter(id => id);

      return {
        ...prevModel,
        attachments: newAttachments,
        deletedAttachmentIds,
      };
    });
  };

  const isDownloading =
    downloadEventAttachmentStatus === 'loading' ||
    downloadActionAttachmentStatus === 'loading' ||
    downloadIncidentAttachmentStatus === 'loading' ||
    downloadCorrectiveActionAttachmentStatus === 'loading';

  return (
    <>
      <div className={baseCss + '--fieldRow'}>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            id="createEventURL"
            rounded
            label="Link"
            type="text"
            hasError={model.url && !isValidUrl(model.url)}
            placeholder="http://www.example.com"
            value={model.url}
            onChange={e => setModel({ ...model, url: e.currentTarget.value })}
            icon={faPen}
            disabled={readonlyMode}
          />
        </div>
      </div>
      {!readonlyMode && (
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <AdoptechFileInput
              label="Attach file"
              name="file"
              multiple
              urls={[]}
              placeholder={
                (model.attachments || []).length > 0 ? '' : 'No files uploaded'
              }
              accept=".pdf, .doc, .docx"
              onChange={files => {
                const newFiles = files.map(file => {
                  return {
                    id: null,
                    filename: file.name,
                    url: URL.createObjectURL(file),
                    rawFile: file,
                    contentType: file.type,
                  };
                });
                const newAttachments = [
                  ...(model.attachments || []),
                  ...newFiles,
                ];
                setModel({
                  ...model,
                  attachments: newAttachments,
                });
              }}
              extraClass="no-gap"
            />
          </div>
        </div>
      )}
      {(model.attachments || []).length > 0 && (
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            {model.attachments
              .sort((a, b) => a.filename.localeCompare(b.filename))
              .map(attachment => {
                return (
                  <div
                    key={attachment.id + attachment.filename + attachment.url}
                    className={baseCss + '--attachment'}
                  >
                    <a
                      href="#"
                      onClick={e => handleDownloadAttachment(e, attachment)}
                      className={classNames([baseCss + '--link'], {
                        disabled: isDownloading,
                      })}
                    >
                      {attachment.filename}
                      <FontAwesomeIcon
                        className={classNames(['icon'], {
                          'fa-spin': isDownloading,
                        })}
                        icon={isDownloading ? faSpinner : faCloudDownload}
                      />
                    </a>
                    <FontAwesomeIcon
                      className="icon"
                      icon={faTrashAlt}
                      onClick={e => handleRemoveAttachment(e, attachment)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default CalendarAttachments;
