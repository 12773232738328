import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrustHubSections } from '../../../../store/trusthubThunks';
import { ApplicationState } from '../../../../../../types/applicationState';
import { selectCurrentVendor } from '../../../../../../selectors/selectCurrentVendor';
import { AddDocumentPermissionFormData } from '../../TrusthubUserAdminAddDocumentPermissionDrawer/TrusthubUserAdminAddDocumentPermissionDrawer';
import { DocumentPermissionTableRow } from '../../TrusthubUserAdminAddDocumentPermissionDrawer/DocumentPermissionTableRow/DocumentPermissionTableRow';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import { TrusthubDocumentModel } from '../../../../../../swagger/trusthub/models/TrusthubDocumentModel';
import '../../TrusthubUserAdminAddDocumentPermissionDrawer/TrusthubUserAdminAddDocumentPermissionDrawer.scss';
import { TrusthubAccessRequestModel } from '../../../../../../swagger/trusthub';

interface TrusthubUserAdminDocumentsListProps {
  documents: TrusthubDocumentModel[];
  request: TrusthubAccessRequestModel;
  formData: AddDocumentPermissionFormData;
  setFormData: React.Dispatch<
    React.SetStateAction<AddDocumentPermissionFormData>
  >;
}
export const TrusthubUserAdminDocumentsList: React.FC<
  TrusthubUserAdminDocumentsListProps
> = ({ documents, formData, setFormData, request }) => {
  const baseCss = 'trusthubUserAdminAddDocumentsList';
  const dispatch = useDispatch();
  const { fetchingSectionsStatus, sections } = useSelector(
    (state: ApplicationState) => state.trustHub
  );
  const currentVendor = useSelector(selectCurrentVendor);
  useEffect(() => {
    dispatch(
      fetchTrustHubSections(
        {
          vendorIdOrDomain: currentVendor?.id,
          trusthubVendorUserId: request.trusthubVendorUser?.id,
        },
        () => {}
      )
    );
  }, [currentVendor.id]);

  // We don't need all documents inside section => Only documents requested in access request
  const requestDocsIds = useMemo(
    () => request.trusthubDocuments.map(doc => doc.id),
    [request]
  );
  const filteredSections = [...sections]
    .map(section => {
      return {
        ...section,
        ...{
          trusthubDocuments: section.trusthubDocuments.filter(doc =>
            requestDocsIds.includes(doc.id)
          ),
        },
      };
    })
    .filter(section => section.visible)
    .filter(section => section.trusthubDocuments.length > 0);

  const sectionsList = useMemo(() => {
    return filteredSections
      .sort((a, b) => a.rowOrder || 0 - b.rowOrder || 0)
      .map(section => {
        return (
          <DocumentPermissionTableRow
            key={section.id}
            section={section}
            formData={formData}
            setFormData={setFormData}
          />
        );
      });
  }, [sections, formData]);

  return (
    <>
      {fetchingSectionsStatus === 'loading' ? (
        <LoadingSpinner />
      ) : (
        <div className={baseCss}>
          {filteredSections.length > 0 ? (
            <>
              <div className={baseCss + '--label'}>
                {request.trusthubVendorUser?.fullName} has requested access to
                the following documents. Select the document(s) you would like
                to grant access to. Any documents not selected will have their
                access request declined.
              </div>
              <div className={baseCss + '--fieldRow'}>{sectionsList}</div>
            </>
          ) : (
            <div className={baseCss + '--label'}>
              There are no available documents for request.
            </div>
          )}
        </div>
      )}
    </>
  );
};
