import React, { MouseEventHandler } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FrameworkModel,
  FrameworkRelation,
  VendorPolicyDetails,
} from '../../swagger';
import { selectVendorPolicyToEdit } from '../../selectors/selectVendorPolicyToEdit';
import { complianceControlsRoute } from '../Routes/Routes';
import { push } from 'connected-react-router';
import { setSelectedFramework } from '../../store/compliance/complianceSlice';

import './PolicyFrameworksList.scss';

export const PolicyFrameworksList: React.FC = () => {
  const baseCss = 'policyFrameworksList';
  const policy = useSelector(selectVendorPolicyToEdit) as VendorPolicyDetails;
  const dispatch = useDispatch();

  const navigateToFrameworkControls =
    (fw: FrameworkRelation['framework']): MouseEventHandler<HTMLDivElement> =>
    e => {
      e.stopPropagation();
      dispatch(setSelectedFramework(fw as FrameworkModel));
      dispatch(push(complianceControlsRoute));
    };

  if (!policy) return <></>;

  if (policy.frameworksRelations.length < 1) {
    return (
      <>
        <div className={baseCss + '--section-labels'}>
          <span className={baseCss + '--section-label'}>Frameworks</span>
        </div>
        <div className={baseCss + '--empty'}>
          There are no framework references for this policy yet
        </div>
      </>
    );
  }

  return (
    <>
      <div className={baseCss + '--section-labels'}>
        <span className={baseCss + '--section-label'}>Frameworks</span>
      </div>
      <div className={baseCss + '--frameworks'}>
        <div className={baseCss + '--headers'}>
          <div className={baseCss + '--label'}>Framework</div>
          <div className={baseCss + '--label'}>Category</div>
          <div className={baseCss + '--label'}>Reference</div>
        </div>
        {policy.frameworksRelations?.map(r => (
          <div className={baseCss + '--frameworksRow'} key={r.framework.id}>
            <div
              className={baseCss + '--framework'}
              onClick={navigateToFrameworkControls(r.framework)}
            >
              {r.framework.name}
            </div>
            <div className={baseCss + '--category'}>
              {r.controlCategory.name}
            </div>
            <div className={baseCss + '--reference'}>{r.annexRef}</div>
          </div>
        ))}
      </div>
    </>
  );
};
