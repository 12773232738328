/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AssertionExtended,
  AssertionExtendedFromJSON,
  AssertionExtendedFromJSONTyped,
  AssertionExtendedToJSON,
  ControlModel,
  ControlModelFromJSON,
  ControlModelFromJSONTyped,
  ControlModelToJSON,
  EvidenceModel,
  EvidenceModelFromJSON,
  EvidenceModelFromJSONTyped,
  EvidenceModelToJSON,
  SharedTaskRecurring,
  SharedTaskRecurringFromJSON,
  SharedTaskRecurringFromJSONTyped,
  SharedTaskRecurringToJSON,
  TaskExtendedAllOf,
  TaskExtendedAllOfFromJSON,
  TaskExtendedAllOfFromJSONTyped,
  TaskExtendedAllOfToJSON,
  TaskModel,
  TaskModelFromJSON,
  TaskModelFromJSONTyped,
  TaskModelToJSON,
  VendorActionModel,
  VendorActionModelFromJSON,
  VendorActionModelFromJSONTyped,
  VendorActionModelToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface TaskExtended
 */
export interface TaskExtended extends TaskModel {
  /**
   *
   * @type {Array<ControlModel>}
   * @memberof TaskExtended
   */
  controls?: Array<ControlModel>;
  /**
   *
   * @type {Array<EvidenceModel>}
   * @memberof TaskExtended
   */
  evidences?: Array<EvidenceModel>;
  /**
   *
   * @type {Array<AssertionExtended>}
   * @memberof TaskExtended
   */
  assertions?: Array<AssertionExtended>;
  /**
   *
   * @type {Array<VendorActionModel>}
   * @memberof TaskExtended
   */
  vendorActions?: Array<VendorActionModel>;
}

export function TaskExtendedFromJSON(json: any): TaskExtended {
  return TaskExtendedFromJSONTyped(json, false);
}

export function TaskExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TaskExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...TaskModelFromJSONTyped(json, ignoreDiscriminator),
    controls: !exists(json, 'controls')
      ? undefined
      : (json['controls'] as Array<any>).map(ControlModelFromJSON),
    evidences: !exists(json, 'evidences')
      ? undefined
      : (json['evidences'] as Array<any>).map(EvidenceModelFromJSON),
    assertions: !exists(json, 'assertions')
      ? undefined
      : (json['assertions'] as Array<any>).map(AssertionExtendedFromJSON),
    vendorActions: !exists(json, 'vendor_actions')
      ? undefined
      : (json['vendor_actions'] as Array<any>).map(VendorActionModelFromJSON),
  };
}

export function TaskExtendedToJSON(value?: TaskExtended | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...TaskModelToJSON(value),
    controls:
      value.controls === undefined
        ? undefined
        : (value.controls as Array<any>).map(ControlModelToJSON),
    evidences:
      value.evidences === undefined
        ? undefined
        : (value.evidences as Array<any>).map(EvidenceModelToJSON),
    assertions:
      value.assertions === undefined
        ? undefined
        : (value.assertions as Array<any>).map(AssertionExtendedToJSON),
    vendor_actions:
      value.vendorActions === undefined
        ? undefined
        : (value.vendorActions as Array<any>).map(VendorActionModelToJSON),
  };
}
