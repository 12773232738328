import { PdfOpenMode } from '../types/pdfOpenMode';
// import { agreementsPageRoute } from '../components/Routes/Routes';
// Only in this file agreementsPageRoute or any constant is empty.
// Looks like it's a cycle dependency.
export const PdfPreviewEntityPaths = {
  policies: '/r/governance/policies',
  agreements: '/r/governance/agreements',
  documents: '/r/companyInformation/documents',
  controls: '/r/compliance/frameworks/:id/controls',
  audits: '/r/compliance/audits',
};

export const getPdfDocumentPath = ({
  vendorDocumentId,
  mode,
  basePath,
  vendorId,
  documentableId,
}: {
  vendorDocumentId?: string;
  mode: PdfOpenMode;
  basePath: string;
  vendorId?: string;
  documentableId?: string;
}): string => {
  const search = new URLSearchParams();
  vendorDocumentId && search.append('vendorDocumentId', vendorDocumentId);
  vendorId && search.append('vendorId', vendorId);
  mode && search.append('mode', mode);
  documentableId && search.append('documentableId', documentableId);

  const searchString = search.toString();
  return `${basePath}?${searchString}`;
};
