import { createSelector } from '@reduxjs/toolkit';
import { sort } from '../../../../functions/sort';
import { sortByOwner } from '../../../../functions/sortByOwner';
import { sortByDate } from '../../../../functions/sortByDate';
import { ColumnSort } from '../../../../types/columnSort';
import { selectSortSettings } from '../../../../selectors/selectSortSettings';
import { ApplicationState } from '../../../../types/applicationState';
import { Grid } from '../../../../types/grid';
import Fuse from 'fuse.js';
import {
  TrusthubCompanyStatus,
  TrusthubCompanyModel,
  TrusthubVendorUserModel,
} from '../../../../swagger/trusthub';

const sortCompanies = (
  a: TrusthubCompanyModel,
  b: TrusthubCompanyModel,
  columnSort: ColumnSort
) => {
  const typedColumn = columnSort.name as keyof TrusthubCompanyModel;

  if (['ndaStatus'].includes(typedColumn)) {
    return sort(
      a.status === TrusthubCompanyStatus.WaitingForNda,
      b.status === TrusthubCompanyStatus.WaitingForNda,
      columnSort.direction
    );
  }

  if (['owner'].includes(typedColumn)) {
    return sort(
      a.relationshipOwners.map(owner => owner.fullName).join(',') || '',
      b.relationshipOwners.map(owner => owner.fullName).join(',') || '',
      columnSort.direction
    );
  }

  if (['createdAt'].includes(typedColumn)) {
    const dateColumn = typedColumn as 'createdAt';
    return sortByDate(a[dateColumn], b[dateColumn], columnSort.direction);
  }

  if (['createdBy'].includes(typedColumn)) {
    return sortByOwner(
      a[typedColumn] as TrusthubVendorUserModel,
      b[typedColumn] as TrusthubVendorUserModel,
      columnSort.direction
    );
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

const searchCompanies = (requests: TrusthubCompanyModel[], search: string) => {
  let filteredCompanies = requests;
  const fuse = new Fuse(requests, {
    ignoreLocation: true,
    includeScore: true,
    keys: ['name', 'status', 'createdBy.fullName', 'createdAt'],
    threshold: 0,
  });

  if (search) {
    filteredCompanies = fuse.search(search).map(x => x.item);
  }

  return [...filteredCompanies];
};

export const selectCompanies = createSelector(
  (state: ApplicationState) => state.trustHub.companies,
  selectSortSettings(Grid.TrustHubUserAdminCompanies),
  (state: ApplicationState) => state.trustHub.companiesSearch,
  (companies: TrusthubCompanyModel[], sortSetting, search) => {
    return [...searchCompanies(companies, search)].sort((a, b) =>
      sortCompanies(a, b, sortSetting.columnSort)
    );
  }
);
