import React, { useState } from 'react';
import { AdoptechButton } from '../../../../../components/AdoptechButton/AdoptechButton';
import { calculateNextReviewDate } from '../../../../../components/PolicyOverviewSection/PolicyOverviewSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  ControlReviewFrequencyEnum,
  ReviewHistoryItemCreatePayloadReviewHistoryItem,
  ReviewHistoryItemCreatePayloadReviewHistoryItemRelatedToTypeEnum,
  ReviewStatusEnum,
} from '../../../../../swagger';
import { postReviewHistoryItem } from '../../../../../store/compliance/complianceThunks';
import {
  setControlFormModel,
  updateControlReviewStatusSuccess,
} from '../../../../../store/compliance/complianceSlice';
import { ComplianceEditControlFormModel } from '../../../../../components/compliance/Types/complianceTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApplicationState } from '../../../../../types/applicationState';
import { UserAvatar } from '../../../../../components/UserAvatar/UserAvatar';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { formatShortDate } from '../../../../../functions/formatShortDate';
import { AdoptechDatePicker } from '../../../../../components/AdoptechDatePicker/AdoptechDatePicker';
import moment from 'moment';
import { useOwnerOptions } from '../../../../../hooks/useOwnerOptions';
import { selectCurrentVendorUser } from '../../../../../selectors/selectCurrentVendorUser';

interface EditControlReviewProps {
  control: ComplianceEditControlFormModel;
}

export const EditControlReview: React.FC<EditControlReviewProps> = props => {
  const baseCss = 'complianceEditControlForm';
  const dispatch = useDispatch();
  const { editControlFormModel, isPostingReviewHistoryItem } = useSelector(
    (state: ApplicationState) => state.compliance
  );
  const { users } = useOwnerOptions({
    onlyAdminable: true,
  });

  const [reviewConfirmed, setReviewConfirmed] = useState(false);

  const handleReviewClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const reviewFrequency = ControlReviewFrequencyEnum._12Months;
    const payload: ReviewHistoryItemCreatePayloadReviewHistoryItem = {
      relatedToType:
        ReviewHistoryItemCreatePayloadReviewHistoryItemRelatedToTypeEnum.Control,
      relatedToId: props.control.id,
      reviewFrequency,
    };

    dispatch(
      postReviewHistoryItem(payload, historyItem => {
        const nextReviewDate = calculateNextReviewDate(
          historyItem.createdAt,
          ControlReviewFrequencyEnum._12Months
        );
        dispatch(
          updateControlReviewStatusSuccess({
            id: props.control.id,
            nextReviewDate,
            reviewStatus: ReviewStatusEnum.Reviewed,
            reviewOverdue: false,
          })
        );

        dispatch(
          setControlFormModel({
            ...editControlFormModel,
            nextReviewDate: nextReviewDate,
            formTouched: true,
          })
        );
        setReviewConfirmed(true);
      })
    );
  };

  const currentVendorUser = useSelector(selectCurrentVendorUser);

  return (
    <>
      <div className={baseCss + '--fieldTitle'}>
        <div>Control review</div>
        <AdoptechButton
          disabled={reviewConfirmed}
          onClick={handleReviewClick}
          busy={isPostingReviewHistoryItem}
        >
          <FontAwesomeIcon icon={faCheck} />
          {reviewConfirmed ? 'Review confirmed' : 'Review'}
        </AdoptechButton>
      </div>
      <div className={baseCss + '--reviewRow'}>
        <div>Last reviewed by</div>
        <UserAvatar
          hideText
          user={users.find(
            user =>
              user.id ===
              (reviewConfirmed
                ? currentVendorUser.id
                : props.control.lastReview?.owner?.id)
          )}
          size="small"
          showTooltip
        />
        <div>
          {formatShortDate(
            reviewConfirmed
              ? new Date().toISOString()
              : props.control.lastReview?.createdAt
          )}
        </div>
      </div>
      <div className={baseCss + '--reviewRow'}>
        <div>Next review due</div>
        {editControlFormModel.nextReviewDate && (
          <AdoptechDatePicker
            labelText={null}
            placeHolderText="Select a date"
            outputDate={
              editControlFormModel.nextReviewDate
                ? new Date(editControlFormModel.nextReviewDate)
                : null
            }
            onDateSelect={(d: Date) => {
              const endOfDay = moment(d).endOf('day');
              const endOfDayToDateTime = endOfDay.toJSON();

              dispatch(
                setControlFormModel({
                  ...editControlFormModel,
                  nextReviewDate: endOfDayToDateTime,
                  formTouched: true,
                })
              );
            }}
          ></AdoptechDatePicker>
        )}
      </div>
    </>
  );
};
