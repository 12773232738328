import { createSelector } from '@reduxjs/toolkit';
import { VendorUser } from '../swagger';
import { ApplicationState } from '../types/applicationState';
import { selectSortSettings } from './selectSortSettings';
import { Grid } from '../types/grid';
import { vendorUserSort } from '../functions/vendorUserSort';

export const selectUsers = createSelector(
  (state: ApplicationState) => state.vendors.users,
  selectSortSettings(Grid.Users),
  (users: VendorUser[], sortSetting) => {
    if (users?.length) {
      return [...users].sort((a, b) =>
        vendorUserSort(a, b, sortSetting.columnSort)
      );
    }
    return [];
  }
);
