import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { reactSelectCustomStyles } from '../../functions/reactSelectCustomStyles';
import { reactSelectCustomTheme } from '../../functions/reactSelectCustomTheme';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechTooltip } from '../AdoptechTooltip/AdoptechTooltip';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import './AdoptechReactSelect.scss';
import ReactSelect, { Theme } from 'react-select';

export interface AdoptechReactSelectProps {
  allowAdd?: boolean;
  addText?: string;
  id: string;
  onAdd?: () => void;
  onChange: (value: any) => void;
  options: SelectionOption[];
  value?: any;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  label?: string;
  ariaLabel?: string;
  hasError?: boolean;
  components?: any;
  noOptionsText?: string;
  maxMenuHeight?: number;
  showUserAvatar?: boolean;
  additionalStyling?: { [key: string]: React.CSSProperties };
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  isClearable?: boolean;
  theme?: (theme: Theme) => Theme;
  labelTooltipText?: string;
  showUserEmail?: boolean;
  rounded?: boolean;
}
export const AdoptechReactSelect: React.FC<AdoptechReactSelectProps> = ({
  allowAdd,
  addText,
  id,
  onChange,
  onAdd,
  options,
  value,
  isDisabled,
  isLoading,
  isMulti,
  placeholder,
  label,
  ariaLabel,
  hasError,
  components,
  noOptionsText,
  maxMenuHeight,
  showUserAvatar,
  additionalStyling,
  closeMenuOnSelect,
  hideSelectedOptions,
  isClearable,
  theme,
  labelTooltipText,
  showUserEmail = false,
}) => {
  const optionsWithCreate = allowAdd
    ? [{ label: addText ? addText : 'Add ...', value: 'Add' }, ...options]
    : options;

  const mainClasses = classNames({
    adoptechReactSelect: true,
    'adoptech-error': hasError,
    'adoptechReactSelect--isDisabled': isDisabled,
  });

  const prefixClass = 'adoptechReactSelect--prefix';

  // TODO: remove "VendorUser dependency in this 'base component'"
  const vendorUsers = useSelector(
    (state: ApplicationState) => state.vendors.users
  );

  const getUser = (userId: string) => {
    return vendorUsers?.find(u => u.id === userId);
  };

  return (
    <div className={mainClasses}>
      {label && (
        <AdoptechTooltip
          showTooltip={!!labelTooltipText}
          text={labelTooltipText}
          identifier={id}
        >
          <label className="adoptechReactSelect--label" htmlFor={id}>
            {label}
          </label>
        </AdoptechTooltip>
      )}
      <ReactSelect
        aria-label={ariaLabel}
        className="react-select"
        id={id}
        onChange={(option: SelectionOption) => {
          const selectionOption = option as SelectionOption;
          if (allowAdd && selectionOption.value === 'Add') {
            onAdd();
            return;
          }
          onChange(selectionOption);
        }}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        options={optionsWithCreate}
        styles={reactSelectCustomStyles(showUserAvatar, additionalStyling)}
        theme={theme || reactSelectCustomTheme}
        value={value}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isMulti={isMulti}
        components={components}
        placeholder={placeholder || 'Please choose ...'}
        noOptionsMessage={() => noOptionsText}
        maxMenuHeight={maxMenuHeight}
        classNamePrefix="adoptechReactSelect--prefix" // !!! Classes like ValueContainer are disappearing after compilation build inside shadow DOM! https://github.com/JedWatson/react-select/issues/3309
        formatOptionLabel={
          showUserAvatar
            ? (option: SelectionOption) => {
                const user = getUser(option.value);
                if (!user) {
                  return option.label;
                }
                return (
                  <UserAvatar
                    size="medium"
                    user={user}
                    showUserEmail={showUserEmail}
                  />
                );
              }
            : undefined
        }
      />
    </div>
  );
};
