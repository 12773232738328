import React, { useEffect, useState } from 'react';
import {
  CorrectiveActionModel,
  CorrectiveActionModelActionTypeEnum,
} from '../../../swagger';
import { CorrectiveActionFormType } from '../CalendarPage/CalendarPage';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';
import { ApplicationState } from '../../../types/applicationState';
import { useSelector } from 'react-redux';
import AdoptechModal from '../../../components/AdoptechModal/AdoptechModal';
import { Modal } from 'react-bootstrap';
import './EditCorrectiveActionModal.scss';
import { fetchCorrectiveAction } from '../store/calendarThunks';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { canFeature } from '../../../functions/access';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { AccessObject } from '../../../types/accessObject';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { AdoptechTextArea2 } from '../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechDatePicker2 } from '../../../components/AdoptechDatePicker2/AdoptechDatePicker2';
import moment from 'moment';
import CalendarAttachments from '../EditEventModal/CalendarAttachments/CalendarAttachments';

type Model = CorrectiveActionModel;
type FormType = CorrectiveActionFormType;

interface Props {
  init?: Model['id'];
  close: () => void;
  confirm: (params: FormType) => void;
  show: boolean;
  onDelete?: (id: Model['id']) => void;
}

const baseCss = 'editCorrectActionModal';

export const EditCorrectiveActionModal: React.FC<Props> = ({
  init = undefined,
  confirm = () => {},
  onDelete = () => {},
  close,
  show,
}) => {
  const [model, setModel] = useState({ id: init } as CorrectiveActionFormType);

  const modalTitle = model.id
    ? 'Edit Corrective Action'
    : 'Create Corrective Action';
  const { users, ownerOptions: userOptions } = useOwnerOptions({
    includingGuest: true,
  });
  const actionName = model.id ? 'Save' : 'Create';
  const {
    createCorrectiveActionStatus,
    fetchCorrectiveActionStatus,
    updateCorrectiveActionStatus,
    deleteCorrectiveActionStatus,
  } = useSelector((state: ApplicationState) => state.calendar);
  const isDeletingCalendarItem = deleteCorrectiveActionStatus === 'loading';
  const isFetchingCalendarItem = fetchCorrectiveActionStatus === 'loading';
  const isUpdatingCalendarItem = updateCorrectiveActionStatus === 'loading';
  const isCreatingCalendarItem = createCorrectiveActionStatus === 'loading';
  const dispatch = useAppDispatch();
  const isLoading = isFetchingCalendarItem || isDeletingCalendarItem;
  const fetchCorrectiveActionAsync = async (id: string) => {
    const action = await dispatch(fetchCorrectiveAction(id)).unwrap();

    setModel(action);
  };

  const { errorMessage } = useSelector(
    (state: ApplicationState) => state.global
  );

  useEffect(() => {
    errorMessage &&
      errorMessage.includes('ID entered') &&
      setModel({
        // highlight field on BE unique error
        ...model,
        identifier: null,
      });
  }, [errorMessage]);

  useEffect(() => {
    model.id && fetchCorrectiveActionAsync(model.id);
  }, [model.id]);
  const canManageCalendar = canFeature(AccessObject.calendar_manage);
  const readonlyMode = !canManageCalendar;
  const hasNameError = !model.name;
  const hasDescriptionError = !model.description;
  const hasTypeError = !model.actionType;
  const hasIdentifierError = !model.identifier;
  const hasDateError = !model.dateIdentified;
  const hasOwnerError = !model.owner;
  const hasRaisedByError = !model.raisedBy;
  const hasRootCauseError = !model.rootCause;
  const hasCorrectiveActionsError = !model.correctiveActions;

  const isNonConformance =
    model.actionType === CorrectiveActionModelActionTypeEnum.NonConformance;
  const dataMissing =
    hasNameError ||
    hasDescriptionError ||
    hasTypeError ||
    hasIdentifierError ||
    hasDateError ||
    hasOwnerError ||
    hasRaisedByError ||
    (isNonConformance && hasRootCauseError) ||
    hasCorrectiveActionsError;

  const typeOptions = [
    {
      label: 'Non-conformance (NC) ',
      value: CorrectiveActionModelActionTypeEnum.NonConformance,
    },
    {
      label: 'Opportunity for improvement (OFI)',
      value: CorrectiveActionModelActionTypeEnum.OpportunityForImprovement,
    },
  ];
  const deadline = model.dateIdentified ? new Date(model.dateIdentified) : null;

  return (
    <AdoptechModal className={baseCss} onHide={close} show={show}>
      <Modal.Header>
        <div>{modalTitle}</div>
        {model.id && (
          <AdoptechButton
            extraClass={model.completed ? 'completedButton' : 'pendingButton'}
          >
            {model.completed ? 'Completed' : 'Pending'}
          </AdoptechButton>
        )}
      </Modal.Header>
      <Modal.Body>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <div className={baseCss + '--body'}>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextInput2
                  id="createCorrectiveActionName"
                  rounded
                  label="Title"
                  type="text"
                  value={model.name}
                  onChange={e =>
                    setModel({ ...model, name: e.currentTarget.value })
                  }
                  hasError={hasNameError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextArea2
                  rounded
                  id="createCorrectiveActionDescription"
                  label="Describe this Corrective action"
                  value={model.description}
                  onChange={e =>
                    setModel({ ...model, description: e.currentTarget.value })
                  }
                  icon={faPen}
                  hasError={hasDescriptionError}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechReactSelect2
                  id={baseCss + '--corrective-action-type'}
                  rounded
                  options={typeOptions}
                  label="Corrective action type"
                  isClearable={false}
                  hasError={hasTypeError}
                  onChange={option =>
                    setModel({
                      ...model,
                      actionType: option.value as FormType['actionType'],
                    })
                  }
                  value={typeOptions.filter(
                    option => option.value === model.actionType
                  )}
                  placeholder="Please select"
                  isDisabled={readonlyMode}
                />
              </div>
              <div className={baseCss + '--field'}>
                <AdoptechTextInput2
                  id={baseCss + '--corrective-action-id'}
                  rounded
                  label="ID"
                  type="text"
                  value={model.identifier}
                  placeholder="For Example NC1"
                  onChange={e =>
                    setModel({ ...model, identifier: e.currentTarget.value })
                  }
                  hasError={hasIdentifierError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechDatePicker2
                  id="createCorrectiveActionDate"
                  rounded
                  labelText="Date identified"
                  placeHolderText="Select a date"
                  minDate={null}
                  outputDate={deadline}
                  hasError={hasDateError}
                  onDateSelect={d => {
                    const endOfDay = moment(d).endOf('day');
                    const endOfDayToDateTime = endOfDay.toJSON();

                    setModel({
                      ...model,
                      dateIdentified: endOfDayToDateTime,
                    });
                  }}
                  disabled={readonlyMode}
                />
              </div>
              <div className={baseCss + '--field'}>
                <AdoptechReactSelect2
                  placeholder="Please choose..."
                  rounded
                  label="Owner"
                  id="createCorrectiveActionOwner"
                  options={userOptions}
                  hasError={hasOwnerError}
                  onChange={e => {
                    setModel({
                      ...model,
                      owner: users.find(user => user.id === e.value),
                    });
                  }}
                  value={userOptions.find(
                    option => option.value == model.owner?.id
                  )}
                  isDisabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextArea2
                  id="createCorrectiveActionRaisedBy"
                  rounded
                  label="Who/how was the issue raised?"
                  value={model.raisedBy}
                  onChange={e =>
                    setModel({ ...model, raisedBy: e.currentTarget.value })
                  }
                  hasError={hasRaisedByError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            {!hasTypeError && isNonConformance && (
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--field'}>
                  <AdoptechTextArea2
                    id="createCorrectiveActionRootCause"
                    rounded
                    label="Detail the root cause of the non-conformance "
                    value={model.rootCause}
                    onChange={e =>
                      setModel({ ...model, rootCause: e.currentTarget.value })
                    }
                    hasError={hasRootCauseError}
                    icon={faPen}
                    disabled={readonlyMode}
                  />
                </div>
              </div>
            )}

            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextArea2
                  id="createCorrectiveActionCorrectiveActions"
                  rounded
                  label={
                    isNonConformance
                      ? 'Detail changes implemented, including the date to eliminate the cause of the non-conformance and prevent recurrence. Comment on their perceived effectiveness. '
                      : 'Detail the changes implemented, including the date, to address the OFI. Comment on their perceived effectiveness. '
                  }
                  value={model.correctiveActions}
                  onChange={e =>
                    setModel({
                      ...model,
                      correctiveActions: e.currentTarget.value,
                    })
                  }
                  hasError={hasCorrectiveActionsError}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>

            <CalendarAttachments
              baseCss={baseCss}
              model={model}
              setModel={setModel}
              readonlyMode={readonlyMode}
              type="corrective-action"
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {model.id ? (
          <div>
            <AdoptechButton
              disabled={dataMissing}
              onClick={() => {
                const data = { ...model, completed: !model.completed };
                confirm(data);
              }}
            >
              {model.completed ? 'Mark as pending' : 'Mark as completed'}
            </AdoptechButton>

            <AdoptechButton
              extraClass={baseCss + '--deleteButton'}
              icon={faTrashAlt}
              onClick={() => onDelete(model.id)}
            >
              Delete
            </AdoptechButton>
          </div>
        ) : (
          <AdoptechButton
            onClick={() => {
              close();
            }}
          >
            Cancel
          </AdoptechButton>
        )}
        <AdoptechButton
          disabled={dataMissing || readonlyMode}
          busy={isCreatingCalendarItem || isUpdatingCalendarItem}
          onClick={() => confirm(model)}
          variant={AdoptechButtonVariant.Primary}
        >
          {actionName}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
