import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  shareEntity,
  updateSharePermissions,
} from '../../store/vendors/vendorsThunks';
import {
  MultipleShare,
  MultipleShareShares,
  ShareAccessLevel,
} from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import './ShareModal.scss';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';

import { Tag } from 'react-tag-autocomplete';
import { ShareAutocomplete } from '../ShareAutocomplete/ShareAutocomplete';
import { hideShareModal } from '../../store/vendors/vendorsSlice';
import { allPermissionsOptions, ShareTypesAndTitles } from './constants';
import { SharesTable } from '../SharesTable/SharesTable';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showGlobalToast } from '../../store/global/globalSlice';
import classNames from 'classnames';

const permissionsToUpdateInitial: {
  toChangeList: MultipleShareShares[];
  toDeleteList: string[];
} = {
  toChangeList: [],
  toDeleteList: [],
};

export const ShareModal: React.FC = () => {
  const [permissionsToUpdate, setPermissionsToUpdate] = useState(
    permissionsToUpdateInitial
  );
  const [message, setMessage] = useState('');
  const [usersToShare, setUsersToShare] = useState<Tag[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<string>();
  const [permissionsOptions, setPermissionsOptions] = useState<
    SelectionOption[]
  >([]);
  const currentVendor = useSelector(selectCurrentVendor);

  const shareableObject = useSelector(
    (state: ApplicationState) => state.vendors.shareableObject
  );
  const isSharingModalShowing = useSelector(
    (state: ApplicationState) => state.vendors.isSharingModalShowing
  );
  const isRequestingShare = useSelector(
    (state: ApplicationState) => state.vendors.isRequestingShare
  );
  const isRequestingDeletePermissions = useSelector(
    (state: ApplicationState) => state.vendors.isRequestingDeletePermissions
  );
  const usersAreAdded = Boolean(usersToShare.length);
  const isShareButtonDisabled = !usersAreAdded || !selectedPermission;
  const isDoneButtonDisabled =
    !permissionsToUpdate.toChangeList.length &&
    !permissionsToUpdate.toDeleteList.length;
  const typeTitle =
    shareableObject?.title ||
    ShareTypesAndTitles[shareableObject?.shareableType];
  const dispatch = useDispatch();

  const handleChangePermissions = (toChange: MultipleShareShares) => {
    setPermissionsToUpdate({
      ...permissionsToUpdate,
      toChangeList: [
        ...permissionsToUpdate.toChangeList.filter(
          share => share.email !== toChange.email
        ),
        toChange,
      ],
    });
  };

  const handleDeletePermissions = (emailToDelete: string) => {
    setPermissionsToUpdate({
      toChangeList: permissionsToUpdate.toChangeList.filter(
        share => share.email !== emailToDelete
      ),
      toDeleteList: [...permissionsToUpdate.toDeleteList, emailToDelete],
    });
  };

  const handleUpdatePermissions = (shouldHide = true) => {
    if (isDoneButtonDisabled) return;
    const { toChangeList, toDeleteList } = permissionsToUpdate;
    dispatch(
      updateSharePermissions({
        id: currentVendor.id,
        shareableType: shareableObject.shareableType,
        shareableId: shareableObject.shareableId,
        multipleShare: { shares: toChangeList },
        emailsToDelete: toDeleteList,
        onSuccess: () => {
          dispatch(showGlobalToast('The permissions has been updated'));
          shouldHide && dispatch(hideShareModal());
        },
      })
    );
    setPermissionsToUpdate(permissionsToUpdateInitial);
  };

  const handleShare = () => {
    const multipleShare: MultipleShare = {
      message,
      shares: usersToShare.map((userToShare: Tag) => ({
        email: userToShare.name,
        accessLevel: selectedPermission as ShareAccessLevel,
      })),
    };
    dispatch(
      shareEntity({
        id: currentVendor.id,
        shareableType: shareableObject.shareableType,
        shareableId: shareableObject.shareableId,
        multipleShare,
        onSuccess: () => {
          dispatch(hideShareModal());
          dispatch(showGlobalToast('Your document has been shared.'));
        },
      })
    );
  };

  useEffect(() => {
    if (shareableObject) {
      const options = allPermissionsOptions.filter(
        opt =>
          shareableObject.accessLevels.indexOf(
            opt.value as ShareAccessLevel
          ) !== -1
      );
      setPermissionsOptions(options);
    }
  }, [shareableObject]);

  useEffect(() => {
    if (!isSharingModalShowing) {
      setMessage('');
      setUsersToShare([]);
      setSelectedPermission(null);
      setPermissionsOptions([]);
    }
  }, [isSharingModalShowing]);

  if (!shareableObject) {
    return null;
  }

  const handleCancel = () => {
    setPermissionsToUpdate(permissionsToUpdateInitial);
    dispatch(hideShareModal());
  };

  const showOnlyGetLink = shareableObject.showOnlyGetLink;

  const GetLinkFooter = (
    <div className="shareModal--getLink">
      <div>
        <div className="shareModal--getLinkTitle">
          <FontAwesomeIcon
            className="shareModal--getLinkTitleIcon"
            icon={faLink}
          />
          <div className="shareModal--getLinkTitleText">Get link</div>
        </div>
        <div className="shareModal--getLinkInfo">
          <b>Restricted</b> Only people with Security Manager role will be able
          to open this link.
        </div>
      </div>
      <div
        className="shareModal--copyLink"
        onClick={() => {
          const tempInput = document.body.appendChild(
            document.createElement('input')
          );
          tempInput.value =
            window.location.origin + shareableObject.relativePath;
          tempInput.focus();
          tempInput.select();
          document.execCommand('copy');
          tempInput.parentNode.removeChild(tempInput);
          dispatch(showGlobalToast('Link copied to clipboard.'));
        }}
      >
        Copy link
      </div>
    </div>
  );

  if (showOnlyGetLink) {
    return (
      <AdoptechModal
        className="shareModal"
        onHide={handleCancel}
        show={isSharingModalShowing}
      >
        {GetLinkFooter}
      </AdoptechModal>
    );
  }

  return (
    <AdoptechModal
      className="shareModal"
      onHide={handleCancel}
      show={isSharingModalShowing}
    >
      <Modal.Header>Share {typeTitle}</Modal.Header>
      <Modal.Body className="shareModal--body">
        <div className="shareModal--usersAndPermissions">
          <div
            className={classNames('shareModal--userAutocomplete', {
              'shareModal--autocompleteWithContent': usersAreAdded,
            })}
          >
            <div className="shareModal--label">Add people</div>
            <ShareAutocomplete
              usersToShare={usersToShare}
              onUpdate={(newUsers: Tag[]) => {
                handleUpdatePermissions(false);
                setUsersToShare(newUsers);
              }}
              vendorId={currentVendor.id}
              shareableType={shareableObject.shareableType}
              shareableId={shareableObject.shareableId}
            />
          </div>

          {usersAreAdded && (
            <div className="shareModal--permissionSelect">
              <div className="shareModal--label">Permissions</div>
              <AdoptechReactSelect
                placeholder="Please select"
                id="selectPermission"
                onChange={(selection: SelectionOption) =>
                  setSelectedPermission(selection.value)
                }
                options={permissionsOptions}
              />
            </div>
          )}
        </div>

        {usersAreAdded && (
          <div className="shareModal--message">
            <div className="shareModal--label">Add optional message</div>
            <AdoptechTextArea
              id="shareMessage"
              onChange={e => {
                setMessage(e.currentTarget.value);
              }}
              value={message}
            />
          </div>
        )}
        <SharesTable
          permissionsToUpdate={permissionsToUpdate}
          hidden={usersAreAdded}
          shareableObject={shareableObject}
          onChangePermissions={handleChangePermissions}
          onDeletePermissions={handleDeletePermissions}
        />
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>
        {usersAreAdded ? (
          <AdoptechButton
            busy={isRequestingShare}
            disabled={isShareButtonDisabled}
            onClick={handleShare}
            variant={AdoptechButtonVariant.Primary}
          >
            SHARE
          </AdoptechButton>
        ) : (
          <AdoptechButton
            disabled={isDoneButtonDisabled}
            busy={isRequestingShare || isRequestingDeletePermissions}
            onClick={() => handleUpdatePermissions()}
            variant={AdoptechButtonVariant.Primary}
          >
            Done
          </AdoptechButton>
        )}
      </Modal.Footer>
      {!usersAreAdded && GetLinkFooter}
    </AdoptechModal>
  );
};
