export const helpHost = 'https://knowledge.adoptech.co.uk';
const articles = {
  pestel: `${helpHost}/pestel`,
  vendor: `${helpHost}/how-to-assess-a-vendor-risk`,
};

export type chatWidgetArticleId = keyof typeof articles;

export const showWidgetChatArticle = (articleId: chatWidgetArticleId) => {
  window.open(articles[articleId], '_blank');
};

// without "important" doesn't work
// https://community.hubspot.com/t5/APIs-Integrations/How-to-move-the-livechat-widget-in-another-position/m-p/626678
export const moveChatWidgetPosition = (width: number) => {
  document
    .getElementById('hubspot-messages-iframe-container')
    ?.style?.setProperty('right', `${width}px`, 'important');
};

export const openChatWidget = () => {
  window.HubSpotConversations?.widget?.open();
};

// we need init step ( not automatically init ) because we use identity api request
// for logged-in admin user before init
// https://developers.hubspot.com/docs/api/conversation/visitor-identification
export const initChatWidget = () => {
  window.HubSpotConversations?.clear();
  window.HubSpotConversations?.widget?.load();
};

export const removeChatWidget = () => {
  window.HubSpotConversations?.widget?.remove();
};
