import { ColumnSort } from '../types/columnSort';
import { SortDirection } from '../types/sortDirection';
import { sort } from './sort';
import { VendorUser } from '../swagger';

export const vendorUserSort = (
  a: VendorUser,
  b: VendorUser,
  columnSort: ColumnSort
): number => {
  let direction = 1;
  if (columnSort.direction === SortDirection.Ascending) {
    direction = -1;
  }

  const typedColumn: keyof VendorUser = columnSort.name as keyof VendorUser;

  return sort(a[typedColumn], b[typedColumn], direction);
};
