import React, { useState } from 'react';
import './ControlCheckControls.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedFramework } from '../../../../../selectors/selectSelectedFramework';
import { ApplicationState } from '../../../../../types/applicationState';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {
  setControlFormModel,
  setSelectedControlId,
  setShowControlDrawer,
} from '../../../../../store/compliance/complianceSlice';
import { EditControl } from '../../../../../components/compliance/Types/complianceTypes';
import { FrameworksPills } from '../../../../../components/Frameworks/Pills';
import {
  fetchComplianceCategories,
  fetchComplianceControls,
  fetchControlExtended,
} from '../../../../../store/compliance/complianceThunks';
import { ControlModel, ControlModelStatusEnum } from '../../../../../swagger';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';

export const controlIcon = (control: ControlModel) => {
  return control.status === ControlModelStatusEnum.Failed
    ? faExclamationCircle
    : faCheckCircle;
};
export const controlIconClass = (control: ControlModel, baseClass: string) => {
  return control.status === ControlModelStatusEnum.Compliant
    ? baseClass + '--greenTick'
    : control.status === ControlModelStatusEnum.Failed
      ? baseClass + '--redTick'
      : baseClass + '--greyTick';
};

export const ControlCheckControls: React.FC = () => {
  const dispatch = useDispatch();
  const baseCss = 'controlCheckControls';
  const selectedFramework = useSelector(selectSelectedFramework);
  const { isFetchingTaskDetails, isFetchingControls, tempTask, frameworks } =
    useSelector((state: ApplicationState) => state.compliance);
  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );
  const [controlDataFetched, setControlDataFetched] = useState(false);
  const fetchControlDrawerData = () => {
    if (controlDataFetched) return;
    const firstFrameworkId = (frameworks || [])[0].id; // TODO: BE support for multiple frameworks
    dispatch(fetchComplianceCategories(vendorId, firstFrameworkId));
    // used when click parent control RHS => add framework relation.

    dispatch(fetchComplianceControls({ vendorId, frameworkId: null }));
    // used in Control RHS: when click parent control RHS => control.policies, control.tasks
    setControlDataFetched(true);
  };

  const assignedControls = tempTask?.controls || [];
  // deprecated:
  // const controls = useSelector(selectLinkedControlsExtended(assignedControls));
  const controls = assignedControls;
  const childClass = (name: string) => `${baseCss}--${name}`;

  if (isFetchingTaskDetails) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className={childClass('header')}>
        <div>Parent controls</div>
      </div>
      {isFetchingControls ? (
        <LoadingSpinner />
      ) : (
        <>
          {controls.length === 0 && (
            <div style={{ marginTop: '8px' }}>There are no parent controls</div>
          )}
          {controls.length > 0 && (
            <div className={childClass('controls')}>
              <div className={childClass('tableHeaders')}>
                <div className={childClass('label')}></div>
                <div className={childClass('label')}>Name</div>
                <div className={childClass('label')}>Framework</div>
              </div>
              {controls.map(control => {
                const controlFrameworks =
                  (control.frameworksRelations || []).length > 0
                    ? [control.frameworksRelations[0].framework]
                    : [];
                return (
                  <div
                    className={
                      childClass('tableRow') +
                      ' ' +
                      childClass('tableRow--large')
                    }
                    key={control.id}
                    onClick={e => {
                      e.stopPropagation();
                      fetchControlDrawerData();
                      dispatch(fetchControlExtended(control.id));
                      dispatch(setSelectedControlId(control.id));
                      const editControl = new EditControl(control);
                      dispatch(setControlFormModel(editControl.simpleObject()));
                      dispatch(setShowControlDrawer(true));
                    }}
                  >
                    <div className={childClass('status')}>
                      <FontAwesomeIcon
                        icon={controlIcon(control)}
                        className={controlIconClass(control, baseCss)}
                      />
                    </div>
                    <div className={childClass('name')}>{control.name}</div>
                    <div className={childClass('framework')}>
                      <FrameworksPills frameworks={controlFrameworks} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};
