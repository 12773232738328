import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';

import './AdoptechOverflowLine.scss';

interface AdoptechOverflowLineProps {
  speed?: number;
  className?: string;
}

const AdoptechOverflowLine: React.FC<AdoptechOverflowLineProps> = ({
  speed = 300,
  className = '',
  children,
}) => {
  const baseCss = 'adoptechOverflowLine';
  const fadeCompensation = 20;

  const elWrapper = useRef(null);
  const elContent = useRef(null);

  const [overflowValue, setOverflow] = useState(0);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    setOverflow(elContent.current.offsetWidth - elWrapper.current.offsetWidth);
  }, [elWrapper.current, elContent.current]);

  const overflow = overflowValue > 0;

  const scrollForward = () => setScroll(true);
  const scrollBack = () => setScroll(false);

  const style = overflow
    ? {
        marginLeft: scroll ? `-${overflowValue + fadeCompensation}px` : '0',
      }
    : {};

  return (
    <div
      ref={elWrapper}
      className={classNames(baseCss + '--wrapper', className, {
        scroll,
        overflow,
      })}
      onMouseEnter={scrollForward}
      onMouseLeave={scrollBack}
    >
      <div ref={elContent} style={style} className={baseCss + '--content'}>
        {children}
      </div>
    </div>
  );
};

export default AdoptechOverflowLine;
