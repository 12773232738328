import React, { useEffect, useMemo, useState } from 'react';
import './CalendarPage.scss';
import { AdoptechButtonVariant } from '../../../components/AdoptechButton/AdoptechButton';
import { useSelector } from 'react-redux';
import {
  createActionReference,
  createCorrectiveAction,
  createCorrectiveActionParams,
  createEvent,
  createEventParams,
  createIncident,
  createIncidentParams,
  createNewAction,
  createNewActionParams,
  deleteAction,
  deleteActionReference,
  deleteCorrectiveAction,
  deleteEvent,
  deleteIncident,
  fetchCalendarItems,
  updateAction,
  updateActionParams,
  updateCorrectiveAction,
  updateCorrectiveActionParams,
  updateEvent,
  updateIncident,
  updateIncidentParams,
} from '../store/calendarThunks';
import { cleanupItems, setCalendarFilters } from '../store/calendarSlice';
import { ApplicationState } from '../../../types/applicationState';
import moment from 'moment';
import {
  isAction,
  isCorrectiveAction,
  isEvent,
  isIncident,
} from '../selectors/selectCalendarHSItems';
import {
  CalendarItemModel,
  CalendarItemModelStatusEnum,
  CorrectiveActionExtended,
  CorrectiveActionsCorrectiveActionIdPatchCorrectiveActionActionTypeEnum,
  IncidentEventExtended,
  ReferenceCreatePayloadReferenceReferenceableTypeEnum,
  SharedAttachmentModel,
  VendorActionExtended,
  VendorActionModel,
  VendorEventEventType,
  VendorEventExtended,
  VendorEventModel,
  VendorsVendorIdCorrectiveActionsPostCorrectiveActionActionTypeEnum,
  VendorsVendorIdIncidentEventsPostRequest,
} from '../../../swagger';
import { EditActionModal } from '../../../components/EditActionModal/EditActionModal';
import { showGlobalToast } from '../../../store/global/globalSlice';
import { CommandConfirmation } from '../../../types/CommandConfirmation';
import { ConfirmationModal } from '../../../components/ConfirmationModal/ConfirmationModal';
import { EditEventModal } from '../EditEventModal/EditEventModal';
import { selectVendorDetails } from '../../../selectors/selectVendorDetails';
import { CalendarHeader } from '../CalendarHeader/CalendarHeader';
import { CalendarItemsList } from '../CalendarItemsList/CalendarItemsList';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import { AdoptechPanel } from '../../../components/AdoptechPanel/AdoptechPanel';
import { CalendarSearch } from '../CalendarSearch/CalendarSearch';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { scrollContainer } from '../../../components/Routes/Routes';
import { selectCalendarPageItems } from '../selectors/selectCalendarPageItems';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import { EditCorrectiveActionModal } from '../EditCorrectiveActionModal/EditCorrectiveActionModal';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { EditIncidentModal } from '../EditIncidentModal/EditIncidentModal';

export const completedStatus = CalendarItemModelStatusEnum.Completed;
export const overdueStatus = CalendarItemModelStatusEnum.Overdue;

interface CalendarFormAttachment extends SharedAttachmentModel {
  rawFile?: File;
}
type CalendarForm = {
  deletedAttachmentIds?: SharedAttachmentModel['id'][];
  attachments?: CalendarFormAttachment[];
};
export type EventFormType = VendorEventExtended & CalendarForm;
export type ActionFormType = VendorActionExtended & CalendarForm;
export type CorrectiveActionFormType = CorrectiveActionExtended & CalendarForm;
export type IncidentFormType = IncidentEventExtended & CalendarForm;

const mapFormAttachmentsToPayload = (attachments: CalendarFormAttachment[]) => {
  return (attachments || [])
    .filter((attachment: CalendarFormAttachment) => attachment.rawFile)
    .map((attachment: CalendarFormAttachment) => attachment.rawFile);
};
export const mapVendorActonFormToPostPayload = (params: ActionFormType) => {
  const { name, owner, description, dueDate, actionType, attachments, url } =
    params;
  const payload: createNewActionParams['payload'] = {
    vendorActionName: name,
    vendorActionOwnerId: owner.id,
    vendorActionDescription: description,
    vendorActionDueDate: dueDate,
    vendorActionActionType: actionType,
    vendorActionUrl: url,
    vendorActionAttachments: mapFormAttachmentsToPayload(attachments),
  };
  return payload;
};

const mapIncidentFormTypeToPostOrPatchPayload = (
  params: IncidentFormType
): createIncidentParams => {
  const {
    name,
    description,
    dateIdentified,
    raisedBy,
    rootCause,
    preventativeActions,
    actionsTaken,
    dataBreach,
    dataBreachDetails,
    url,
    attachments,
    identifier,
    owner,
  } = params;
  return {
    incidentEventName: name,
    incidentEventDescription: description,
    incidentEventDateIdentified: dateIdentified,
    incidentEventRaisedBy: raisedBy,
    incidentEventRootCause: rootCause,
    incidentEventPreventativeActions: preventativeActions,
    incidentEventActionsTaken: actionsTaken,
    incidentEventIdentifier: identifier,
    incidentEventOwnerId: owner?.id,
    incidentEventDataBreach: dataBreach,
    incidentEventDataBreachDetails: dataBreachDetails,
    incidentEventUrl: url,
    incidentEventAttachments: mapFormAttachmentsToPayload(attachments),
  };
};

const mapCorrectiveActionFormTypeToPostOrPatchPayload = (
  payload: CorrectiveActionFormType
): createCorrectiveActionParams => {
  const postOrPatchPayload: createCorrectiveActionParams = {
    correctiveActionName: payload.name,
    correctiveActionDescription: payload.description,
    correctiveActionDateIdentified: payload.dateIdentified,
    correctiveActionRaisedBy: payload.raisedBy,
    correctiveActionRootCause: payload.rootCause,
    correctiveActionActionType:
      payload.actionType as unknown as VendorsVendorIdCorrectiveActionsPostCorrectiveActionActionTypeEnum,
    correctiveActionCorrectiveActions: payload.correctiveActions,
    correctiveActionIdentifier: payload.identifier,
    correctiveActionOwnerId: payload.owner?.id,
    correctiveActionUrl: payload.url,
    correctiveActionAttachments: mapFormAttachmentsToPayload(
      payload.attachments
    ),
  };
  return postOrPatchPayload;
};

export const endOfCurrentDateMonth = (currentDate: string | Date) => {
  return moment(currentDate).endOf('month').toDate();
};

export const CalendarPage: React.FC = () => {
  const [currentCommand, command] = useState<CommandConfirmation>(null);
  const baseCss = 'calendarPage';
  const currentVendor = useSelector(selectVendorDetails);
  const dispatch = useAppDispatch();
  const currentVendorUser = useSelector(selectCurrentVendorUser);

  const {
    filters,
    filters: { showMyItems },
  } = useSelector((state: ApplicationState) => state.calendar);
  const showAllItems = !showMyItems;

  const refetchCurrentDateCalendarItems = async () => {
    const filtersPayload = {
      ...filters,
      ...{
        page: 1,
      },
    };

    await dispatch(
      fetchCalendarItems({
        filters: filtersPayload,
        currentVendorUserId: currentVendorUser.id,
      })
    );
  };

  const cleanup = () => {
    dispatch(cleanupItems());
  };

  useEffect(() => {
    cleanup();
    refetchCurrentDateCalendarItems();
    return () => {
      cleanup();
    };
  }, [currentVendor]);

  const { fetchCalendarItemsStatus } = useSelector(
    (state: ApplicationState) => state.calendar
  );

  const isFetchingItems = fetchCalendarItemsStatus === 'loading';

  const items = useSelector((state: ApplicationState) => state.calendar.items);
  const filteredGroupedByMonthItems = useSelector(selectCalendarPageItems);

  const [showEditActionModal, setShowEditActionModal] = useState(false);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [showCorrectiveActionModal, setShowCorrectiveActionModal] =
    useState(false);
  const [showIncidentModal, setShowIncidentModal] = useState(false);
  const [currentCalendarItemId, setCurrentCalendarItemId] = useState(null);
  const handleRowClick = (item: CalendarItemModel) => {
    setCurrentCalendarItemId(item.id);
    if (isEvent(item)) {
      setShowEditEventModal(true);
      return;
    }
    if (isCorrectiveAction(item)) {
      setShowCorrectiveActionModal(true);
      return;
    }

    if (isIncident(item)) {
      setShowIncidentModal(true);
      return;
    }

    if (isAction(item)) {
      setShowEditActionModal(true);
      return;
    }

    alert('Unknown calendar item type');
  };

  const refetchPageItems = async () => {
    setShowEditActionModal(false);
    setShowEditEventModal(false);
    setShowCorrectiveActionModal(false);
    setShowIncidentModal(false);
    cleanup();
    await refetchCurrentDateCalendarItems();
  };
  // If we post/patch action type => post/delete action reference.

  // 3 cases: (Ignore risk/control related logic until risk register future ticket)
  // Create new action -> create new reference
  // Change action type legal-pestel or pestel-legal -> remove old reference async && create new reference
  // Change action type from legal/pestel -> remove old reference
  const isPestel = (item: VendorActionModel | VendorActionExtended) =>
    item.actionType === VendorEventEventType.PestelReview;
  const isLegal = (item: VendorActionModel | VendorActionExtended) =>
    item.actionType === VendorEventEventType.LegalRegisterReview;
  const deleteReference = (
    action: VendorActionExtended,
    oldAction?: VendorActionExtended
  ) => {
    const typeChanged = oldAction.actionType !== action.actionType;

    const oldActionHasRegistry = isPestel(oldAction) || isLegal(oldAction);

    if (typeChanged && oldActionHasRegistry) {
      const referenceableId = isPestel(oldAction)
        ? currentVendor.registers.pestelRegisterId
        : currentVendor.registers.legalRegisterId;
      const referenceableType =
        oldAction.actionType === VendorEventEventType.PestelReview
          ? ReferenceCreatePayloadReferenceReferenceableTypeEnum.PestelRegister
          : ReferenceCreatePayloadReferenceReferenceableTypeEnum.LegalRegister;

      action?.id &&
        referenceableId &&
        dispatch(
          deleteActionReference(action?.id, referenceableId, referenceableType)
        );
    }
  };

  const afterCreateOrUpdate = async (
    action: VendorActionExtended,
    oldAction?: VendorActionExtended
  ) => {
    if (oldAction?.id) deleteReference(action, oldAction);

    if (isLegal(action)) {
      await dispatch(
        createActionReference(
          action.id,
          currentVendor.registers.legalRegisterId,
          ReferenceCreatePayloadReferenceReferenceableTypeEnum.LegalRegister,
          () => {}
        )
      );
    }

    if (isPestel(action)) {
      await dispatch(
        createActionReference(
          action.id,
          currentVendor.registers.pestelRegisterId,
          ReferenceCreatePayloadReferenceReferenceableTypeEnum.PestelRegister,
          () => {}
        )
      );
    }

    const message = 'Action was successfully saved';
    await refetchPageItems();
    dispatch(showGlobalToast(message));
    return;
  };

  const createOrUpdateAction = async (params: ActionFormType) => {
    const { completed, deletedAttachmentIds } = params;

    const createPayload = mapVendorActonFormToPostPayload(params);

    if (!currentActionId) {
      const newAction = await dispatch(
        createNewAction({ payload: createPayload })
      ).unwrap();

      afterCreateOrUpdate(newAction);

      return;
    }

    const updatePayload: updateActionParams = {
      ...createPayload,
      ...{ vendorActionDeletedAttachmentIds: deletedAttachmentIds },
      ...{ vendorActionCompleted: completed },
    };

    dispatch(updateAction(params, updatePayload, afterCreateOrUpdate));
  };

  const createOrUpdateIncident = async (params: IncidentFormType) => {
    const createPayload = mapIncidentFormTypeToPostOrPatchPayload(params);

    const message = 'Incident was successfully saved';
    if (!currentIncidentId) {
      try {
        await dispatch(createIncident(createPayload)).unwrap();
      } catch (error) {
        return;
      }
    } else {
      const updatePayload: updateIncidentParams = {
        ...createPayload,
        incidentEventCompleted: params.completed,
        incidentEventId: currentIncidentId,
        incidentEventDeletedAttachmentIds: params.deletedAttachmentIds,
      };
      try {
        await dispatch(updateIncident(updatePayload)).unwrap();
      } catch (error) {
        return;
      }
    }

    await refetchPageItems();
    dispatch(showGlobalToast(message));
    return;
  };

  const createOrUpdateEvent = async ({
    name,
    owner,
    description,
    date,
    eventType,
    url,
    attachments,
    deletedAttachmentIds,
  }: EventFormType) => {
    const payload: createEventParams = {
      vendorEventName: name,
      vendorEventOwnerId: owner.id,
      vendorEventDescription: description,
      vendorEventDate: date,
      vendorEventEventType: eventType,
      vendorEventUrl: url,
      vendorEventAttachments: mapFormAttachmentsToPayload(attachments),
    };

    const message = 'Event was saved successfully.';

    if (currentEventId) {
      const updatePayload = {
        ...payload,
        ...{ vendorEventDeletedAttachmentIds: deletedAttachmentIds },
        ...{ vendorEventId: currentEventId },
      };
      await dispatch(updateEvent(currentEventId, updatePayload, () => {}));
    } else {
      await dispatch(createEvent(payload));
    }

    await refetchPageItems();
    dispatch(showGlobalToast(message));
  };

  const createOrUpdateCorrectiveAction = async (
    params: CorrectiveActionFormType
  ) => {
    const createPayload: createCorrectiveActionParams =
      mapCorrectiveActionFormTypeToPostOrPatchPayload(params);

    const message = 'Corrective Action was successfully saved';

    if (!currentCorrectiveActionId) {
      try {
        const newAction = await dispatch(
          createCorrectiveAction(createPayload)
        ).unwrap();
      } catch (error) {
        return;
      }
    } else {
      const { completed, deletedAttachmentIds } = params;
      const updatePayload: updateCorrectiveActionParams = {
        correctiveActionId: currentCorrectiveActionId,
        ...createPayload,
        ...{
          correctiveActionCompleted: completed,
          correctiveActionDeletedAttachmentIds: deletedAttachmentIds,
          correctiveActionActionType:
            createPayload.correctiveActionActionType as unknown as CorrectiveActionsCorrectiveActionIdPatchCorrectiveActionActionTypeEnum,
        },
      };
      try {
        const updatedAction = await dispatch(
          updateCorrectiveAction(updatePayload)
        ).unwrap();
      } catch (error) {
        return;
      }
    }

    await refetchPageItems();
    dispatch(showGlobalToast(message));
    return;
  };

  const item = useMemo(
    () => items.find(filteredItem => filteredItem.id === currentCalendarItemId),
    [items, currentCalendarItemId]
  );

  const currentActionId: VendorActionModel['id'] = useMemo(
    () => (item && isAction(item) ? item.id : undefined),
    [item]
  );

  const currentEventId: VendorEventModel['id'] = useMemo(
    () => (item && isEvent(item) ? item.id : undefined),
    [item]
  );

  const currentCorrectiveActionId = useMemo(
    () => (item && isCorrectiveAction(item) ? item.id : undefined),
    [item]
  );

  const currentIncidentId = useMemo(
    () => (item && isIncident(item) ? item.id : undefined),
    [item]
  );

  const isLoading = isFetchingItems;
  const deleteActionCommand: CommandConfirmation = {
    title: 'Confirm Delete',
    subject: { name: item?.name, type: 'Action' },
    description: 'Click CONFIRM to delete this Action',
    buttonVariant: AdoptechButtonVariant.Warning,
    onConfirm: async () => {
      setShowEditActionModal(false);
      await dispatch(deleteAction(currentCalendarItemId));
      await refetchPageItems();
      dispatch(showGlobalToast('Action was deleted successfully.'));
    },
  };

  const deleteEventCommand: CommandConfirmation = {
    ...deleteActionCommand,
    description: 'Click CONFIRM to delete this Event',
    onConfirm: async () => {
      const id = currentCalendarItemId;
      setCurrentCalendarItemId(null);
      setShowEditEventModal(false);

      await dispatch(deleteEvent(id));
      await refetchPageItems();
      dispatch(showGlobalToast('Event was deleted successfully.'));
    },
  };

  const deleteCorrectiveActionCommand: CommandConfirmation = {
    ...deleteActionCommand,
    description: 'Click CONFIRM to delete this Corrective Action',
    onConfirm: async () => {
      setShowCorrectiveActionModal(false);
      await dispatch(deleteCorrectiveAction(currentCalendarItemId));
      await refetchPageItems();
      dispatch(showGlobalToast('Corrective Action was deleted successfully.'));
    },
  };

  const deleteIncidentCommand: CommandConfirmation = {
    ...deleteActionCommand,
    description: 'Click CONFIRM to delete this Incident',
    onConfirm: async () => {
      setShowIncidentModal(false);
      await dispatch(deleteIncident(currentCalendarItemId));
      await refetchPageItems();
      dispatch(showGlobalToast('Incident was deleted successfully.'));
    },
  };

  const noMorePages = () => {
    const noPages = filters.totalPages === 0;
    const pageLimitReached = filters.page + 1 > filters.totalPages;
    return filters.totalPages !== null && (noPages || pageLimitReached);
  };

  const onScroll = () => {
    const container = document.getElementById(scrollContainer);
    const reachedToBottom =
      container.offsetHeight + container.scrollTop >= container.scrollHeight;

    if (reachedToBottom && !noMorePages()) {
      const nextPage = filters.page + 1;
      const filtersPayload = {
        ...filters,
        ...{
          page: nextPage,
        },
      };

      dispatch(setCalendarFilters(filtersPayload));
      dispatch(
        fetchCalendarItems({
          filters: filtersPayload,
          currentVendorUserId: currentVendorUser.id,
        })
      );
    }
  };
  useEffect(() => {
    const container = document.getElementById(scrollContainer);
    container.addEventListener('scroll', onScroll);
    return () => {
      container.removeEventListener('scroll', onScroll);
    };
  }, [filters, currentVendorUser.id]);

  const noDataPlaceholders = {
    actions_and_events:
      'No Actions or Events have been created, to begin select Add new.',
    incident_log: 'No Incidents have been created, to begin select Add new.',
    audit_schedule: 'No Events have been scheduled, to begin select Add new.',
    corrective_actions:
      'No Corrective Actions have been created, to begin select Add new.',
  };
  return (
    <div className={baseCss}>
      <CalendarHeader
        setCurrentCalendarItemId={setCurrentCalendarItemId}
        setShowEditActionModal={setShowEditActionModal}
        setShowEditEventModal={setShowEditEventModal}
        setShowCorrectiveActionModal={setShowCorrectiveActionModal}
        setShowIncidentModal={setShowIncidentModal}
      />

      <AdoptechPanel>
        <CalendarSearch />
        {isLoading && filters.page === 1 ? (
          <LoadingSpinner />
        ) : (
          <>
            <>
              {Object.entries(filteredGroupedByMonthItems).length === 0 && (
                <NoDataText
                  extraClass="mt-2"
                  text={noDataPlaceholders[filters.viewMode]}
                />
              )}
            </>
            <>
              {Object.entries(filteredGroupedByMonthItems).map(
                ([monthName, sortedMonthItems]) => {
                  return (
                    <div key={monthName}>
                      <div className={baseCss + `--${monthName}`}>
                        {monthName}
                      </div>
                      <CalendarItemsList
                        showTooltip
                        gridName={monthName}
                        items={sortedMonthItems}
                        onClick={handleRowClick}
                        showAllItems={showAllItems}
                      />
                    </div>
                  );
                }
              )}
            </>
          </>
        )}

        {isLoading && filters.page > 1 && <LoadingSpinner inlineSmall />}
      </AdoptechPanel>
      {showEditActionModal && (
        <EditActionModal
          init={{ id: currentActionId }}
          confirm={createOrUpdateAction}
          show={showEditActionModal}
          close={() => setShowEditActionModal(false)}
          onDelete={(id: VendorActionModel['id']) => {
            setShowEditActionModal(false);
            command(deleteActionCommand);
          }}
        />
      )}
      {showEditEventModal && (
        <EditEventModal
          init={currentEventId}
          confirm={createOrUpdateEvent}
          show={showEditEventModal}
          onDelete={(id: VendorEventModel['id']) => {
            setShowEditEventModal(false);
            command(deleteEventCommand);
          }}
          close={() => setShowEditEventModal(false)}
        />
      )}

      {showCorrectiveActionModal && (
        <EditCorrectiveActionModal
          init={currentCorrectiveActionId}
          confirm={createOrUpdateCorrectiveAction}
          show={showCorrectiveActionModal}
          onDelete={(id: CorrectiveActionExtended['id']) => {
            setShowCorrectiveActionModal(false);
            command(deleteCorrectiveActionCommand);
          }}
          close={() => setShowCorrectiveActionModal(false)}
        />
      )}

      {showIncidentModal && (
        <EditIncidentModal
          init={currentIncidentId}
          confirm={createOrUpdateIncident}
          show={showIncidentModal}
          onDelete={(id: IncidentEventExtended['id']) => {
            setShowIncidentModal(false);
            command(deleteIncidentCommand);
          }}
          close={() => setShowIncidentModal(false)}
        />
      )}

      <ConfirmationModal
        command={currentCommand}
        onCancel={confirmed => {
          // if we click cancel or outside delete modal, show edit modal
          if (!confirmed) {
            if (currentActionId) return setShowEditActionModal(true);
            if (currentEventId) return setShowEditEventModal(true);
            if (currentCorrectiveActionId)
              return setShowCorrectiveActionModal(true);
            if (currentIncidentId) return setShowIncidentModal(true);
          }
          command(null);
        }}
      />
    </div>
  );
};
