import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './EditEventModal.scss';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import moment from 'moment';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import AdoptechModal from '../../../components/AdoptechModal/AdoptechModal';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { AdoptechTextArea2 } from '../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { AdoptechDatePicker2 } from '../../../components/AdoptechDatePicker2/AdoptechDatePicker2';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { VendorEventEventType, VendorEventModel } from '../../../swagger';
import { fetchEvent } from '../store/calendarThunks';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';
import { AccessObject } from '../../../types/accessObject';
import { canFeature } from '../../../functions/access';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import CalendarAttachments from './CalendarAttachments/CalendarAttachments';
import { EventFormType } from '../CalendarPage/CalendarPage';

interface Props {
  init?: VendorEventModel['id'];
  close: () => void;
  confirm: ({
    name,
    description,
    owner,
    date,
    eventType,
  }: EventFormType) => void;
  show: boolean;
  onDelete?: (id: VendorEventModel['id']) => void;
}

const baseCss = 'editEventModal';

export const eventTypes = [
  ['Document Review', VendorEventEventType.DocumentReview],
  ['External Audit', VendorEventEventType.ExternalAudit],
  ['Internal Audit', VendorEventEventType.InternalAudit],
  ['Legal Register Review', VendorEventEventType.LegalRegisterReview],
  ['Pestel Review', VendorEventEventType.PestelReview],
  ['Risk Register Review', VendorEventEventType.RiskRegisterReview],
  ['Management meeting', VendorEventEventType.SecurityMeeting],
  ['Security Training', VendorEventEventType.SecurityTraining],
  ['Control Review', VendorEventEventType.ControlReview],
  ['Control Check Review', VendorEventEventType.ControlCheckReview],
  ['Agreement Review', VendorEventEventType.AgreementReview],
  ['Policy Review', VendorEventEventType.PolicyReview],
  ['Incident BCP/DR Simulation', VendorEventEventType.IncidentBcpDrSimulation],
  ['Manual Test Due', VendorEventEventType.AssertionDueDate],
  ['Other', VendorEventEventType.Other],
];

export const EditEventModal: React.FC<Props> = ({
  init = undefined,
  confirm = () => {},
  onDelete = () => {},
  close,
  show,
}) => {
  const { users, ownerOptions: userOptions } = useOwnerOptions({
    includingGuest: true,
  });
  const [model, setModel] = useState({ id: init } as EventFormType);
  const deadline = model.date ? new Date(model.date) : null;

  const dataMissing =
    !model.name || !model.owner || !model.date || !model.eventType;

  const modalTitle = model.id ? 'Edit Event' : 'Create Event';

  const actionName = model.id ? 'Save' : 'Create';
  const hiddenTypes: string[] = [
    VendorEventEventType.ControlReview,
    VendorEventEventType.ControlCheckReview,
    VendorEventEventType.PolicyReview,
    VendorEventEventType.AgreementReview,
    VendorEventEventType.AssertionDueDate,
  ];
  const canManageCalendar = canFeature(AccessObject.calendar_manage);
  const readonlyMode = (model.id && !model.editable) || !canManageCalendar;

  const typeOptions = eventTypes
    .map(([label, value]) => ({ label, value }))
    .filter(option =>
      readonlyMode ? true : !hiddenTypes.includes(option.value)
    );

  const dispatch = useAppDispatch();
  const {
    createEventStatus,
    fetchActionStatus,
    fetchVendorEventStatus,
    isUpdatingCalendarItem,
    deleteActionStatus,
    deleteEventStatus,
  } = useSelector((state: ApplicationState) => state.calendar);
  const isDeletingCalendarItem =
    deleteActionStatus === 'loading' || deleteEventStatus === 'loading';
  const isFetchingCalendarItem =
    fetchActionStatus === 'loading' || fetchVendorEventStatus === 'loading';
  const isLoading = isFetchingCalendarItem || isDeletingCalendarItem;

  const fetchEventAsync = async (id: string) => {
    const action = await dispatch(fetchEvent(id)).unwrap();

    setModel(action);
  };

  useEffect(() => {
    model.id && fetchEventAsync(model.id);
  }, [model.id]);

  return (
    <AdoptechModal className={baseCss} onHide={close} show={show}>
      <Modal.Header>
        <div>{modalTitle}</div>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <div className={baseCss + '--body'}>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextInput2
                  id="createEventName"
                  rounded
                  label="Title"
                  type="text"
                  value={model.name}
                  onChange={e =>
                    setModel({ ...model, name: e.currentTarget.value })
                  }
                  hasError={!model.name}
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextArea2
                  rounded
                  id="createEventDescription"
                  label="Describe this event"
                  value={model.description}
                  onChange={e =>
                    setModel({ ...model, description: e.currentTarget.value })
                  }
                  icon={faPen}
                  disabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechReactSelect2
                  id={baseCss}
                  rounded
                  options={typeOptions}
                  label="Event type"
                  isClearable={false}
                  hasError={!model.eventType}
                  onChange={option =>
                    setModel({
                      ...model,
                      eventType: option.value as VendorEventEventType,
                    })
                  }
                  value={typeOptions.filter(
                    option => option.value === model.eventType
                  )}
                  placeholder="Please select"
                  isDisabled={readonlyMode}
                />
              </div>
              <div className={baseCss + '--field'}>
                <AdoptechReactSelect2
                  placeholder="Select an owner"
                  rounded
                  label="Owner"
                  id="createEventOwner"
                  options={userOptions}
                  hasError={!model.owner}
                  onChange={e => {
                    setModel({
                      ...model,
                      owner: users.find(user => user.id === e.value),
                    });
                  }}
                  value={userOptions.find(
                    option => option.value == model.owner?.id
                  )}
                  isDisabled={readonlyMode}
                />
              </div>
            </div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechDatePicker2
                  id="createEventDate"
                  rounded
                  labelText="Date"
                  placeHolderText="Select a date"
                  minDate={null}
                  outputDate={deadline}
                  hasError={!model.date}
                  onDateSelect={d => {
                    const endOfDay = moment(d).endOf('day');
                    const endOfDayToDateTime = endOfDay.toJSON();

                    setModel({
                      ...model,
                      date: endOfDayToDateTime,
                    });
                  }}
                  disabled={readonlyMode}
                />
              </div>
              <div className={baseCss + '--field'}></div>
            </div>
            <CalendarAttachments
              baseCss={baseCss}
              model={model}
              setModel={setModel}
              readonlyMode={readonlyMode}
              type="event"
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {model.id ? (
          <div>
            <AdoptechButton
              disabled={readonlyMode}
              extraClass={baseCss + '--deleteButton'}
              icon={faTrashAlt}
              onClick={() => onDelete(model.id)}
            >
              Delete
            </AdoptechButton>
          </div>
        ) : (
          <AdoptechButton
            onClick={() => {
              close();
            }}
          >
            Cancel
          </AdoptechButton>
        )}
        <AdoptechButton
          disabled={dataMissing || readonlyMode}
          busy={createEventStatus == 'loading' || isUpdatingCalendarItem}
          onClick={() => confirm(model)}
          variant={AdoptechButtonVariant.Primary}
        >
          {actionName}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
