import './TestLogPage.scss';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  AssertionCategoryModel,
  AssertionRunModel,
  AssertionRunModelStatusEnum,
} from '../../../swagger';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAssertionCategories,
  fetchAssertionRuns,
} from './store/testsThunks';
import { ApplicationState } from '../../../types/applicationState';
import { formatShortDateTime } from '../../../functions/formatShortDateTime';
import { Pager } from './Pager';
import { Status } from './Status';
import { EventDetailsDrawer } from './EventDetailsDrawer';
import {
  useQueryParams,
  useRemoveQueryParam,
} from '../../../hooks/useQueryParams';
import { useBackground } from '../../../hooks/useBackground';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { AdoptechCheckbox } from '../../../components/AdoptechCheckbox/AdoptechCheckbox';

const baseCss = 'testLogPage';

export const TestLogPage: React.FC = () => {
  useBackground('grey');

  const events = useSelector(
    (state: ApplicationState) => state.tests.assertionRuns.data || []
  );
  const categories = useSelector(
    (state: ApplicationState) => state.tests.assertionCategories
  );

  const [selectedCategory, setSelectedCategory] =
    useState<AssertionCategoryModel['identifier']>();
  const categoryOptions = categories.map(category => ({
    value: category.identifier,
    label: category.name,
  }));

  const [page, setPage] = useState(1);
  const totalPages = useSelector(
    (state: ApplicationState) =>
      state.tests.assertionRuns?.meta?.totalPages || 1
  );
  const totalEntries = useSelector(
    (state: ApplicationState) =>
      state.tests.assertionRuns?.meta?.totalEntries || 0
  );

  const statusOptions = [
    { label: 'Failed', value: AssertionRunModelStatusEnum.Failed },
    { label: 'Compliant', value: AssertionRunModelStatusEnum.Compliant },
  ];

  const [showManual, setShowManual] = useState(false);

  const [selectedStatus, setSelectedStatus] =
    useState<AssertionRunModelStatusEnum>();

  const dispatch = useDispatch();

  const queryParams = useQueryParams();

  const removeQueryParam = useRemoveQueryParam();

  const searchQuery = queryParams.get('search');
  const [search, setSearch] = useState(searchQuery || '');

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    removeQueryParam('search');
    setSearch(e.currentTarget.value);
  };
  useEffect(() => {
    dispatch(fetchAssertionCategories());
  }, []);

  const fetchResults = () =>
    dispatch(
      fetchAssertionRuns(
        selectedCategory,
        search,
        selectedStatus,
        showManual,
        `${page}`
      )
    );

  useEffect(() => {
    setPage(1);
    fetchResults();
  }, [selectedCategory, search, selectedStatus, showManual]);

  useEffect(() => {
    fetchResults();
  }, [page]);

  const [selectedEventId, setSelectedEventId] =
    useState<AssertionRunModel['id']>();
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);

  const openDetailsDrawer = (event: AssertionRunModel) => () => {
    setSelectedEventId(event.id);
    setShowDetailsDrawer(true);
  };

  const closeDetailsDrawer = () => {
    // keep eventId in case of the same event reopen - it will not trigger request
    setShowDetailsDrawer(false);
  };

  return (
    <div className={baseCss}>
      <div className={baseCss + '--sticky'}>
        <div className={baseCss + '--header'}>
          <div className={baseCss + '--title'}>
            Test results
            <Lozenge value={totalEntries} />
          </div>
          <div className={baseCss + '--divider'} />
          <AdoptechReactSelect
            id="testLogPageCategorySelect"
            placeholder="Select category"
            value={
              selectedCategory &&
              categoryOptions.find(o => o.value === selectedCategory)
            }
            options={[
              { label: 'No selection', value: null },
              ...categoryOptions,
            ]}
            onChange={e => setSelectedCategory(e.value)}
          />
          <AdoptechReactSelect
            id="testLogPageStatusSelect"
            placeholder="Select status"
            value={
              selectedStatus &&
              statusOptions.find(o => o.value === selectedStatus)
            }
            options={[{ label: 'No selection', value: null }, ...statusOptions]}
            onChange={e => setSelectedStatus(e.value)}
          />
          <div className={baseCss + '--divider'} />
          <AdoptechCheckbox
            color="white"
            checked={showManual}
            id="checkbox-tests-show-inactive"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setShowManual(e.target.checked)
            }
            label="Show manual tests"
            className="checkbox-show-inactive"
          />
          <Pager page={page} setPage={setPage} totalPages={totalPages} />
        </div>
        <div className={baseCss + '--list-header'}>
          <AdoptechTextInput
            id="testLogPageSearch"
            additionalClass="adoptechTextInput-search"
            onChange={handleSearch}
            placeholder="Search Test name"
            value={search}
            icon={faSearch}
            type="text"
          />
          <div className={baseCss + '--list-heading'}>
            <span>Source</span>
            <span>Category</span>
            <span>Time</span>
            <span>Test name</span>
            <span>Result</span>
          </div>
        </div>
      </div>
      <div className={baseCss + '--list'}>
        {events.map(event => (
          <div
            className={baseCss + '--list-row'}
            key={`assertion-run-${event.id}`}
            onClick={openDetailsDrawer(event)}
          >
            <div>{event.source}</div>
            <div>{event.assertionCategory?.name || '-'}</div>
            <div>{formatShortDateTime(event.startedAt)}</div>
            <div>{event.name}</div>
            <Status status={event.status} />
          </div>
        ))}
      </div>
      <EventDetailsDrawer
        show={showDetailsDrawer}
        eventId={selectedEventId}
        onClose={closeDetailsDrawer}
      />
    </div>
  );
};
