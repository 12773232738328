import React, { useState } from 'react';
import { RegisterReviewFormProps } from './RegisterReviewDrawer';
import { Accordion } from 'react-bootstrap';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import { AdoptechTextArea2 } from '../AdoptechTextArea2/AdoptechTextArea2';
import { AdoptechReactSelect2 } from '../AdoptechReacSelect2/AdoptechReactSelect2';
import { SelectionOption } from '../../types/selectionOption';
import { ReviewStatusEnum } from '../../swagger/models/ReviewStatusEnum';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { calculateNextReviewDate } from '../PolicyOverviewSection/PolicyOverviewSection';
import moment from 'moment';
import { formatShortDate } from '../../functions/formatShortDate';
import {
  ReviewHistoryItemCreatePayloadReviewHistoryItem,
  ReviewHistoryItemCreatePayloadReviewHistoryItemRelatedToTypeEnum,
} from '../../swagger/models/ReviewHistoryItemCreatePayloadReviewHistoryItem';
import { postReviewHistoryItem } from '../../store/compliance/complianceThunks';
import { updateLegalRegisterReviewSuccess } from '../../store/legalRegister/legalRegisterSlice';
import { RegistryHeaderProps } from '../RegistryHeader/RegisterHeader';
import { updatePestelRegisterReviewSuccess } from '../../store/pestel/pestelSlice';
import {
  ControlReviewFrequencyEnum,
  LegalRegisterModel,
  ReviewFrequencyEnum,
} from '../../swagger';

interface OverviewSectionProps {
  formData: RegisterReviewFormProps;
  setFormData: React.Dispatch<React.SetStateAction<RegisterReviewFormProps>>;
  register: RegistryHeaderProps['register'];
  registerType: RegistryHeaderProps['registerType'];
}

export const OverviewSection: React.FC<OverviewSectionProps> = ({
  formData,
  setFormData,
  register,
  registerType,
}) => {
  const isLegal = registerType === 'LegalRegister';
  const baseCss = 'registerReviewDrawer';
  const reviewFrequencyOptions = Object.values(ReviewFrequencyEnum).map(
    value => {
      return { label: `In ${value}`, value: value };
    }
  );
  const { isPostingReviewHistoryItem } = useSelector(
    (state: ApplicationState) => state.compliance
  );
  const [selectedReviewFrequencyOption, setSelectedReviewFrequencyOption] =
    useState<SelectionOption>(
      reviewFrequencyOptions.find(
        option => option.value === register.reviewFrequency
      )
    );

  const showNextReviewDate =
    register.reviewStatus === ReviewStatusEnum.Reviewed &&
    selectedReviewFrequencyOption?.value &&
    register.nextReviewDate;

  const dispatch = useDispatch();
  const placeholder =
    `The Legal register should be reviewed on a regular basis, this is typically carried out as part of your security management reviews.

Comment here on who reviewed the register, when, and how it was reviewed. Make sure you take a snapshot (generate a report) as the Auditor will want to see a version controlled Legal register report.`.replace(
      'Legal',
      isLegal ? 'Legal' : 'PESTEL'
    );

  return (
    <Accordion defaultActiveKey="0">
      <AdoptechAccordionCard
        title="Review Register"
        index="0"
        cardBodyClass="card-body--centered withBorderBottom"
        headerClass="noBorderBottom positionInitial"
        iconSize="small"
        noMargin
      >
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <AdoptechTextArea2
              label="Review comments"
              rounded
              placeholder={placeholder}
              onChange={e => {
                const text = e.currentTarget.value;
                const payload = { ...formData, reviewComments: text };
                setFormData(payload);
              }}
              value={formData.reviewComments}
              id="reviewComments"
              disabled={formData.reviewSubmitted}
              rows={6}
            />
          </div>
        </div>
        <div className={baseCss + '--fieldRow'}>
          <div className={baseCss + '--field'}>
            <AdoptechReactSelect2
              id="review-frequency"
              isDisabled={formData.reviewSubmitted}
              options={reviewFrequencyOptions}
              onChange={(selectedOption: SelectionOption) => {
                const payload = {
                  ...formData,
                  reviewFrequency: selectedOption.value as ReviewFrequencyEnum,
                };
                setFormData(payload);
                setSelectedReviewFrequencyOption(selectedOption);
              }}
              value={selectedReviewFrequencyOption}
              placeholder="Set review frequency"
              label="Next review"
              bottomComponent={
                showNextReviewDate ? (
                  <div className="nextReviewDateHint">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    {`Next review on ${formatShortDate(
                      register.reviewFrequency ===
                        selectedReviewFrequencyOption.value // if frequency not changed
                        ? register.nextReviewDate // BE date
                        : calculateNextReviewDate(
                            // FE dynamic calculated date
                            moment().toISOString(),
                            selectedReviewFrequencyOption.value
                          )
                    )}`}
                  </div>
                ) : null
              }
            />
          </div>
        </div>

        <div
          className={baseCss + '--fieldRow'}
          style={{ marginTop: showNextReviewDate ? 20 : 0 }}
        >
          <div className={baseCss + '--field'}>
            <AdoptechButton
              variant={AdoptechButtonVariant.White}
              disabled={
                formData.reviewSubmitted || !selectedReviewFrequencyOption
              }
              busy={isPostingReviewHistoryItem}
              extraClass="adoptechButton-smallRounded"
              onClick={e => {
                e.preventDefault();

                const payload: ReviewHistoryItemCreatePayloadReviewHistoryItem =
                  {
                    relatedToType: isLegal
                      ? ReviewHistoryItemCreatePayloadReviewHistoryItemRelatedToTypeEnum.LegalRegister
                      : ReviewHistoryItemCreatePayloadReviewHistoryItemRelatedToTypeEnum.PestelRegister,
                    relatedToId: register.id,
                    reviewFrequency:
                      selectedReviewFrequencyOption?.value as unknown as ControlReviewFrequencyEnum,
                    reviewComment: formData.reviewComments,
                  };

                dispatch(
                  postReviewHistoryItem(payload, historyItem => {
                    const nextReviewDate = calculateNextReviewDate(
                      historyItem.createdAt,
                      selectedReviewFrequencyOption?.value
                    );

                    setFormData({
                      ...formData,
                      nextReviewDate,
                      reviewSubmitted: true,
                    });

                    const registryUpdatePayload = {
                      nextReviewDate,
                      reviewStatus: ReviewStatusEnum.Reviewed,
                      reviewOverdue: false,
                      lastReviewedAt: register.lastReviewedAt || moment(),
                      reviewFrequency:
                        selectedReviewFrequencyOption.value as ReviewFrequencyEnum,
                    };
                    dispatch(
                      isLegal
                        ? updateLegalRegisterReviewSuccess(
                            registryUpdatePayload
                          )
                        : updatePestelRegisterReviewSuccess(
                            registryUpdatePayload
                          )
                    );
                  })
                );
              }}
            >
              {formData.reviewSubmitted ? 'Review submitted' : 'Submit review'}
            </AdoptechButton>
          </div>
        </div>
      </AdoptechAccordionCard>
    </Accordion>
  );
};
