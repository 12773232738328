import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialise } from '../../store/global/globalThunks';
import { ApplicationState } from '../../types/applicationState';
import { LoadingScreen } from '../LoadingScreen/LoadingScreen';
import { selectUserRoles } from '../../selectors/selectUserRoles';
import { VendorUserRoles } from '../../swagger';
import { welcomeRoute } from '../Routes/Routes';

export const Home: React.FC = () => {
  const dispatch = useDispatch();

  const isInitialised = useSelector(
    (state: ApplicationState) => state.global.isInitialised
  );

  const vendors = useSelector(
    (state: ApplicationState) => state.vendors.vendors
  );

  const userRoles = useSelector(selectUserRoles);

  useEffect(() => {
    // Fetch data to allow decision to be made on initial page to show
    if (!isInitialised) {
      dispatch(initialise());
      return;
    }

    // If no vendors present, redirect to welcome pages
    if (vendors.length === 0) {
      dispatch(push(welcomeRoute));
      return;
    }

    // If user is Guest then redirect to Company Profile
    if (
      userRoles.filter(roles => roles !== VendorUserRoles.Guest).length === 0
    ) {
      dispatch(push('/r/companyInformation/profile'));
    }
    // Default to dashboard
    dispatch(push('/r/dashboard'));
  }, []);

  return <LoadingScreen />;
};
