import './RiskRegisterTreatment.scss';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ControlExtended,
  ControlModel,
  RiskExtended,
  RiskModelStatusEnum,
  RiskModelTreatmentStrategyEnum,
  TaskExtended,
  VendorActionExtended,
  VendorActionModel,
  VendorEventEventType,
} from '../../../swagger';
import {
  commitCurrentRisk,
  createRiskActionReference,
  createRiskControlReference,
  deleteRiskActionReference,
  deleteRiskControlReference,
  fetchRisk,
  fetchVendorActions,
} from '../../../store/riskRegistry/riskRegistryThunks';
import { ApplicationState } from '../../../types/applicationState';
import { SelectionOption } from '../../../types/selectionOption';
import { AdoptechReactSelect } from '../../AdoptechReactSelect/AdoptechReactSelect';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { AdoptechTextArea } from '../../AdoptechTextArea/AdoptechTextArea';
import { UpdateModelFunction } from '../../../types/UpdateModelFunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { EditActionModal } from '../../EditActionModal/EditActionModal';
import { CommandConfirmation } from '../../../types/CommandConfirmation';
import { Interweave } from 'interweave';
import { mapVendorActonFormToPostPayload } from '../../../features/calendar/CalendarPage/CalendarPage';
import { createNewAction } from '../../../features/calendar/store/calendarThunks';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

const baseCss = 'riskRegisterTreatment';

type Command = Dispatch<SetStateAction<CommandConfirmation>>;

const treatmentStrategyOptions = [
  {
    value: RiskModelTreatmentStrategyEnum.Modify,
    label:
      '<b>Modify</b><br/>Apply security controls to reduce the likelihood or impact.',
    title: 'Add actions',
    description: `
      Add actions required to mitigate this risk that are not covered in controls.
    `,
  },
  {
    value: RiskModelTreatmentStrategyEnum.Share,
    label:
      '<b>Share</b><br/>Distribute the risk with a partner, such as an insurance firm.',
    title: 'Share risk',
    description: 'Provide an explanation of how the risk is being shared.',
  },
  {
    value: RiskModelTreatmentStrategyEnum.Avoid,
    label:
      '<b>Avoid</b><br/>Cease the activity or changing the circumstances that are causing the risk',
    title: 'Avoid risk',
    description: 'Provide an explanation of how the risk is being avoided.',
  },
  {
    value: RiskModelTreatmentStrategyEnum.Retain,
    label:
      '<b>Retain</b><br/>The risk is within your agreed tolerance levels, or the cost to treat the risk would outweigh the benefit.',
    title: 'Retain risk',
    description: 'Provide an explanation of how the risk is being retained.',
  },
];

const TreatmentStrategyTask: React.FC<{
  task: TaskExtended;
}> = ({ task }) => {
  return (
    <div className={baseCss + '--tasksRow'}>
      <div>
        <span className={baseCss + '--taskSlug'}> Check </span>
        {task.name}
      </div>
      <FontAwesomeIcon
        icon={task.status === 'compliant' ? faCheckCircle : faCircle}
        className="mr-4"
      />
      <div></div>
    </div>
  );
};

const TreatmentStrategyControl: React.FC<{
  risk: RiskExtended;
  control: ControlExtended;
  onChange(): void;
  command: Command;
}> = ({ risk, control, onChange, command }) => {
  const dispatch = useDispatch();
  const compliant = control.tasks.every(t => t.status === 'compliant');

  const removeControl = () => {
    command({
      ...(risk.status == RiskModelStatusEnum.Approved
        ? {
            title: 'Continue to editing',
            description: `
            Deleting this control will change this Risk.
            Are you sure you wish to continue to editing? The risk will need to
            be re-approved. To return to viewing click Cancel or continue to
            editing by selecting Edit.
          `,
            action: 'Edit',
            buttonVariant: AdoptechButtonVariant.Primary,
          }
        : {
            subject: { name: control.name, type: 'Control' },
            action: 'Delete',
            buttonVariant: AdoptechButtonVariant.Warning,
          }),
      onConfirm: () => {
        dispatch(deleteRiskControlReference(control.id, risk.id, onChange));
      },
    });
  };

  return (
    <>
      <div className={baseCss + '--controlsRow'}>
        <div>
          <span className={baseCss + '--controlSlug'}> Control </span>
          {control.name}
        </div>
        <FontAwesomeIcon
          icon={compliant ? faCheckCircle : faCircle}
          className="mr-4"
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="mr-4"
          onClick={removeControl}
        />
        <div />
      </div>
      {control.tasks.map(task => (
        <TreatmentStrategyTask key={task.id} task={task} />
      ))}
    </>
  );
};

const TreatmentControls: React.FC<{
  risk: RiskExtended;
  command: Command;
  update: UpdateModelFunction<RiskExtended>;
}> = ({ risk, update, command }) => {
  const dispatch = useDispatch();

  const controls = useSelector(
    (state: ApplicationState) => state.riskRegistry.controls
  );

  const [chosenControlId, setChosenControlId] =
    useState<ControlModel['id']>(null);

  if (risk.treatmentStrategy !== RiskModelTreatmentStrategyEnum.Modify)
    return <></>;

  const reFetchRisk = () => dispatch(fetchRisk(risk.id));

  const addedControlIds = risk.controls.map(c => c.id);

  const controlsOptions: SelectionOption[] = controls
    .filter(c => !addedControlIds.includes(c.id))
    .map(c => ({
      label: c.name,
      value: c.id,
    }));

  const addControl = () => {
    update(m => {
      dispatch(
        createRiskControlReference(chosenControlId, risk.id, reFetchRisk)
      );
      setChosenControlId(null);
      return m;
    });
  };

  const description = `
    Map the controls that will be used to modify this risk. Select a control
    to view the related plan, checks and evidence.
  `;

  return (
    <>
      <div className={baseCss + '--treatmentStrategyModify'}>
        <strong>Add controls</strong>
        <p>{description}</p>
        <div className={baseCss + '--fieldRow'}>
          {controls.length && (
            <AdoptechReactSelect
              id="riskAddControl"
              key={`riskAddControl-${chosenControlId}`}
              options={controlsOptions}
              onChange={option => setChosenControlId(option.value)}
              value={controlsOptions.find(o => o.value == chosenControlId)}
              placeholder="Start typing to add control"
            />
          )}
          <AdoptechButton
            disabled={
              addedControlIds.includes(chosenControlId) ||
              chosenControlId == null
            }
            onClick={addControl}
          >
            + Add control
          </AdoptechButton>
        </div>
        {risk.controls.length > 0 && (
          <div className={baseCss + '--controlsHeader'}>
            <div>Description</div>
            <div>Status</div>
          </div>
        )}
        {risk.controls.map(control => (
          <TreatmentStrategyControl
            key={control.id}
            risk={risk}
            control={control}
            onChange={reFetchRisk}
            command={command}
          />
        ))}
      </div>
      <hr />
    </>
  );
};

const TreatmentAction: React.FC<{
  action: VendorActionModel;
  onDelete(): void;
}> = ({ action, onDelete }) => {
  return (
    <div className={baseCss + '--actionRow'}>
      <div>
        <span className={baseCss + '--actionSlug'}> Action </span>
        {action.name}
      </div>
      <FontAwesomeIcon
        icon={action.completed ? faCheckCircle : faCircle}
        className="mr-4"
      />
      <FontAwesomeIcon icon={faTrashAlt} className="mr-4" onClick={onDelete} />
    </div>
  );
};

const TreatmentActions: React.FC<{
  risk: RiskExtended;
  update: UpdateModelFunction<RiskExtended>;
  command: Command;
}> = ({ risk, update, command }) => {
  const dispatch = useAppDispatch();

  const treatmentStrategy = treatmentStrategyOptions.find(
    ts => ts.value == risk.treatmentStrategy
  );

  const vendorActions = useSelector(
    (state: ApplicationState) => state.riskRegistry.vendorActions
  );

  const actionIds = risk.vendorActions.map(va => va.id);

  const actionOptions = vendorActions
    .filter(t => !actionIds.includes(t.id))
    .map(t => ({ value: t.id, label: t.name }));

  const [chosenActionId, setChosenActionId] =
    useState<VendorActionModel['id']>();

  const reFetchRisk = () => {
    dispatch(fetchRisk(risk.id));
  };

  const [showCreateAction, setShowCreateAction] = useState(false);
  const afterCreate = (action: VendorActionExtended) => {
    dispatch(createRiskActionReference(action.id, risk.id, reFetchRisk));
    dispatch(fetchVendorActions());
  };
  const createAction = async (params: VendorActionExtended) => {
    const payload = mapVendorActonFormToPostPayload(params);

    setChosenActionId(null);
    const newAction = await dispatch(createNewAction({ payload })).unwrap();

    afterCreate(newAction);

    setShowCreateAction(false);
  };

  const addAction = () => {
    update(m => {
      dispatch(createRiskActionReference(chosenActionId, risk.id, reFetchRisk));
      setChosenActionId(null);
      return m;
    });
  };

  const removeAction = (action: VendorActionModel) => () => {
    command({
      ...(risk.status == RiskModelStatusEnum.Approved
        ? {
            title: 'Continue to editing',
            description: `
            Deleting this action will change this Risk.
            Are you sure you wish to continue to editing? The risk will need to
            be re-approved. To return to viewing click Cancel or continue to
            editing by selecting Edit.
          `,
            action: 'Edit',
            buttonVariant: AdoptechButtonVariant.Primary,
          }
        : {
            subject: { name: action.name, type: 'Action' },
            action: 'Delete',
            buttonVariant: AdoptechButtonVariant.Warning,
          }),
      onConfirm: () => {
        dispatch(deleteRiskActionReference(action.id, risk.id, reFetchRisk));
      },
    });
  };

  if (!treatmentStrategy) return <></>;

  const showExplanation =
    risk.treatmentStrategy !== RiskModelTreatmentStrategyEnum.Modify;
  const Explanation = (
    <>
      <div className={baseCss + '--fieldRow'}>
        <AdoptechTextArea
          id="riskTreatmentExplanation"
          value={risk.treatmentExplanation}
          onChange={e => {
            const value = `${e.currentTarget.value}`;
            update(m => ({ ...m, treatmentExplanation: value }), true);
          }}
          onBlur={() => dispatch(commitCurrentRisk())}
        />
      </div>
      <p>
        Are there additional actions required to fulfil this risk treatment
        plan? Add the required actions.
      </p>
    </>
  );

  return (
    <div className={baseCss + '--treatmentStrategySimple'}>
      <strong>{treatmentStrategy.title}</strong>
      <p>{treatmentStrategy.description}</p>
      {showExplanation && Explanation}
      <div className={baseCss + '--fieldRow'}>
        <AdoptechReactSelect
          allowAdd
          onAdd={() => setShowCreateAction(true)}
          id="riskAddAction"
          addText="+ Create new Action"
          key={`riskAddAction-${chosenActionId}`}
          options={actionOptions}
          onChange={t => setChosenActionId(t.value)}
          value={actionOptions.find(a => a.value == chosenActionId)}
          placeholder="Start typing to add action"
        />
        <AdoptechButton disabled={!chosenActionId} onClick={addAction}>
          + Add action
        </AdoptechButton>
      </div>
      {risk.vendorActions.length > 0 && (
        <div className={baseCss + '--tasksHeader'}>
          <div>Description</div>
          <div>Status</div>
        </div>
      )}
      {risk.vendorActions.map(action => (
        <TreatmentAction
          key={action.id}
          action={action}
          onDelete={removeAction(action)}
        />
      ))}
      <EditActionModal
        init={{ actionType: VendorEventEventType.RiskRegisterReview }}
        confirm={createAction}
        show={showCreateAction}
        close={() => setShowCreateAction(false)}
        disabledFields={['actionType']}
      />
    </div>
  );
};

export interface Props {
  risk: RiskExtended;
  update: UpdateModelFunction<RiskExtended>;
  command: Command;
}

export const RiskRegisterTreatment: React.FC<Props> = ({
  risk,
  update,
  command,
}) => {
  const [dirty, setDirty] = useState(false);
  let selectedOption: RiskModelTreatmentStrategyEnum;

  useEffect(() => {
    {
      if (
        risk.treatmentExplanation ||
        risk.vendorActions.length > 0 ||
        (risk.treatmentStrategy === RiskModelTreatmentStrategyEnum.Modify &&
          risk.controls.length > 0)
      ) {
        setDirty(true);
      }
    }
  }, [risk]);

  const switchStrategyCommand: CommandConfirmation = {
    title: 'Change risk treatment strategy',
    description:
      'Are you sure you wish to change the risk management strategy? By continuing the current answers for this section ' +
      'will be lost. To return select CANCEL or to continue select CONFIRM.',
    buttonVariant: AdoptechButtonVariant.Primary,
    onConfirm: () => {
      update(m => ({
        ...m,
        treatmentStrategy: selectedOption,
      }));
      setDirty(false);
    },
  };
  return (
    <>
      <div className="riskRegisterEditor--title">
        Select the risk treatment strategy
      </div>
      <div className={baseCss + '--strategyRow'}>
        {treatmentStrategyOptions.map(option => {
          return (
            <div className="adoptechRadioButton" key={option.value}>
              <input
                checked={option.value === risk.treatmentStrategy}
                id={option.value}
                onChange={() => {
                  if (dirty) {
                    selectedOption =
                      option.value as RiskModelTreatmentStrategyEnum;
                    command(switchStrategyCommand);
                  } else {
                    update(m => ({
                      ...m,
                      treatmentStrategy:
                        option.value as RiskModelTreatmentStrategyEnum,
                    }));
                  }
                }}
                type="radio"
                value={option.value}
              />
              <label htmlFor={option.value}>
                <Interweave content={option.label} />
              </label>
            </div>
          );
        })}
      </div>
      <hr />
      <TreatmentControls risk={risk} update={update} command={command} />
      <TreatmentActions risk={risk} update={update} command={command} />
    </>
  );
};
