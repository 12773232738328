import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTaskDetails,
  patchComplianceTask,
  postComplianceTask,
} from '../../../../../store/compliance/complianceThunks';
import { TaskExtended } from '../../../../../swagger';
import { ApplicationState } from '../../../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../../components/AdoptechButton/AdoptechButton';
import { ComplianceEditTaskForm } from '../ComplianceEditTaskForm/ComplianceEditTaskForm';
import './ComplianceEditTaskDrawer.scss';
import classNames from 'classnames';
import { DrawerType } from '../../../../../components/compliance/Types/complianceTypes';
import {
  cleanupTaskDetails,
  setEditTaskMode,
  setSelectedTaskId,
  setTempTask,
  updateTaskInTheTree,
} from '../../../../../store/compliance/complianceSlice';
import { EditTaskMode } from '../../../../../store/compliance/complianceState';
import AdoptechHS from '../../../../../components/AdoptechHS/AdoptechHS';
import { useUpdateHiddenControlDrawer } from '../EditTestDrawer/EditTestForm/EditTestFormLastResultsSection/EditTestFormLastResultsList/EditTestFormLastResultsList';

interface ComplianceEditTaskDrawerProps {
  show: boolean;
  onClose(): void;
}

export const ComplianceEditTaskDrawer: React.FC<
  ComplianceEditTaskDrawerProps
> = ({ show, onClose = () => {} }) => {
  const [formTouched, setFormTouched] = useState<boolean>(false);

  const {
    selectedTaskId,
    selectedControlId,
    tempTask,
    showControlDrawer,
    isPatchingTask,
    isPostingTask,
    patchControlCheckAssertionsStatus,
    editControlFormModel,
    isFetchingTaskDetails,
    drawerOnTop: activeDrawer,
  } = useSelector((state: ApplicationState) => state.compliance);

  const isSubmitting =
    isPostingTask ||
    isPatchingTask ||
    isFetchingTaskDetails ||
    patchControlCheckAssertionsStatus === 'loading';

  const formValid = !!tempTask?.name;
  const dispatch = useDispatch();
  const baseCss = 'complianceEditTaskDrawer';
  const updateHiddenControlDrawer = useUpdateHiddenControlDrawer();
  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  useEffect(() => {
    return () => {
      dispatch(setEditTaskMode(EditTaskMode.Normal));
    };
  }, []);

  const [originalTask, setOriginalTask] = useState<TaskExtended>(null);
  useEffect(() => {
    if (selectedTaskId) {
      dispatch(
        fetchTaskDetails(selectedTaskId, response => setOriginalTask(response))
      );
    } else {
      dispatch(setTempTask(undefined));
    }
    setFormTouched(false);
  }, [selectedTaskId]);

  const submit = async () => {
    if (tempTask?.id) {
      type PatchField = 'name' | 'description' | 'assigneeId';
      type PatchTaskPayload = {
        [key in PatchField]?: string;
      };
      const patchTaskPayload: PatchTaskPayload = {};

      // patch only changed fields
      ['name', 'description', 'assigneeId'].forEach((field: PatchField) => {
        if (field === 'assigneeId') {
          if (originalTask.assignee?.id !== tempTask.assignee?.id) {
            patchTaskPayload.assigneeId = tempTask.assignee?.id;
          }
          return;
        }

        if (originalTask[field] !== tempTask[field]) {
          patchTaskPayload[field] = tempTask[field];
        }
      });

      const onSuccess = (response: TaskExtended) => {
        dispatch(updateTaskInTheTree(response));
        updateHiddenControlDrawer(response.status);
      };
      await dispatch(
        patchComplianceTask({
          taskId: tempTask.id,
          task: patchTaskPayload,
          onSuccess: response => {
            onSuccess(response);
          },
        })
      );
    } else {
      const newTaskPayload = {
        name: tempTask.name,
        description: tempTask.description,
        assigneeId: tempTask?.assignee?.id,
      };
      dispatch(
        postComplianceTask({
          vendorId,
          task: newTaskPayload,
        })
      );
    }
    dispatch(cleanupTaskDetails());
    onClose();
  };

  const handleClose = () => {
    const handleNoSave = () => dispatch(setSelectedTaskId(undefined));

    dispatch(cleanupTaskDetails());
    onClose();
    if (!formTouched) handleNoSave();
  };

  const drawerBackgroundRef = useRef<HTMLDivElement>();

  return (
    <AdoptechHS
      noFormPadding
      backButton={showControlDrawer}
      onBackClick={handleClose}
      title={
        tempTask?.name ||
        (isFetchingTaskDetails ? 'Edit check' : 'Create new check')
      }
      badges={tempTask?.name ? [{ title: 'Check' }] : []}
      ref={drawerBackgroundRef}
      showConfirmationWarning={formTouched}
      onClose={handleClose}
      extraClass={classNames({ onTop: activeDrawer === DrawerType.Task }, [
        baseCss,
      ])}
      show={show}
      footer={
        <>
          <AdoptechButton
            onClick={handleClose}
            variant={AdoptechButtonVariant.White}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            disabled={!formTouched || !formValid}
            onClick={submit}
            variant={AdoptechButtonVariant.Primary}
            busy={isSubmitting}
          >
            {tempTask ? 'DONE' : 'ADD'}
          </AdoptechButton>
        </>
      }
    >
      <ComplianceEditTaskForm
        onChange={(editedTask: TaskExtended) => {
          dispatch(setTempTask(editedTask));
          setFormTouched(true);
        }}
      />
    </AdoptechHS>
  );
};
