import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers } from '../../selectors/selectUsers';
import {
  addManagersToGenerate,
  hideGenerateChecklistManagersModal,
} from '../../store/checklists/checklistsSlice';
import { generateChecklistFromTemplate } from '../../store/checklists/checklistsThunks';
import { ApplicationState } from '../../types/applicationState';
import { SelectionOption } from '../../types/selectionOption';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechModal from '../AdoptechModal/AdoptechModal';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechTextArea } from '../AdoptechTextArea/AdoptechTextArea';
import './GenerateChecklistManagersModal.scss';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';

export const GenerateChecklistManagersModal: React.FC = () => {
  const dispatch = useDispatch();

  const isGeneratingChecklistFromTemplate = useSelector(
    (state: ApplicationState) =>
      state.checklists.isGeneratingChecklistFromTemplate
  );
  const isShowingGenerateChecklistManagersModal = useSelector(
    (state: ApplicationState) =>
      state.checklists.isShowingGenerateChecklistManagersModal
  );
  const currentVendor = useSelector(selectCurrentVendor);

  const [selectedUser, setSelectedUser] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const users = useSelector(selectUsers);
  const options = useMemo(
    () => users.map(u => ({ value: u.id, label: u.email })),
    [users]
  );

  useEffect(() => {
    setMessage('');
  }, [isShowingGenerateChecklistManagersModal]);

  const handleSelect = (option: SelectionOption) => {
    setSelectedUser(option.value);
  };

  const handleCancel = () => {
    dispatch(hideGenerateChecklistManagersModal());
  };

  const handleDone = () => {
    dispatch(addManagersToGenerate({ users: [selectedUser], message }));
    dispatch(generateChecklistFromTemplate(currentVendor?.id));
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setMessage(value);
  };

  return (
    <React.Fragment>
      <AdoptechModal
        className="generateChecklistManagersModal"
        onHide={handleCancel}
        show={isShowingGenerateChecklistManagersModal}
      >
        <Modal.Header>
          <div className="generateChecklistManagersModal--header">
            <div>Generate checklist</div>
            <div className="generateChecklistManagersModal--subHeader">
              Who is the owner of this checklist?
            </div>
            <div className="generateChecklistManagersModal--guidance">
              Guidance: This person will be responsible for ensuring this
              checklist is completed.
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="generateChecklistManagersModal--staffMembers">
            <AdoptechReactSelect
              id="generateChecklistManagersModal--userSelect"
              onChange={handleSelect}
              options={options}
              showUserAvatar
            />
          </div>
          <div className="generateChecklistManagersModal--message">
            <AdoptechTextArea
              id="message"
              label="Add optional message"
              onChange={handleMessageChange}
              value={message}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AdoptechButton onClick={handleCancel}>Cancel</AdoptechButton>

          <AdoptechButton
            disabled={!selectedUser}
            onClick={handleDone}
            variant={AdoptechButtonVariant.Primary}
            busy={isGeneratingChecklistFromTemplate}
          >
            Generate
          </AdoptechButton>
        </Modal.Footer>
      </AdoptechModal>
    </React.Fragment>
  );
};
