import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  Company,
  CompanyIdentifierModel,
  CompanyIdentifierType,
  DashboardSummary,
  EscrowAccount,
  Image,
  Office,
  SharedDocumentList,
  SharesList,
  ShareSuggestionList,
  Vendor,
  VendorDetails,
  VendorDocument,
  VendorDocumentApproval,
  VendorDocumentAttestation,
  VendorDocumentDownload,
  VendorDocumentExtended,
  VendorProductIndex,
  VendorSupplierCategory,
  VendorSupplierExtended,
  VendorSupplierModel,
  VendorTable,
  VendorTeamModel,
  VendorUser,
} from '../../swagger';
import { InviteUserDetails } from '../../types/inviteUserDetails';
import { ShareableObject, VendorsState } from './vendorsState';
import { VendorDocumentApprovalExtended } from '../../types/VendorDocumentApprovalExtended';
import { VendorDocumentAttestationExtended } from '../../types/VendorDocumentAttestationExtended';

const vendorsSlice = createSlice({
  initialState: {
    // TODO: move to companyProfileSlice
    companyDetailsState: {
      isAddOfficeAddressModalShowing: false,
      isDeleteCertificateModalShowing: false,
      showAddedToast: false,
      showEditedToast: false,
      showDeletedToast: false,
      isEditMediaModalShowing: false,
      showEditedMediaToast: false,
      showEditedCompanyProfileToast: false,
      isLogoUploadSuccessToastShowing: false,
      identifierTypes: null,
      isDeleteCompanyIdentifierModalShowing: false,
      isDeleteCompanyIdentifierToastShowing: false,
    },
    documentState: {
      isArchivingVendorDocument: false,
      isAttestingVendorDocument: false,
      isCompleteDocumentAuditModalOpen: false,
      isDeleteVendorDocumentModalShowing: false,
      isDeletingVendorDocument: false,
      isFetchingVendorDocument: false,
      isFetchingVendorDocumentApprovals: false,
      isFetchingVendorDocumentAttestations: false,
      isFetchingVendorDocuments: false,
      isFetchingVendorPolicyDocuments: false,
      isFileUploadedToastShowing: false,
      isDocumentUploadModalShowing: false,
      isShowingSuccessfulVendorDocumentAttestationToast: false,
      isShowingSuccessfulVendorDocumentApprovalToast: false,
      isShowingSuccessfulVendorDocumentRejectionToast: false,
      isShowingSuccessfulVendorDocumentUpdatedToast: false,
      isUnarchivingVendorDocument: false,
      isUploadingVendorDocument: false,
      isVendorDocumentDeletedToastShowing: false,
      policyDocuments: [],
      vendorDocuments: [],
      vendorDocumentDownload: null,
      vendorDocumentApprovals: [],
      vendorDocumentAttestations: [],
    },
    inviteState: {
      isAddUserModalShowing: false,
      firstName: '',
      lastName: '',
      emailAddress: '',
      isSendingInvitation: false,
      resend: false,
    },
    isFetchingEscrowAccounts: false,
    isFetchingVendorDetails: false,
    isFetchingVendorTable: {},
    isFetchingVendors: false,
    isFetchingDashboard: false,
    isManageBillingModalShowing: false,
    isManageSubscriptionModalShowing: false,
    isWarningSubscriptionLimitModalShowing: false,
    isUserDeletedModalShowing: false,
    teamsState: {
      isAddTeamModalShowing: false,
      isAddingVendorTeam: false,
      isDeleteTeamModalShowing: false,
      isDeletingVendorTeam: false,
      isDuplicateTeamName: false,
      isFetchingVendorTeams: false,
      isTeamDeletedToastShowing: false,
      vendorTeams: [],
    },
    vendors: [],
    users: [],
    vendorDetails: [],
    companyIdentifiers: {},
    vendorTableCache: {},
    currentVendor: null,
    vendorSupplierForm: null,
    orgChart: { filename: '', urls: { full: '', thumb: '' } },
    isFetchingOrgChart: false,
    escrowAccounts: [],
    vendorSuppliers: [],
    vendorSupplierCategories: [],
    legislationIdToDelete: '',
    isCreatingVendorSupplier: false,
    isPatchingVendorSupplier: false,
  } as unknown as VendorsState,
  name: 'vendorsSlice',
  reducers: {
    fetchEscrowAccountsRequest: state => {
      state.isFetchingEscrowAccounts = true;
      state.escrowAccounts = [];
    },
    fetchEscrowAccountsFailure: state => {
      state.isFetchingEscrowAccounts = false;
      state.escrowAccounts = [];
    },
    fetchEscrowAccountsSuccess: (
      state,
      action: PayloadAction<Array<EscrowAccount>>
    ) => {
      state.isFetchingEscrowAccounts = false;
      state.escrowAccounts = action.payload;
    },
    upsertVendorTeamsRequest: state => {
      state.teamsState.upsertVendorTeamsError = undefined;
      state.teamsState.isUpsertingVendorTeams = true;
    },
    upsertVendorTeamsSuccess: state => {
      state.teamsState.isUpsertingVendorTeams = false;
    },
    fetchVendorSupplierCategoriesSuccess: (
      state,
      action: PayloadAction<VendorSupplierCategory[]>
    ) => {
      state.vendorSupplierCategories = action.payload;
    },
    upsertVendorTeamsFailure: (state, action: PayloadAction<string>) => {
      state.teamsState.upsertVendorTeamsError = action.payload;
      state.teamsState.isUpsertingVendorTeams = false;
    },
    fetchVendorDocumentsRequest: state => {
      state.documentState.fetchVendorDocumentsError = undefined;
      state.documentState.isFetchingVendorDocuments = true;
    },
    fetchVendorDocumentsSuccess: (
      state,
      action: PayloadAction<VendorDocument[]>
    ) => {
      state.documentState.isFetchingVendorDocuments = false;
      state.documentState.vendorDocuments = action.payload;
    },
    fetchVendorDocumentsFailure: (state, action: PayloadAction<string>) => {
      state.documentState.fetchVendorDocumentsError = action.payload;
      state.documentState.isFetchingVendorDocuments = false;
    },
    fetchVendorTableRequest: (state, action: PayloadAction<string>) => {
      state.fetchingVendorTableError = null;
      state.isFetchingVendorTable[action.payload] = true;
    },
    fetchVendorTableSuccess: (
      state,
      action: PayloadAction<{ type: string; vendorTable: VendorTable }>
    ) => {
      state.isFetchingVendorTable[action.payload.type] = false;
      state.vendorTableCache[action.payload.type] = action.payload.vendorTable;
    },
    fetchVendorTableFailure: (
      state,
      action: PayloadAction<{ type: string; error: string }>
    ) => {
      state.fetchingVendorTableError = action.payload.error;
      state.isFetchingVendorTable[action.payload.type] = false;
    },
    fetchVendorTeamsRequest: state => {
      state.teamsState.fetchVendorTeamsError = undefined;
      state.teamsState.isFetchingVendorTeams = true;
    },
    fetchVendorTeamsSuccess: (
      state,
      action: PayloadAction<VendorTeamModel[]>
    ) => {
      state.teamsState.isFetchingVendorTeams = false;
      state.teamsState.vendorTeams = action.payload;
    },
    fetchVendorTeamsFailure: (state, action: PayloadAction<string>) => {
      state.teamsState.fetchVendorTeamsError = action.payload;
      state.teamsState.isFetchingVendorTeams = false;
    },
    fetchVendorDetailsRequest: state => {
      state.fetchingVendorDetailsError = undefined;
      state.isFetchingVendorDetails = true;
    },
    fetchVendorDetailsSuccess: (
      state,
      action: PayloadAction<VendorDetails>
    ) => {
      state.isFetchingVendorDetails = false;
      const index = state.vendorDetails.findIndex(
        vd => vd.id === action.payload.id
      );

      if (index !== -1) {
        state.vendorDetails[index] = action.payload;
      } else {
        state.vendorDetails.push(action.payload);
      }
    },
    fetchVendorDetailsFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingVendorDetails = false;
      state.fetchingVendorDetailsError = action.payload;
    },
    fetchVendorsRequest: state => {
      state.fetchingVendorsError = undefined;
      state.isFetchingVendors = true;
    },
    fetchVendorsSuccess: (state, action: PayloadAction<Vendor[]>) => {
      state.isFetchingVendors = false;
      state.vendors = action.payload;
      if (state.currentVendor) {
        state.currentVendor = state.vendors.find(
          (vendor: Vendor) => vendor.id === state.currentVendor.id
        );
      }
    },
    setCurrentVendor: (state, action: PayloadAction<Vendor>) => {
      localStorage.setItem('currentVendorId', action.payload?.id);
      state.currentVendor = action.payload;
    },
    fetchVendorsFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingVendors = false;
      state.fetchingVendorsError = action.payload;
    },
    fetchVendorUserRequest: state => {
      state.fetchingVendorUserError = undefined;
      state.isFetchingVendorUser = true;
    },
    fetchVendorUserSuccess: (state, action: PayloadAction<VendorUser>) => {
      state.isFetchingVendorUser = false;

      const index = state.users.findIndex(u => u.id === action.payload.id);

      if (index !== -1) {
        state.users[index] = action.payload;
      } else {
        state.users.push(action.payload);
      }
    },

    updateVendorUserRoles: (state, action: PayloadAction<VendorUser>) => {
      const newFields = { roles: action.payload.roles };
      state.users = state.users.map(user => {
        return action.payload.id === user.id ? { ...user, ...newFields } : user;
      });
    },
    fetchVendorUserFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingVendorUser = false;
      state.fetchingVendorUserError = action.payload;
    },
    fetchVendorUsersRequest: state => {
      state.fetchingVendorUsersError = undefined;
      state.isFetchingVendorUsers = true;
    },
    fetchVendorUsersSuccess: (state, action: PayloadAction<VendorUser[]>) => {
      state.hasFetchedVendorUsers = true;
      state.isFetchingVendorUsers = false;
      state.users = action.payload;
    },
    fetchVendorUsersFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingVendorUsers = false;
      state.fetchingVendorUsersError = action.payload;
    },
    fetchOrgChartRequest: (state, action: PayloadAction<boolean>) => {
      state.isFetchingOrgChart = action.payload;
    },
    fetchOrgChart: (state, action: PayloadAction<Image>) => {
      state.orgChart = action.payload;
    },
    fetchOrgChartFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingOrgChart = false;
      state.fetchOrgChartError = action.payload;
    },
    showAddUserModal: (state, action: PayloadAction<boolean>) => {
      state.inviteState.isAddUserModalShowing = true;
    },
    cancelAddUserModal: state => {
      state.inviteState.isAddUserModalShowing = false;
    },
    hideAddUserModal: state => {
      state.inviteState.isAddUserModalShowing = false;
    },
    showConfirmSendInvitationModal: (state, action: PayloadAction<boolean>) => {
      state.inviteState.isConfirmSendInvitationModalShowing = true;
      state.inviteState.resend = action.payload;
    },
    hideConfirmSendInvitationModal: state => {
      state.inviteState.isConfirmSendInvitationModalShowing = false;
    },
    showConfirmResendInvitationModal: state => {
      state.inviteState.isConfirmResendInvitationModalShowing = true;
    },
    hideConfirmResendInvitationModal: state => {
      state.inviteState.isConfirmResendInvitationModalShowing = false;
    },
    updateInviteUserDetails: (
      state,
      action: PayloadAction<InviteUserDetails>
    ) => {
      const { firstName, lastName, emailAddress, message } = action.payload;
      state.inviteState.firstName = firstName;
      state.inviteState.lastName = lastName;
      state.inviteState.emailAddress = emailAddress;
      state.inviteState.message = message;
    },
    sendInvitationRequest: state => {
      state.inviteState.sendingInvitationError = undefined;
      state.inviteState.isSendingInvitation = true;
    },
    sendInvitationSuccess: state => {
      state.inviteState.isInvitationSentModalShowing = true;
      state.inviteState.isSendingInvitation = false;
    },
    sendInvitationFailure: (state, action: PayloadAction<string>) => {
      state.inviteState.isSendingInvitation = false;
      state.inviteState.sendingInvitationError = action.payload;
    },
    hideInvitationSentModal: state => {
      state.inviteState.isInvitationSentModalShowing = false;
    },
    userRolesChangeFailure: (state, action: PayloadAction<string>) => {},
    userDeleteSuccess: state => {
      state.isUserDeletedModalShowing = true;
    },
    hideUserDeletedModal: state => {
      state.isUserDeletedModalShowing = false;
    },
    userCreateFailure: (state, action: PayloadAction<string>) => {
      state.userCreateError = action.payload;
    },
    userUpdateFailure: (state, action: PayloadAction<string>) => {
      state.userUpdateError = action.payload;
    },
    userDeleteFailure: (state, action: PayloadAction<string>) => {
      state.userDeleteError = action.payload;
    },
    showAddTeamModal: (state, action: PayloadAction<boolean>) => {
      state.teamsState.isAddTeamModalShowing = true;
      state.teamsState.isDuplicateTeamName = false;
      state.teamsState.addVendorTeamError = undefined;
    },
    cancelAddTeamModal: state => {
      state.teamsState.isAddTeamModalShowing = false;
    },
    hideAddTeamModal: state => {
      state.teamsState.isAddTeamModalShowing = false;
    },
    showDeleteTeamModal: (state, action: PayloadAction<string>) => {
      state.teamsState.isDeleteTeamModalShowing = true;
      state.teamsState.vendorTeamIdBeingDeleted = action.payload;
    },
    hideDeleteTeamModal: state => {
      state.teamsState.isDeleteTeamModalShowing = false;
      state.teamsState.vendorTeamIdBeingDeleted = undefined;
    },
    showTeamDeletedToast: state => {
      state.teamsState.isTeamDeletedToastShowing = true;
    },
    hideTeamDeletedToast: state => {
      state.teamsState.isTeamDeletedToastShowing = false;
    },
    uploadVendorDocumentRequest: state => {
      state.documentState.uploadVendorDocumentError = undefined;
      state.documentState.isUploadingVendorDocument = true;
    },
    uploadVendorDocumentSuccess: state => {
      state.documentState.isUploadingVendorDocument = false;
    },
    uploadVendorDocumentFailure: (state, action: PayloadAction<string>) => {
      state.documentState.uploadVendorDocumentError = action.payload;
      state.documentState.isUploadingVendorDocument = false;
    },
    hideFileUploadedToast: state => {
      state.documentState.isFileUploadedToastShowing = false;
    },
    showFileUploadedToast: state => {
      state.documentState.isFileUploadedToastShowing = true;
    },
    hideDocumentUploadModal: state => {
      state.documentState.isDocumentUploadModalShowing = false;
    },
    showDocumentUploadModal: state => {
      state.documentState.isDocumentUploadModalShowing = true;
    },
    deleteVendorDocumentRequest: state => {
      state.documentState.deleteVendorDocumentError = undefined;
      state.documentState.isDeletingVendorDocument = true;
    },
    deleteVendorDocumentSuccess: (
      state,
      action: PayloadAction<VendorDocument['id']>
    ) => {
      const docs = state.documentState.vendorDocuments;
      state.documentState.vendorDocuments = docs.filter(
        doc => doc.id !== action.payload
      );
      state.documentState.isDeletingVendorDocument = false;
    },
    deleteVendorDocumentFailure: (state, action: PayloadAction<string>) => {
      state.documentState.deleteVendorDocumentError = action.payload;
      state.documentState.isDeletingVendorDocument = false;
    },
    showDeleteVendorDocumentModal: (state, action: PayloadAction<string>) => {
      state.documentState.isDeleteVendorDocumentModalShowing = true;
      state.documentState.vendorDocumentIdBeingDeleted = action.payload;
    },
    hideDeleteVendorDocumentModal: state => {
      state.documentState.isDeleteVendorDocumentModalShowing = false;
      state.documentState.vendorDocumentIdBeingDeleted = undefined;
    },
    showVendorDocumentDeletedToast: state => {
      state.documentState.isVendorDocumentDeletedToastShowing = true;
    },
    hideVendorDocumentDeletedToast: state => {
      state.documentState.isVendorDocumentDeletedToastShowing = false;
    },

    attestVendorDocumentRequest: state => {
      state.documentState.isAttestingVendorDocument = true;
    },
    attestVendorDocumentSuccess: state => {
      state.documentState.isAttestingVendorDocument = false;
    },
    attestVendorDocumentFailure: (state, action: PayloadAction<string>) => {
      state.documentState.attestVendorDocumentError = action.payload;
      state.documentState.isAttestingVendorDocument = false;
    },
    approveVendorDocumentRequest: state => {
      state.documentState.isApprovingVendorDocument = true;
    },
    approveVendorDocumentSuccess: state => {
      state.documentState.isApprovingVendorDocument = false;
    },
    approveVendorDocumentFailure: (state, action: PayloadAction<string>) => {
      state.documentState.approveVendorDocumentError = action.payload;
      state.documentState.isApprovingVendorDocument = false;
    },
    updateOfficeAddressRequest: state => {
      state.companyDetailsState.isUpdatingOfficeAddress = true;
    },
    updateOfficeAddressSuccess: (state, action: PayloadAction<Company>) => {
      state.companyDetailsState.isUpdatingOfficeAddress = false;
      state.companyOffices = action.payload.offices;
    },
    updateOfficeAddressFailure: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.updateOfficeAddressError = action.payload;
      state.companyDetailsState.isUpdatingOfficeAddress = false;
    },
    deleteOfficeAddressRequest: state => {
      state.companyDetailsState.isDeletingOfficeAddress = true;
    },
    deleteOfficeAddressSuccess: (
      state,
      action: PayloadAction<Office['id']>
    ) => {
      state.companyDetailsState.isDeletingOfficeAddress = false;
      const offices = state.companyOffices;
      if (!offices) return;
      state.companyOffices = offices.filter(
        office => office.id !== action.payload
      );
    },
    deleteOfficeAddressFailure: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.deleteOfficeAddressError = action.payload;
      state.companyDetailsState.isDeletingOfficeAddress = false;
    },
    showSuccessfulVendorDocumentAttestationToast: state => {
      state.documentState.isShowingSuccessfulVendorDocumentAttestationToast =
        true;
    },
    hideSuccessfulVendorDocumentAttestationToast: state => {
      state.documentState.isShowingSuccessfulVendorDocumentAttestationToast =
        false;
    },
    showSuccessfulVendorDocumentApprovalToast: state => {
      state.documentState.isShowingSuccessfulVendorDocumentApprovalToast = true;
    },
    hideSuccessfulVendorDocumentApprovalToast: state => {
      state.documentState.isShowingSuccessfulVendorDocumentApprovalToast =
        false;
    },
    showSuccessfulVendorDocumentRejectionToast: state => {
      state.documentState.isShowingSuccessfulVendorDocumentRejectionToast =
        true;
    },
    hideSuccessfulVendorDocumentRejectionToast: state => {
      state.documentState.isShowingSuccessfulVendorDocumentRejectionToast =
        false;
    },
    showSuccessfulVendorDocumentUpdatedToast: state => {
      state.documentState.isShowingSuccessfulVendorDocumentUpdatedToast = true;
    },
    hideSuccessfulVendorDocumentUpdatedToast: state => {
      state.documentState.isShowingSuccessfulVendorDocumentUpdatedToast = false;
    },
    showAddCompanyOfficeAddressModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.companyDetailsState.isAddOfficeAddressModalShowing = true;
    },
    cancelAddCompanyOfficeAddressModal: state => {
      state.companyDetailsState.isAddOfficeAddressModalShowing = false;
    },
    hideAddCompanyOfficeAddressModal: state => {
      state.companyDetailsState.isAddOfficeAddressModalShowing = false;
    },
    setCompanyOfficeBeingEdited: (state, action: PayloadAction<Office>) => {
      state.companyDetailsState.officeBeingEdited = action.payload;
    },
    showDeleteCompanyOfficeAddressModal: (
      state,
      action: PayloadAction<Office>
    ) => {
      state.companyDetailsState.isDeleteOfficeAddressModalShowing = true;
      state.companyDetailsState.officeBeingEdited = action.payload;
    },
    hideDeleteCompanyOfficeAddressModal: state => {
      state.companyDetailsState.isDeleteOfficeAddressModalShowing = false;
    },
    showDeleteCertificateModal: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.isDeleteCertificateModalShowing = true;
      state.companyDetailsState.certificateIdBeingDeleted = action.payload;
    },
    hideDeleteCertificateModal: state => {
      state.companyDetailsState.isDeleteCertificateModalShowing = false;
      state.companyDetailsState.certificateIdBeingDeleted = undefined;
    },
    showOfficeAddressDeletedToast: state => {
      state.companyDetailsState.showDeletedToast = true;
    },
    hideOfficeAddressDeletedToast: state => {
      state.companyDetailsState.showDeletedToast = false;
    },
    showOfficeAddressEditedToast: state => {
      state.companyDetailsState.showEditedToast = true;
    },
    hideOfficeAddressEditedToast: state => {
      state.companyDetailsState.showEditedToast = false;
    },
    showOfficeAddressAddedToast: state => {
      state.companyDetailsState.showAddedToast = true;
    },
    hideOfficeAddressAddedToast: state => {
      state.companyDetailsState.showAddedToast = false;
    },
    showEditMediaModal: state => {
      state.companyDetailsState.isEditMediaModalShowing = true;
    },
    hideEditMediaModal: state => {
      state.companyDetailsState.isEditMediaModalShowing = false;
    },
    showEditMediaToast: state => {
      state.companyDetailsState.showEditedMediaToast = true;
    },
    hideEditMediaToast: state => {
      state.companyDetailsState.showEditedMediaToast = false;
    },
    showEditCompanyProfileToast: state => {
      state.companyDetailsState.showEditedCompanyProfileToast = true;
    },
    hideEditCompanyProfileToast: state => {
      state.companyDetailsState.showEditedCompanyProfileToast = false;
    },
    updateVendorDetailsRequest: state => {
      state.companyDetailsState.isUpdatingVendorDetails = true;
    },
    updateVendorDetailsSuccess: state => {
      state.companyDetailsState.isUpdatingVendorDetails = false;
    },
    updateVendorDetailsFailure: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.updateVendorDetailsError = action.payload;
      state.companyDetailsState.isUpdatingVendorDetails = false;
    },
    updateOrgChartRequest: (state, action: PayloadAction<boolean>) => {
      state.isUpdatingOrgChart = action.payload;
    },
    updateOrgChartFailure: (state, action: PayloadAction<string>) => {
      state.isUpdatingOrgChart = false;
      state.updateOrgChartError = action.payload;
    },
    showCompleteDocumentAuditModal: (
      state,
      action: PayloadAction<VendorDocumentExtended>
    ) => {
      state.documentState.isCompleteDocumentAuditModalOpen = true;
      state.documentState.completeDocument = action.payload;
    },
    hideCompleteDocumentAuditModal: state => {
      state.documentState.isCompleteDocumentAuditModalOpen = false;
      state.documentState.completeDocument = null;
    },
    showManageBillingModal: state => {
      state.isManageBillingModalShowing = true;
    },
    hideManageBillingModal: state => {
      state.isManageBillingModalShowing = false;
    },
    fetchVendorPolicyDocumentsFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.documentState.fetchVendorPolicyDocumentsError = action.payload;
      state.documentState.isFetchingVendorPolicyDocuments = false;
    },
    fetchVendorPolicyDocumentsRequest: (
      state,
      action: PayloadAction<boolean>
    ) => {
      const showLoading = action.payload;
      state.documentState.fetchVendorPolicyDocumentsError = undefined;
      state.documentState.isFetchingVendorPolicyDocuments = showLoading;
    },
    fetchVendorPolicyDocumentsSuccess: (
      state,
      action: PayloadAction<VendorDocumentExtended[]>
    ) => {
      state.documentState.isFetchingVendorPolicyDocuments = false;
      state.documentState.policyDocuments = action.payload;
    },
    fetchLogoRequest: state => {
      state.companyDetailsState.logoFetchingError = undefined;
    },
    fetchLogoSuccess: (state, action: PayloadAction<Image>) => {
      state.companyDetailsState.logo = action.payload;
    },
    fetchLogoError: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.logoFetchingError = action.payload;
    },
    showUploadLogoModal: state => {
      state.companyDetailsState.isUploadLogoModalShowing = true;
    },
    setCompanyColour: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.colour = action.payload;
    },
    hideUploadLogoModal: state => {
      state.companyDetailsState.isUploadLogoModalShowing = false;
    },
    uploadLogoRequest: state => {
      state.companyDetailsState.isLogoUploading = true;
      state.companyDetailsState.logoUploadingError = undefined;
    },
    uploadLogoSuccess: (state, action: PayloadAction<Image>) => {
      state.companyDetailsState.isLogoUploading = false;
      state.companyDetailsState.logo = action.payload;
    },
    uploadLogoFailure: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.isLogoUploading = false;
      state.companyDetailsState.logoUploadingError = action.payload;
    },
    showLogoUploadSuccessToast: state => {
      state.companyDetailsState.isLogoUploadSuccessToastShowing = true;
    },
    hideLogoUploadSuccessToast: state => {
      state.companyDetailsState.isLogoUploadSuccessToastShowing = false;
    },
    fetchDashboardRequest: state => {
      state.dashboard = null;
      state.isFetchingDashboard = true;
    },
    fetchDashboardSuccess: (state, action: PayloadAction<DashboardSummary>) => {
      state.dashboard = action.payload;
      state.isFetchingDashboard = false;
    },
    clearDashboard: state => {
      state.dashboard = null;
    },
    clearVendorSupplierForm: state => {
      state.vendorSupplierForm = null;
    },
    initVendorSupplierForm: (
      state,
      action: PayloadAction<VendorSupplierModel | VendorSupplierExtended>
    ) => {
      state.vendorSupplierForm = {
        ...action.payload,
        id: null,
        vendorSupplierCategoryId: null,
        certifications: [] as string[],
      } as VendorSupplierExtended;
    },
    setVendorSupplierForm: (
      state,
      action: PayloadAction<VendorSupplierExtended>
    ) => {
      state.vendorSupplierForm = action.payload as VendorSupplierExtended;
    },
    fetchDashboardError: (state, action: PayloadAction<string>) => {
      state.isFetchingDashboard = false;
    },
    archiveVendorDocumentFailure: (state, action: PayloadAction<string>) => {
      state.documentState.archiveVendorDocumentError = action.payload;
      state.documentState.isArchivingVendorDocument = false;
    },
    archiveVendorDocumentRequest: (state, action: PayloadAction<string>) => {
      state.documentState.archiveVendorDocumentError = undefined;
      state.documentState.isArchivingVendorDocument = true;
    },
    archiveVendorDocumentSuccess: (
      state,
      action: PayloadAction<VendorDocument['id']>
    ) => {
      state.documentState.archivingVendorDocumentId = action.payload;

      const document = state.documentState.vendorDocuments.find(
        doc => doc.id === action.payload
      );
      document.archived = true;
      document.updatedAt = moment().toISOString();
      state.documentState.isArchivingVendorDocument = false;
    },
    unarchiveVendorDocumentFailure: (state, action: PayloadAction<string>) => {
      state.documentState.unarchiveVendorDocumentError = action.payload;
      state.documentState.archivingVendorDocumentId = null;
      state.documentState.isUnarchivingVendorDocument = false;
    },
    unarchiveVendorDocumentRequest: (state, action: PayloadAction<string>) => {
      state.documentState.unarchiveVendorDocumentError = undefined;
      state.documentState.isUnarchivingVendorDocument = true;
    },
    unarchiveVendorDocumentSuccess: (
      state,
      action: PayloadAction<VendorDocument['id']>
    ) => {
      state.documentState.isUnarchivingVendorDocument = false;
      const document = state.documentState.vendorDocuments.find(
        doc => doc.id === action.payload
      );
      document.archived = false;
      document.updatedAt = moment().toISOString();
      state.documentState.archivingVendorDocumentId = null;
    },
    fetchVendorDocumentsDownloadRequest: state => {},

    fetchVendorDocumentDownloadSuccess: (
      state,
      action: PayloadAction<VendorDocumentDownload>
    ) => {
      state.documentState.vendorDocumentDownload = action.payload;
    },
    cleanupVendorDocumentDownload: state => {
      state.documentState.vendorDocumentDownload = null;
    },
    fetchVendorDocumentsDownloadError: (
      state,
      action: PayloadAction<string>
    ) => {},
    hideWarningSubscriptionLimitModal: state => {
      state.isWarningSubscriptionLimitModalShowing = false;
    },

    showWarningSubscriptionLimitModal: state => {
      state.isWarningSubscriptionLimitModalShowing = true;
    },

    fetchIdentifierTypesRequest: state => {
      state.companyDetailsState.identifierTypesFetching = true;
    },
    fetchIdentifierTypesSuccess: (
      state,
      action: PayloadAction<CompanyIdentifierType[]>
    ) => {
      state.companyDetailsState.identifierTypesFetching = false;
      state.companyDetailsState.identifierTypes = action.payload;
    },
    fetchIdentifierTypesFailure: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.identifierTypesFailure = action.payload;
      state.companyDetailsState.identifierTypesFetching = false;
    },
    fetchCompanyIdentifiersRequest: state => {
      state.companyIdentifiersFetching = true;
    },
    fetchCompanyIdentifiersSuccess: (
      state,
      action: PayloadAction<CompanyIdentifierModel[]>
    ) => {
      state.companyIdentifiersFetching = false;
      const index = state.vendorDetails.findIndex(
        vd => vd.id === state.currentVendor.id
      );
      state.companyIdentifiers[state.vendorDetails[index].companies[0].id] =
        action.payload;
    },
    fetchCompanyIdentifiersFailure: (state, action: PayloadAction<string>) => {
      state.companyIdentifiersFailure = action.payload;
      state.companyIdentifiersFetching = false;
    },
    showDeleteCompanyIdentifierModal: (
      state,
      action: PayloadAction<CompanyIdentifierModel>
    ) => {
      state.companyDetailsState.isDeleteCompanyIdentifierModalShowing = true;
      state.companyDetailsState.identifierBeingDeleted = action.payload;
    },
    hideDeleteCompanyIdentifierModal: state => {
      state.companyDetailsState.isDeleteCompanyIdentifierModalShowing = false;
      state.companyDetailsState.identifierBeingDeleted = null;
    },
    showDeleteCompanyIdentifierToast: state => {
      state.companyDetailsState.isDeleteCompanyIdentifierToastShowing = true;
    },
    hideDeleteCompanyIdentifierToast: state => {
      state.companyDetailsState.isDeleteCompanyIdentifierToastShowing = false;
    },
    deleteCompanyIdentifierRequest: state => {
      state.companyDetailsState.isDeletingCompanyIdentifier = true;
    },
    deleteCompanyIdentifierSuccess: state => {
      state.companyDetailsState.isDeletingCompanyIdentifier = false;
    },
    deleteCompanyIdentifierFailure: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.isDeletingCompanyIdentifier = false;
      state.companyDetailsState.deleteCompanyIdentifierError = action.payload;
    },
    editCompanyIdentifierRequest: state => {
      state.companyDetailsState.isEditingCompanyIdentifier = true;
    },
    editCompanyIdentifierSuccess: state => {
      state.companyDetailsState.isEditingCompanyIdentifier = false;
    },
    editCompanyIdentifierFailure: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.isEditingCompanyIdentifier = false;
      state.companyDetailsState.editCompanyIdentifierError = action.payload;
    },
    createCompanyIdentifierRequest: state => {
      state.companyDetailsState.isCreatingCompanyIdentifier = true;
    },
    createCompanyIdentifierSuccess: state => {
      state.companyDetailsState.isCreatingCompanyIdentifier = false;
    },
    createCompanyIdentifierFailure: (state, action: PayloadAction<string>) => {
      state.companyDetailsState.isCreatingCompanyIdentifier = false;
      state.companyDetailsState.createCompanyIdentifierError = action.payload;
    },
    fetchSharesListRequest: state => {
      state.isFetchingSharesList = true;
    },
    fetchSharesListSuccess: (state, action: PayloadAction<SharesList>) => {
      state.isFetchingSharesList = false;
      state.sharesList = action.payload;
      state.sharesListError = null;
    },
    fetchSharesListFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingSharesList = false;
      state.sharesListError = action.payload;
    },
    fetchShareSuggestionsRequest: state => {
      state.isFetchingShareSuggestions = true;
    },
    fetchShareSuggestionsSuccess: (
      state,
      action: PayloadAction<ShareSuggestionList>
    ) => {
      state.isFetchingShareSuggestions = false;
      state.shareSuggestions = action.payload;
      state.sharesSuggestionsError = null;
    },
    fetchShareSuggestionsFailure: (state, action: PayloadAction<string>) => {
      state.isFetchingShareSuggestions = false;
      state.sharesSuggestionsError = action.payload;
    },
    shareRequest: state => {
      state.isRequestingShare = true;
    },
    shareSuccess: state => {
      state.isRequestingShare = false;
      state.shareError = null;
    },
    shareFailure: (state, action: PayloadAction<string>) => {
      state.isRequestingShare = false;
      state.shareError = action.payload;
    },
    showShareModal: (state, action: PayloadAction<ShareableObject>) => {
      state.isSharingModalShowing = true;
      state.shareableObject = action.payload;
    },
    hideShareModal: state => {
      state.isSharingModalShowing = false;
      state.shareableObject = null;
    },
    deleteSharePermissionsRequest: state => {
      state.isRequestingDeletePermissions = true;
    },
    deleteSharePermissionsSuccess: state => {
      state.isRequestingDeletePermissions = false;
    },
    deleteSharePermissionsFailure: (state, action: PayloadAction<string>) => {
      state.deletePermissionsError = action.payload;
      state.isRequestingDeletePermissions = false;
    },
    getVendorUserPermissionsRequest: state => {
      state.isRequestingVendorUserPermissions = true;
    },
    getVendorUserPermissionsSuccess: (
      state,
      action: PayloadAction<SharedDocumentList>
    ) => {
      state.isRequestingVendorUserPermissions = false;
      state.vendorUserPermissions = action.payload;
    },
    getVendorUserPermissionsFailure: (state, action: PayloadAction<string>) => {
      state.isRequestingVendorUserPermissions = false;
      state.vendorUserPermissionsErrors = action.payload;
    },
    getVendorProductsRequest: state => {
      state.isRequestingVendorProducts = true;
    },
    getVendorProductsSuccess: (
      state,
      action: PayloadAction<VendorProductIndex>
    ) => {
      state.isRequestingVendorProducts = false;
      state.vendorProducts = action.payload;
    },
    getVendorProductsFailure: (state, action: PayloadAction<string>) => {
      state.isRequestingVendorProducts = false;
      state.vendorProductsError = action.payload;
    },
    setFetchVendorDocumentByIdLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isRequestingVendorDocumentById = action.payload;
    },
    setCurrentVendorDocument: (
      state,
      action: PayloadAction<VendorDocumentExtended>
    ) => {
      state.currentVendorDocument = action.payload;
    },
    fetchVendorDocumentByIdFailure: (state, action: PayloadAction<string>) => {
      state.isRequestingVendorDocumentById = false;
      state.fetchVendorDocumentByIdFailure = action.payload;
    },
    patchVendorDocumentRequest: state => {
      state.documentState.isPatchingVendorDocument = true;
      state.documentState.patchVendorDocumentError = undefined;
    },
    patchVendorDocumentSuccess: (
      state,
      action: PayloadAction<VendorDocumentExtended>
    ) => {
      state.documentState.isPatchingVendorDocument = false;

      // policy documents
      const newPolicyDocuments = [...state.documentState.policyDocuments];
      const policyIndex = newPolicyDocuments.findIndex(
        document => document.id === action.payload.id
      );
      if (policyIndex === -1) {
        newPolicyDocuments.push(action.payload);
      } else {
        newPolicyDocuments.splice(policyIndex, 1, { ...action.payload });
      }
      state.documentState.policyDocuments = newPolicyDocuments;

      // vendor documents
      const newVendorDocuments = [...state.documentState.vendorDocuments];
      const vendorIndex = newVendorDocuments.findIndex(
        document => document.id === action.payload.id
      );
      if (vendorIndex === -1) {
        newVendorDocuments.push(action.payload);
      } else {
        newVendorDocuments.splice(vendorIndex, 1, { ...action.payload });
      }
      state.documentState.vendorDocuments = newVendorDocuments;
    },
    patchVendorDocumentFailure: (state, action: PayloadAction<string>) => {
      state.documentState.isPatchingVendorDocument = false;
      state.documentState.patchVendorDocumentError = action.payload;
    },
    toggleMyDocumentsFilter: state => {
      const { myPoliciesFilterEnabled } = state.documentState;
      state.documentState.myPoliciesFilterEnabled = !myPoliciesFilterEnabled;
    },
    setMyDocumentsFilter: (state, action: PayloadAction<boolean>) => {
      state.documentState.myPoliciesFilterEnabled = action.payload;
    },
    fetchVendorSuppliersRequest: state => {
      state.vendorSuppliers = [];
    },
    fetchVendorSuppliersSuccess: (
      state,
      action: PayloadAction<VendorSupplierModel[]>
    ) => {
      state.vendorSuppliers = action.payload;
    },
    fetchVendorSupplierSuccess: (
      state,
      action: PayloadAction<VendorSupplierExtended>
    ) => {
      state.vendorSupplierForm = action.payload;
    },
    fetchVendorSuppliersFailure: (state, action: PayloadAction<string>) => {},
    createVendorSupplierRequest: state => {
      state.isCreatingVendorSupplier = true;
    },
    createVendorSupplierSuccess: state => {
      state.isCreatingVendorSupplier = false;
    },
    patchVendorSupplierRequest: state => {
      state.isPatchingVendorSupplier = true;
    },
    patchVendorSupplierSuccess: state => {
      state.isPatchingVendorSupplier = false;
    },

    fetchCurrentCompanyRequest: state => {
      state.fetchCurrentCompanyRequest = true;
    },
    fetchCurrentCompanySuccess: (state, action: PayloadAction<Company>) => {
      state.fetchCurrentCompanyRequest = false;
      // Don't create a separate state currentCompany because it's already exist
      // in state.vendors.vendorDetails[0].companies[0]
      state.companyOffices = action.payload.offices;
    },
    fetchCurrentCompanyError: (state, action: PayloadAction<string>) => {
      state.fetchCurrentCompanyRequest = false;
    },
    updateCompanyProfileRequest: state => {
      state.updateCompanyProfileRequest = true;
    },
    setCompanyState: (state, action: PayloadAction<Company>) => {
      state.vendorDetails.map(vendor => {
        if (vendor.id !== state.currentVendor.id) return vendor;
        const company = vendor.companies[0];
        vendor.companies[0] = { ...company, ...action.payload };
      });
    },
    updateCompanyProfileSuccess: (state, _action: PayloadAction<Company>) => {
      state.updateCompanyProfileRequest = false;
    },
    updateCompanyProfileError: (state, action: PayloadAction<string>) => {
      state.updateCompanyProfileRequest = false;
    },
    fetchVendorDocumentApprovalsRequest: state => {
      state.documentState.isFetchingVendorDocumentApprovals = true;
      state.documentState.fetchVendorDocumentApprovalsError = undefined;
    },
    fetchVendorDocumentApprovalsSuccess: (
      state,
      action: PayloadAction<{
        vendorDocumentId: string;
        approvals: VendorDocumentApproval[];
      }>
    ) => {
      state.documentState.isFetchingVendorDocumentApprovals = false;
      state.documentState.vendorDocumentApprovals =
        state.documentState.vendorDocumentApprovals.filter(
          vda => vda.vendorDocumentId !== action.payload.vendorDocumentId
        );
      const vendorDocumentApprovalsExtended: VendorDocumentApprovalExtended[] =
        action.payload.approvals.map(vda => {
          return { vendorDocumentId: action.payload.vendorDocumentId, ...vda };
        });
      state.documentState.vendorDocumentApprovals.push(
        ...vendorDocumentApprovalsExtended
      );
    },
    fetchVendorDocumentApprovalsFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.documentState.isFetchingVendorDocumentApprovals = false;
      state.documentState.fetchVendorDocumentApprovalsError = action.payload;
    },
    fetchVendorDocumentAttestationsRequest: state => {
      state.documentState.isFetchingVendorDocumentAttestations = true;
      state.documentState.fetchVendorDocumentAttestationsError = undefined;
    },
    fetchVendorDocumentAttestationsSuccess: (
      state,
      action: PayloadAction<{
        vendorDocumentId: string;
        attestations: VendorDocumentAttestation[];
      }>
    ) => {
      state.documentState.isFetchingVendorDocumentAttestations = false;
      state.documentState.vendorDocumentAttestations =
        state.documentState.vendorDocumentAttestations.filter(
          vda => vda.vendorDocumentId !== action.payload.vendorDocumentId
        );
      const vendorDocumentAttestationsExtended: VendorDocumentAttestationExtended[] =
        action.payload.attestations.map(vda => {
          return { vendorDocumentId: action.payload.vendorDocumentId, ...vda };
        });
      state.documentState.vendorDocumentAttestations.push(
        ...vendorDocumentAttestationsExtended
      );
    },
    fetchVendorDocumentAttestationsFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.documentState.isFetchingVendorDocumentAttestations = false;
      state.documentState.fetchVendorDocumentAttestationsError = action.payload;
    },
  },
});

export const {
  fetchEscrowAccountsRequest,
  fetchEscrowAccountsFailure,
  fetchEscrowAccountsSuccess,
  fetchVendorDetailsRequest,
  fetchVendorDetailsSuccess,
  fetchVendorDetailsFailure,
  fetchVendorDocumentsRequest,
  fetchVendorDocumentsSuccess,
  fetchVendorDocumentsFailure,
  fetchVendorTableRequest,
  fetchVendorTableSuccess,
  fetchVendorTableFailure,
  fetchVendorTeamsRequest,
  fetchVendorTeamsSuccess,
  fetchVendorTeamsFailure,
  upsertVendorTeamsRequest,
  upsertVendorTeamsSuccess,
  upsertVendorTeamsFailure,
  fetchVendorUserRequest,
  fetchVendorUserSuccess,
  fetchVendorUserFailure,
  fetchVendorUsersRequest,
  fetchVendorUsersSuccess,
  fetchVendorUsersFailure,
  fetchVendorsRequest,
  fetchVendorsSuccess,
  fetchOrgChartRequest,
  fetchOrgChart,
  fetchOrgChartFailure,
  setCurrentVendor,
  fetchVendorsFailure,
  showAddUserModal,
  hideAddUserModal,
  showConfirmSendInvitationModal,
  hideConfirmSendInvitationModal,
  showConfirmResendInvitationModal,
  hideConfirmResendInvitationModal,
  updateInviteUserDetails,
  sendInvitationRequest,
  sendInvitationSuccess,
  sendInvitationFailure,
  hideInvitationSentModal,
  userRolesChangeFailure,
  userDeleteSuccess,
  hideUserDeletedModal,
  userCreateFailure,
  userUpdateFailure,
  userDeleteFailure,
  showAddTeamModal,
  hideAddTeamModal,
  showDeleteTeamModal,
  hideDeleteTeamModal,
  showTeamDeletedToast,
  hideTeamDeletedToast,
  uploadVendorDocumentRequest,
  uploadVendorDocumentSuccess,
  uploadVendorDocumentFailure,
  hideFileUploadedToast,
  showFileUploadedToast,
  hideDocumentUploadModal,
  showDocumentUploadModal,
  deleteVendorDocumentRequest,
  deleteVendorDocumentSuccess,
  deleteVendorDocumentFailure,
  showDeleteVendorDocumentModal,
  hideDeleteVendorDocumentModal,
  showVendorDocumentDeletedToast,
  hideVendorDocumentDeletedToast,
  attestVendorDocumentRequest,
  attestVendorDocumentSuccess,
  attestVendorDocumentFailure,
  approveVendorDocumentRequest,
  approveVendorDocumentSuccess,
  approveVendorDocumentFailure,
  updateOfficeAddressRequest,
  updateOfficeAddressSuccess,
  updateOfficeAddressFailure,
  updateVendorDetailsRequest,
  updateVendorDetailsSuccess,
  updateVendorDetailsFailure,
  updateOrgChartRequest,
  updateOrgChartFailure,
  deleteOfficeAddressRequest,
  deleteOfficeAddressSuccess,
  deleteOfficeAddressFailure,
  showSuccessfulVendorDocumentAttestationToast,
  hideSuccessfulVendorDocumentAttestationToast,
  showSuccessfulVendorDocumentApprovalToast,
  hideSuccessfulVendorDocumentApprovalToast,
  showAddCompanyOfficeAddressModal,
  hideAddCompanyOfficeAddressModal,
  setCompanyOfficeBeingEdited,
  showDeleteCompanyOfficeAddressModal,
  hideDeleteCompanyOfficeAddressModal,
  showOfficeAddressDeletedToast,
  hideOfficeAddressDeletedToast,
  showOfficeAddressEditedToast,
  hideOfficeAddressEditedToast,
  showOfficeAddressAddedToast,
  hideOfficeAddressAddedToast,
  showEditMediaModal,
  hideEditMediaModal,
  showEditMediaToast,
  hideEditMediaToast,
  showCompleteDocumentAuditModal,
  hideCompleteDocumentAuditModal,
  showEditCompanyProfileToast,
  hideEditCompanyProfileToast,
  showManageBillingModal,
  hideManageBillingModal,
  fetchVendorPolicyDocumentsFailure,
  fetchVendorPolicyDocumentsRequest,
  fetchVendorPolicyDocumentsSuccess,
  cancelAddUserModal,
  cancelAddTeamModal,
  cancelAddCompanyOfficeAddressModal,
  fetchLogoRequest,
  fetchLogoSuccess,
  fetchLogoError,
  showUploadLogoModal,
  hideUploadLogoModal,
  uploadLogoFailure,
  uploadLogoRequest,
  uploadLogoSuccess,
  showLogoUploadSuccessToast,
  hideLogoUploadSuccessToast,
  fetchDashboardSuccess,
  fetchDashboardError,
  fetchDashboardRequest,
  clearDashboard,
  clearVendorSupplierForm,
  initVendorSupplierForm,
  setVendorSupplierForm,
  archiveVendorDocumentFailure,
  archiveVendorDocumentRequest,
  archiveVendorDocumentSuccess,
  unarchiveVendorDocumentFailure,
  unarchiveVendorDocumentRequest,
  unarchiveVendorDocumentSuccess,
  showSuccessfulVendorDocumentRejectionToast,
  hideSuccessfulVendorDocumentRejectionToast,
  showSuccessfulVendorDocumentUpdatedToast,
  hideSuccessfulVendorDocumentUpdatedToast,
  fetchVendorDocumentsDownloadRequest,
  fetchVendorDocumentDownloadSuccess,
  cleanupVendorDocumentDownload,
  fetchVendorDocumentsDownloadError,
  hideWarningSubscriptionLimitModal,
  showWarningSubscriptionLimitModal,
  fetchIdentifierTypesRequest,
  fetchIdentifierTypesSuccess,
  fetchIdentifierTypesFailure,
  fetchCompanyIdentifiersRequest,
  fetchCompanyIdentifiersSuccess,
  fetchCompanyIdentifiersFailure,
  showDeleteCompanyIdentifierModal,
  hideDeleteCompanyIdentifierModal,
  showDeleteCompanyIdentifierToast,
  hideDeleteCompanyIdentifierToast,
  deleteCompanyIdentifierRequest,
  deleteCompanyIdentifierSuccess,
  deleteCompanyIdentifierFailure,
  editCompanyIdentifierRequest,
  editCompanyIdentifierSuccess,
  editCompanyIdentifierFailure,
  createCompanyIdentifierRequest,
  createCompanyIdentifierSuccess,
  createCompanyIdentifierFailure,
  fetchSharesListRequest,
  fetchSharesListSuccess,
  fetchVendorSupplierCategoriesSuccess,
  fetchSharesListFailure,
  fetchShareSuggestionsRequest,
  fetchShareSuggestionsSuccess,
  fetchShareSuggestionsFailure,
  shareRequest,
  shareSuccess,
  shareFailure,
  showShareModal,
  hideShareModal,
  deleteSharePermissionsRequest,
  deleteSharePermissionsSuccess,
  deleteSharePermissionsFailure,
  getVendorUserPermissionsRequest,
  getVendorUserPermissionsSuccess,
  getVendorUserPermissionsFailure,
  getVendorProductsFailure,
  getVendorProductsRequest,
  getVendorProductsSuccess,
  setFetchVendorDocumentByIdLoading,
  setCurrentVendorDocument,
  fetchVendorDocumentByIdFailure,
  patchVendorDocumentFailure,
  patchVendorDocumentRequest,
  patchVendorDocumentSuccess,
  setCompanyColour,
  fetchVendorSuppliersFailure,
  fetchVendorSuppliersRequest,
  fetchVendorSuppliersSuccess,
  fetchVendorSupplierSuccess,
  toggleMyDocumentsFilter,
  fetchCurrentCompanyRequest,
  fetchCurrentCompanySuccess,
  fetchCurrentCompanyError,
  updateCompanyProfileRequest,
  setCompanyState,
  updateCompanyProfileSuccess,
  updateCompanyProfileError,
  setMyDocumentsFilter,
  updateVendorUserRoles,
  createVendorSupplierRequest,
  createVendorSupplierSuccess,
  patchVendorSupplierRequest,
  patchVendorSupplierSuccess,
  fetchVendorDocumentApprovalsRequest,
  fetchVendorDocumentApprovalsSuccess,
  fetchVendorDocumentApprovalsFailure,
  fetchVendorDocumentAttestationsRequest,
  fetchVendorDocumentAttestationsSuccess,
  fetchVendorDocumentAttestationsFailure,
  showDeleteCertificateModal,
  hideDeleteCertificateModal,
} = vendorsSlice.actions;

export default vendorsSlice.reducer;
