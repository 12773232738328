import { createSelector } from '@reduxjs/toolkit';
import { UserDetails, VendorUser } from '../swagger';
import { ApplicationState } from '../types/applicationState';

const getUserDetails = (state: ApplicationState) => state.user.userDetails;
const getVendorUsers = (state: ApplicationState) => state.vendors.users;

export const selectCurrentVendorUser = createSelector(
  getUserDetails,
  getVendorUsers,
  (userDetails: UserDetails, vendorUsers: VendorUser[]) => {
    return vendorUsers?.find(user => user.userId === userDetails.id);
  }
);
