/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ControlExtended,
  ControlExtendedFromJSON,
  ControlExtendedFromJSONTyped,
  ControlExtendedToJSON,
  RiskExtendedAllOf,
  RiskExtendedAllOfFromJSON,
  RiskExtendedAllOfFromJSONTyped,
  RiskExtendedAllOfToJSON,
  RiskModel,
  RiskModelFromJSON,
  RiskModelFromJSONTyped,
  RiskModelToJSON,
  RiskModelActionStats,
  RiskModelActionStatsFromJSON,
  RiskModelActionStatsFromJSONTyped,
  RiskModelActionStatsToJSON,
  VendorActionModel,
  VendorActionModelFromJSON,
  VendorActionModelFromJSONTyped,
  VendorActionModelToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface RiskExtended
 */
export interface RiskExtended extends RiskModel {
  /**
   *
   * @type {Array<ControlExtended>}
   * @memberof RiskExtended
   */
  controls?: Array<ControlExtended>;
  /**
   *
   * @type {Array<VendorActionModel>}
   * @memberof RiskExtended
   */
  vendorActions?: Array<VendorActionModel>;
}

export function RiskExtendedFromJSON(json: any): RiskExtended {
  return RiskExtendedFromJSONTyped(json, false);
}

export function RiskExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RiskExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...RiskModelFromJSONTyped(json, ignoreDiscriminator),
    controls: !exists(json, 'controls')
      ? undefined
      : (json['controls'] as Array<any>).map(ControlExtendedFromJSON),
    vendorActions: !exists(json, 'vendor_actions')
      ? undefined
      : (json['vendor_actions'] as Array<any>).map(VendorActionModelFromJSON),
  };
}

export function RiskExtendedToJSON(value?: RiskExtended | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...RiskModelToJSON(value),
    controls:
      value.controls === undefined
        ? undefined
        : (value.controls as Array<any>).map(ControlExtendedToJSON),
    vendor_actions:
      value.vendorActions === undefined
        ? undefined
        : (value.vendorActions as Array<any>).map(VendorActionModelToJSON),
  };
}
