import { useDispatch } from 'react-redux';
import { canFeature } from '../../functions/access';
import { AccessObject } from '../../types/accessObject';
import { TaskModel } from '../../swagger/models/TaskModel';
import {
  setEditTaskMode,
  setSelectedTaskId,
  setShowTaskDrawer,
} from '../../store/compliance/complianceSlice';
import { EditTaskMode } from '../../store/compliance/complianceState';

export const useOnTaskViewClick = () => {
  const dispatch = useDispatch();
  const canManageTasks = canFeature(AccessObject.tasks_manage);

  const onTaskViewClick = (taskId: TaskModel['id']) => {
    dispatch(setSelectedTaskId(taskId));
    if (!canManageTasks) {
      dispatch(setEditTaskMode(EditTaskMode.View));
    }
    dispatch(setShowTaskDrawer(true));
  };
  return { onTaskViewClick };
};
