/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  SharedEmploymentStatus,
  SharedEmploymentStatusFromJSON,
  SharedEmploymentStatusFromJSONTyped,
  SharedEmploymentStatusToJSON,
  VendorTeamModel,
  VendorTeamModelFromJSON,
  VendorTeamModelFromJSONTyped,
  VendorTeamModelToJSON,
  VendorUserExternalIdentifier,
  VendorUserExternalIdentifierFromJSON,
  VendorUserExternalIdentifierFromJSONTyped,
  VendorUserExternalIdentifierToJSON,
  VendorUserRoles,
  VendorUserRolesFromJSON,
  VendorUserRolesFromJSONTyped,
  VendorUserRolesToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorUser
 */
export interface VendorUser {
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  vendorId?: string;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  fullName?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  status?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  position?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  accessLevel?: string | null;
  /**
   *
   * @type {Access}
   * @memberof VendorUser
   */
  access?: Access;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  accessGroupId?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  lastInvitedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  updatedAt?: string;
  /**
   *
   * @type {Array<VendorUserRoles>}
   * @memberof VendorUser
   */
  roles?: Array<VendorUserRoles>;
  /**
   *
   * @type {Array<VendorUserExternalIdentifier>}
   * @memberof VendorUser
   */
  externalIdentifiers?: Array<VendorUserExternalIdentifier>;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  employmentStartedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorUser
   */
  employmentEndedAt?: string | null;
  /**
   *
   * @type {SharedEmploymentStatus}
   * @memberof VendorUser
   */
  employmentStatus?: SharedEmploymentStatus;
  /**
   *
   * @type {VendorUser}
   * @memberof VendorUser
   */
  lineManager?: VendorUser;
  /**
   *
   * @type {Array<VendorTeamModel>}
   * @memberof VendorUser
   */
  vendorTeams?: Array<VendorTeamModel>;
}

export function VendorUserFromJSON(json: any): VendorUser {
  return VendorUserFromJSONTyped(json, false);
}

export function VendorUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    vendorId: !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    email: !exists(json, 'email') ? undefined : json['email'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    fullName: !exists(json, 'full_name') ? undefined : json['full_name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    position: !exists(json, 'position') ? undefined : json['position'],
    accessLevel: !exists(json, 'access_level')
      ? undefined
      : json['access_level'],
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    accessGroupId: !exists(json, 'access_group_id')
      ? undefined
      : json['access_group_id'],
    lastInvitedAt: !exists(json, 'last_invited_at')
      ? undefined
      : json['last_invited_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    roles: !exists(json, 'roles')
      ? undefined
      : (json['roles'] as Array<any>).map(VendorUserRolesFromJSON),
    externalIdentifiers: !exists(json, 'external_identifiers')
      ? undefined
      : (json['external_identifiers'] as Array<any>).map(
          VendorUserExternalIdentifierFromJSON
        ),
    employmentStartedAt: !exists(json, 'employment_started_at')
      ? undefined
      : json['employment_started_at'],
    employmentEndedAt: !exists(json, 'employment_ended_at')
      ? undefined
      : json['employment_ended_at'],
    employmentStatus: !exists(json, 'employment_status')
      ? undefined
      : SharedEmploymentStatusFromJSON(json['employment_status']),
    lineManager: !exists(json, 'line_manager')
      ? undefined
      : VendorUserFromJSON(json['line_manager']),
    vendorTeams: !exists(json, 'vendor_teams')
      ? undefined
      : (json['vendor_teams'] as Array<any>).map(VendorTeamModelFromJSON),
  };
}

export function VendorUserToJSON(value?: VendorUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    vendor_id: value.vendorId,
    user_id: value.userId,
    email: value.email,
    first_name: value.firstName,
    last_name: value.lastName,
    full_name: value.fullName,
    status: value.status,
    position: value.position,
    access_level: value.accessLevel,
    access: AccessToJSON(value.access),
    access_group_id: value.accessGroupId,
    last_invited_at: value.lastInvitedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    roles:
      value.roles === undefined
        ? undefined
        : (value.roles as Array<any>).map(VendorUserRolesToJSON),
    external_identifiers:
      value.externalIdentifiers === undefined
        ? undefined
        : (value.externalIdentifiers as Array<any>).map(
            VendorUserExternalIdentifierToJSON
          ),
    employment_started_at: value.employmentStartedAt,
    employment_ended_at: value.employmentEndedAt,
    employment_status: SharedEmploymentStatusToJSON(value.employmentStatus),
    line_manager: VendorUserToJSON(value.lineManager),
    vendor_teams:
      value.vendorTeams === undefined
        ? undefined
        : (value.vendorTeams as Array<any>).map(VendorTeamModelToJSON),
  };
}
