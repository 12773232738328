import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUsers } from '../selectors/selectUsers';
import { VendorUser, VendorUserRoles } from '../swagger';

interface ownerOptionsProps {
  valuesAsGlobalId: boolean;
  includingGuest: boolean;
  onlyAdminable: boolean;
  onlyRoles?: VendorUserRoles[];
}

// Special format for API value, which we use in SelectAnswer.tsx if object is vendor user
export const globalUserId = (user: VendorUser) => {
  return `VendorUser:${user.id}`;
};

export const useOwnerOptions = (props: Partial<ownerOptionsProps> = {}) => {
  const users = useSelector(selectUsers);
  const adminRoles = [
    VendorUserRoles.Admin,
    VendorUserRoles.SecurityManager,
    VendorUserRoles.ExternalConsultant,
  ];
  const ownerOptions = useMemo(
    () =>
      users
        .filter(user =>
          props.includingGuest
            ? true
            : user.roles.indexOf(VendorUserRoles.Guest) === -1
        )
        .filter(user =>
          props.onlyAdminable
            ? user.roles.some(role => adminRoles.includes(role))
            : true
        )
        .filter(user =>
          props.onlyRoles
            ? user.roles.some(role => props.onlyRoles.includes(role))
            : true
        )
        .map(user => {
          return {
            value: props.valuesAsGlobalId ? globalUserId(user) : user.id,
            label: user.fullName,
          };
        }),
    [props.includingGuest, props.onlyAdminable, props.valuesAsGlobalId, users]
  );
  return { users, ownerOptions };
};
