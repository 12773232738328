import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTasksFilters,
  setSelectedTaskId,
  setShowControlDrawer,
  setShowTaskDrawer,
  setSelectedCategoryId,
  setSelectedFrameworkId,
} from '../../../store/compliance/complianceSlice';
import {
  fetchComplianceFrameworks,
  fetchComplianceTasks,
} from '../../../store/compliance/complianceThunks';
import {
  FrameworkModel,
  TaskModel,
  TaskModelStatusEnum,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import { AdoptechReactSelect } from '../../AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechTextInput } from '../../AdoptechTextInput/AdoptechTextInput';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { ComplianceEditControlDrawer } from '../../../features/compliance/controls/EditControlDrawer/ComplianceEditControlDrawer/ComplianceEditControlDrawer';
import { ComplianceEditTaskDrawer } from '../../../features/compliance/controls/EditControlCheckDrawer/ComplianceEditTaskDrawer/ComplianceEditTaskDrawer';
import { ComplianceTasksList } from '../ComplianceTasksList/ComplianceTasksList';
import './ComplianceTasks.scss';
import { selectFilteredTasks } from '../../../selectors/selectFilteredTasks';
import { selectCurrentVendorUser } from '../../../selectors/selectCurrentVendorUser';
import { selectTaskPageLoading } from '../../../selectors/selectTaskPageLoading';
import { canFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { useOnTaskViewClick } from '../../../hooks/compliance/useOnTaskViewClick';
import { PaymentLandingPageChecker } from '../../../features/pricing/PaymentLandingPageChecker/PaymentLandingPageChecker';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { SelectionOption } from '../../../types/selectionOption';
import { Lozenge } from '../../Lozenge/Lozenge';
import { useParams } from 'react-router';
import {
  complianceFrameworkTasksRoute,
  complianceTasksRoute,
} from '../../Routes/Routes';
import { useAdoptechDebounce } from '../../../hooks/useAdoptechDebounce';

export const ComplianceTasks: React.FC = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const baseCss = 'complianceTasks';

  const isTaskPageLoading = useSelector(selectTaskPageLoading);
  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  const vendorUser = useSelector(selectCurrentVendorUser);

  const [frameworkId, setFrameworkId] = useState(null as string | null);

  const statusOptions: SelectionOption[] = [
    {
      label: 'No selection',
      value: null,
    },
    {
      label: 'Pending',
      value: TaskModelStatusEnum.Pending,
    },
    {
      label: 'Compliant',
      value: TaskModelStatusEnum.Compliant,
    },
    {
      label: 'Failed',
      value: TaskModelStatusEnum.Failed,
    },
  ];

  const filteredTasks = useSelector(selectFilteredTasks(search));

  const {
    showControlDrawer,
    showTaskDrawer,
    frameworks: stateFrameworks,
    tasksFilters: filters,
    tasksMeta,
  } = useSelector((state: ApplicationState) => state.compliance);

  // skip frameworks without data.( ex: backend doesn't generated them yet for the vendor )
  const frameworks = stateFrameworks.filter(framework => framework.id);

  const selectedControl = useSelector((state: ApplicationState) =>
    state.compliance.controls.find(
      c => c.id === state.compliance.selectedControlId
    )
  );

  const params = useParams() as { id: FrameworkModel['id'] };
  const paramsId = params.id;
  // on initial fetch avoid debounce - fetch frameworks and then immediately fetch tasks
  const [initialFetch, setInitialFetch] = useState(true);

  useEffect(() => {
    setInitialFetch(true);
    dispatch(setSelectedFrameworkId(paramsId));
    dispatch(setSelectedCategoryId(null));

    setFrameworkId(paramsId);

    dispatch(
      fetchComplianceFrameworks(vendorId, () => {
        handleFrameworkChange({ value: paramsId, label: null });
      })
    );
  }, [vendorId, vendorUser.roles]);

  const handleFrameworkChange = (value: SelectionOption) => {
    const newFrameworkId = value.value;
    setFrameworkId(newFrameworkId);
    const newUrl = newFrameworkId
      ? complianceFrameworkTasksRoute.replace(':id', newFrameworkId)
      : complianceTasksRoute;
    // silently change URL without redirect
    window.history.pushState(null, '', newUrl);

    fetchTasksOnFilterChange({
      newStatus: currentStatus?.value,
      newApplicable: applicable,
      newSearchName: search,
      newFrameworkId,
    });
  };

  const fetchTasksOnFilterChange = ({
    newStatus,
    newApplicable,
    newFrameworkId,
    newSearchName,
  }: {
    newStatus: string;
    newApplicable: string;
    newFrameworkId: string;
    newSearchName: string;
  }) => {
    checkWithDebounce(
      () => {
        dispatch(
          fetchComplianceTasks({
            vendorId,
            includeStats: true,
            ...(!newSearchName ? {} : { name: newSearchName }),
            ...(!newFrameworkId ? {} : { frameworkId: newFrameworkId }),
            ...(!newStatus ? {} : { status: newStatus }),
            ...(newApplicable === null
              ? {}
              : { applicable: newApplicable === 'applicable' }),
          })
        );
        setInitialFetch(false);
      },
      initialFetch ? 0 : 500 // avoid debounce initially => then use default value
    );
  };

  const handleStatusChange = (option: SelectionOption) => {
    dispatch(setTasksFilters({ status: option.value }));
    fetchTasksOnFilterChange({
      newStatus: option.value,
      newApplicable: applicable,
      newFrameworkId: frameworkId,
      newSearchName: search,
    });
  };
  const handleScopeChange = (option: SelectionOption) => {
    dispatch(setTasksFilters({ applicable: option.value }));

    fetchTasksOnFilterChange({
      newStatus: currentStatus?.value,
      newApplicable: option.value,
      newFrameworkId: frameworkId,
      newSearchName: search,
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchName = e.target.value;
    setSearch(newSearchName);

    fetchTasksOnFilterChange({
      newStatus: currentStatus?.value,
      newApplicable: applicable,
      newFrameworkId: frameworkId,
      newSearchName,
    });
  };

  const applicable = filters.applicable || null;
  const currentStatus = statusOptions.find(o => o.value === filters.status);

  const canManageTasks = canFeature(AccessObject.tasks_manage);

  const frameworkOptions = [
    {
      label: 'No selection',
      value: null,
    },

    ...frameworks.map(fw => ({
      label: fw.name,
      value: fw.id,
    })),
  ];
  const { onTaskViewClick } = useOnTaskViewClick();

  const scopeSelectOptions = [
    {
      label: 'No selection',
      value: null,
    },
    {
      label: 'In scope',
      value: 'applicable',
    },
    {
      label: 'Out of scope',
      value: 'notApplicable',
    },
  ];

  const nullStats = {
    testsTotal: 0,
    tasksTotal: 0,
    testsPassing: 0,
    testsFailing: 0,
    testsPending: 0,
  };
  const stats = (tasksMeta || {})['stats'] || nullStats;
  const { testsTotal, tasksTotal, testsPassing, testsFailing, testsPending } =
    stats;
  const calculatePercentage = (count: number) => {
    if (typeof count !== 'number' || typeof testsTotal !== 'number') {
      return 0;
    }
    return testsTotal < 1 ? 0 : Math.round((count / testsTotal) * 100);
  };
  const checkWithDebounce = useAdoptechDebounce();
  const passingPercentage = calculatePercentage(testsPassing);
  const failingPercentage = calculatePercentage(testsFailing);
  const pendingPercentage = calculatePercentage(testsPending);
  return (
    <PaymentLandingPageChecker type="complianceFrameworks">
      <ComplianceEditTaskDrawer
        show={showTaskDrawer}
        onClose={() => dispatch(setShowTaskDrawer(false))}
      />
      <ComplianceEditControlDrawer
        show={showControlDrawer}
        control={selectedControl}
        onClose={() => dispatch(setShowControlDrawer(false))}
      />
      <div className={baseCss}>
        <div className={baseCss + '--header'}>
          <div className={baseCss + '--badgeCount'}>
            <div className={baseCss + '--count-label'}>Control Checks</div>
            <Lozenge value={tasksTotal} />
          </div>
          <div className={baseCss + '--divider'} />
          <div className={baseCss + '--badgeCount'}>
            <div className={baseCss + '--count-label'}>Tests</div>
            <Lozenge value={testsTotal} />
          </div>
          <div className={baseCss + '--divider'} />
          <div className={baseCss + '--count'}>
            <div className={baseCss + '--count-value-percent'}>
              {passingPercentage}
            </div>
            <div className={baseCss + '--count-label ' + baseCss + '--passing'}>
              Tests Passing
            </div>
          </div>
          <div className={baseCss + '--divider'} />
          <div className={baseCss + '--count'}>
            <div className={baseCss + '--count-value-percent'}>
              {failingPercentage}
            </div>
            <div className={baseCss + '--count-label ' + baseCss + '--failing'}>
              Tests Failing
            </div>
          </div>
          <div className={baseCss + '--divider'} />
          <div className={baseCss + '--count'}>
            <div className={baseCss + '--count-value-percent'}>
              {pendingPercentage}
            </div>
            <div className={baseCss + '--count-label ' + baseCss + '--pending'}>
              Tests Pending
            </div>
          </div>
          <div className={baseCss + '--buttons'}>
            {canManageTasks && (
              <AdoptechButton
                onClick={() => {
                  dispatch(setSelectedTaskId(undefined));
                  dispatch(setShowTaskDrawer(true));
                }}
                variant={AdoptechButtonVariant.PrimaryOrange}
                icon={faPlus}
                iconSize="large"
                rounded
              >
                Add new
              </AdoptechButton>
            )}
          </div>
        </div>
        <div className={baseCss + '--list'}>
          <div className={baseCss + '--filters'}>
            <div className={baseCss + '--frameworkSelect'}>
              <AdoptechReactSelect
                id="frameworkSelect"
                options={frameworkOptions}
                onChange={handleFrameworkChange}
                value={
                  frameworkId === null
                    ? null
                    : frameworkOptions.find(f => f.value === frameworkId)
                }
                placeholder="Select framework"
              />
            </div>
            <div className={baseCss + '--statusSelect'}>
              <AdoptechReactSelect
                id="statusSelect"
                options={statusOptions}
                onChange={handleStatusChange}
                value={currentStatus}
                placeholder="Status"
                showUserAvatar
              />
            </div>
            <div className={baseCss + '--scopeFilter'}>
              <AdoptechReactSelect
                id="scopeSelect"
                options={scopeSelectOptions}
                onChange={handleScopeChange}
                value={scopeSelectOptions.find(
                  o => o.value === filters.applicable
                )}
                placeholder="Scope"
              />
            </div>
            <div className={baseCss + '--search'}>
              <AdoptechTextInput
                id="search"
                value={search}
                onChange={handleSearchChange}
                type="text"
                placeholder="Search for control checks"
                icon={faSearch}
                additionalClass="adoptechTextInput-search"
              />
            </div>
          </div>
          {isTaskPageLoading || initialFetch ? (
            <LoadingSpinner />
          ) : (
            <>
              {filteredTasks.length ? (
                <ComplianceTasksList
                  tasks={filteredTasks}
                  onEdit={(task: TaskModel) => {
                    onTaskViewClick(task?.id);
                  }}
                />
              ) : (
                <div className="mt-2">No checks to display</div>
              )}
            </>
          )}
        </div>
      </div>
    </PaymentLandingPageChecker>
  );
};
