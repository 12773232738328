import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../types/applicationState';
import { Accordion } from 'react-bootstrap';
import { AdoptechAccordionCard } from '../AdoptechAccordionCard/AdoptechAccordionCard';
import {
  fetchComplianceControls,
  fetchControlExtended,
} from '../../store/compliance/complianceThunks';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { ControlRow, useControlClick } from './ControlRow';
import { ControlTasks } from './ControlTasks';
import { ControlExtended } from '../../swagger';
import { SortableTableHeader } from '../SortableTableHeader/SortableTableHeader';
import { Grid } from '../../types/grid';
import { setSelectedControlId } from '../../store/compliance/complianceSlice';

import './ControlsList.scss';

const baseCss = 'controlsList';

interface Props {
  controls: ControlExtended[];
}

export const ControlsList: React.FC<Props> = ({ controls }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(selectCurrentVendor)?.id;

  useEffect(() => {
    dispatch(fetchComplianceControls({ vendorId }));
  }, []);

  const applicable = useSelector(
    (state: ApplicationState) => state.compliance.controlsFilters.applicable
  );

  const loadTasksFor = (control: ControlExtended) => () => {
    dispatch(setSelectedControlId(control.id));
    if (control.tasks) return;
    dispatch(fetchControlExtended(control.id));
  };

  const isFetchingControls = useSelector(
    (state: ApplicationState) => state.compliance.isFetchingControls
  );
  const controlClicker = useControlClick();

  if (isFetchingControls) return <LoadingSpinner />;

  return (
    <div className={baseCss}>
      {applicable == 'notApplicable' && (
        <p className={baseCss + '--notApplicable'}>
          Controls marked as out of scope
        </p>
      )}
      <div className={baseCss + '--headers'}>
        <div> </div>
        <SortableTableHeader
          notInTable
          columnName="name"
          label="Control"
          grid={Grid.Controls}
        />
        <div>Frameworks</div>
        <SortableTableHeader
          notInTable
          columnName="assignee"
          label="Owner"
          grid={Grid.Controls}
        />
        <SortableTableHeader
          notInTable
          columnName="nextReviewDate"
          label="Next review"
          grid={Grid.Controls}
        />
        <div>Actions</div>
      </div>
      <Accordion>
        {controls.map(control => {
          const editControlHandler = controlClicker(control);
          return (
            <AdoptechAccordionCard
              key={control.id}
              index={control.id}
              headerHandleClick={editControlHandler}
              headerClass={
                control.reviewOverdue ? 'card-header--overdue' : null
              }
              callbackOnOpen={loadTasksFor(control)}
              title={<ControlRow control={control} />}
            >
              <ControlTasks control={control} />
            </AdoptechAccordionCard>
          );
        })}
      </Accordion>
    </div>
  );
};
