import { CalendarItemModel } from '../../../swagger/models/CalendarItemModel';
import React from 'react';
import { Grid } from '../../../types/grid';
import { NoDataText } from '../../../components/NoDataText/NoDataText';
import AdoptechGridTable from '../../../components/AdoptechGridTable/AdoptechGridTable';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import classNames from 'classnames';
import {
  isAction,
  isCorrectiveAction,
  isEvent,
  isIncident,
} from '../selectors/selectCalendarHSItems';
import { completedStatus, overdueStatus } from '../CalendarPage/CalendarPage';
import { AdoptechTooltip } from '../../../components/AdoptechTooltip/AdoptechTooltip';
import { formatShortDate } from '../../../functions/formatShortDate';
import moment from 'moment';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import { capitalize } from '../../../functions/capitalize';
import { VendorEventEventType } from '../../../swagger';
import { eventTypes } from '../EditEventModal/EditEventModal';

const tooltipText = (item: CalendarItemModel) => {
  if (isAction(item)) return 'Action';
  if (isEvent(item)) return `Event: ${eventTypeValueToLabel(item.eventType)}`;
  if (isCorrectiveAction(item)) return 'Corrective Action';
  if (isIncident(item)) return 'Incident';
  return 'Unknown';
};

const eventTypeValueToLabel = (itemEventType: VendorEventEventType) =>
  eventTypes.find(eventType => eventType[1] === itemEventType)?.[0] ||
  itemEventType
    .split('_')
    .map(word => capitalize(word))
    .join(' ');

export const CalendarItemsList: React.FC<{
  items: CalendarItemModel[];
  onClick(item: CalendarItemModel): void;
  showAllItems: boolean;
  showTooltip: boolean;
  noDataText?: string;
  gridName?: string;
  extraClass?: 'wide-table';
}> = ({
  items,
  onClick,
  showTooltip,
  showAllItems,
  noDataText,
  gridName,
  extraClass,
}) => {
  if (items.length < 1)
    return (
      <NoDataText
        extraClass="mt-2"
        text={noDataText || 'No calendar items to show'}
      />
    );
  const baseCss = 'calendarPageTable';

  const itemClass = (item: CalendarItemModel) => {
    if (isEvent(item)) return 'event';
    if (isAction(item)) return 'action';
    if (isCorrectiveAction(item)) return 'corrective-action';
    if (isIncident(item)) return 'incident-log';
    return 'unknown';
  };
  return (
    <div className={classNames(baseCss, { [`${extraClass}`]: extraClass })}>
      <div className={baseCss + (showAllItems ? '--3-columns' : '--2-columns')}>
        <AdoptechGridTable
          header={
            <div className="adoptechGridTable--header">
              <SortableTableHeader<CalendarItemModel>
                columnName="date"
                grid={gridName || Grid.Calendar}
                label="Deadline"
                notInTable
              />

              {showAllItems && extraClass ? (
                <div className="owner-column">Owner</div>
              ) : (
                <div></div>
              )}
              <SortableTableHeader<CalendarItemModel>
                columnName="name"
                grid={gridName || Grid.Calendar}
                label="Item"
                notInTable
              />
            </div>
          }
        >
          {items.map(item => {
            return (
              <div
                key={item.id}
                className={classNames('adoptechGridTable--row', {
                  'adoptechGridTable--row-completed':
                    isAction(item) && item.status === completedStatus,

                  'adoptechGridTable--row-expired':
                    isAction(item) && item.status === overdueStatus,
                })}
                onClick={() => onClick(item)}
              >
                <AdoptechTooltip
                  text={tooltipText(item)}
                  identifier={item.id}
                  showTooltip
                >
                  <div
                    className={`${baseCss}--band-column ${itemClass(item)}`}
                  ></div>
                </AdoptechTooltip>
                <div className={baseCss + '--date-column'}>
                  <div>{formatShortDate(item.date)}</div>
                  <div className={baseCss + '--day'}>
                    {item.date ? moment(item.date).format('dddd') : null}
                  </div>
                </div>
                {showAllItems && (
                  <AdoptechOverflowLine>
                    <UserAvatar
                      size="small"
                      user={item.owner}
                      showTooltip={showTooltip}
                    />
                  </AdoptechOverflowLine>
                )}
                <div className={baseCss + '--item-column'}>
                  <div className={baseCss + '--title'}>{item.name}</div>
                  <div className={baseCss + '--description'}>
                    {item.description}
                  </div>
                </div>
              </div>
            );
          })}
        </AdoptechGridTable>
      </div>
    </div>
  );
};
