import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ControlExtended } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { ComplianceControlTasksList } from '../compliance/ComplianceControlTasksList/ComplianceControlTasksList';
import {
  createPayload,
  EditControl,
} from '../compliance/Types/complianceTypes';
import {
  fetchComplianceControls,
  fetchControlExtended,
  patchComplianceControl,
} from '../../store/compliance/complianceThunks';

export interface Props {
  control: ControlExtended;
}

const baseCss = 'controlTasks';

export const ControlTasks: React.FC<Props> = ({ control }) => {
  const dispatch = useDispatch();

  const isFetchingTasks = useSelector(
    (state: ApplicationState) => state.compliance.isFetchingTasks
  );

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor?.id
  );

  if (isFetchingTasks && !control.tasks) return <LoadingSpinner />;
  if (!control.tasks) return <></>;

  const handleTaskRemoval = (taskControl: ControlExtended, taskId: string) => {
    const updatedControl = new EditControl({
      ...control,
      tasks: control.tasks.filter(t => t.id !== taskId),
    });

    const onSuccess = () => {
      dispatch(fetchControlExtended(control.id));
    };

    dispatch(
      patchComplianceControl(
        control?.id,
        createPayload(updatedControl),
        onSuccess
      )
    );
  };

  return (
    <div className={baseCss}>
      <ComplianceControlTasksList
        control={control}
        onRemoveTask={handleTaskRemoval}
      />
    </div>
  );
};
