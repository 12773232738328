import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hidePolicyDrawer } from '../../store/policies/policiesSlice';
import { patchVendorPolicy } from '../../store/policies/policiesThunks';
import { patchVendorDocument } from '../../store/vendors/vendorsThunks';
import {
  VendorDocumentExtended,
  VendorDocumentStatusEnum,
  VendorPolicyDetails,
} from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import AdoptechHS from '../AdoptechHS/AdoptechHS';
import { PolicyOverviewSection } from '../PolicyOverviewSection/PolicyOverviewSection';
import { PolicyFrameworksList } from '../PolicyFrameworksList/PolicyFrameworksList';

export const PolicyDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const isPolicyDrawerShowing = useSelector(
    (state: ApplicationState) => state.policies.isPolicyDrawerShowing
  );
  const policy = useSelector(
    (state: ApplicationState) => state.policies.policyToEdit
  );
  const onPolicyDrawerClose = () => {
    dispatch(hidePolicyDrawer());
  };
  const isADocument = (thing: VendorPolicyDetails | VendorDocumentExtended) =>
    Object.values(VendorDocumentStatusEnum).includes(
      (thing as VendorDocumentExtended).status
    );

  const handleSave = () => {
    if (isADocument(policy)) {
      dispatch(
        patchVendorDocument({
          documentId: policy.id,
          vendorDocument: {
            reviewFrequency: policy.reviewFrequency,
            ownerId: policy.ownerId,
          },
        })
      );
    } else {
      dispatch(
        patchVendorPolicy(policy.id, policy as VendorPolicyDetails, false)
      );
    }
    dispatch(hidePolicyDrawer());
  };

  const Footer: React.FC = () => {
    return (
      <>
        <AdoptechButton
          onClick={onPolicyDrawerClose}
          variant={AdoptechButtonVariant.White}
        >
          Cancel
        </AdoptechButton>
        <AdoptechButton
          onClick={handleSave}
          variant={AdoptechButtonVariant.Primary}
        >
          Save
        </AdoptechButton>
      </>
    );
  };

  return isPolicyDrawerShowing && policy ? (
    <AdoptechHS
      show={isPolicyDrawerShowing}
      onClose={onPolicyDrawerClose}
      footer={<Footer />}
      title={`Edit ${policy.name}`}
      extraClass="adoptechHS--medium"
      showConfirmationWarning
    >
      <PolicyOverviewSection />
      <PolicyFrameworksList />
    </AdoptechHS>
  ) : null;
};
