import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { Intent, Lozenge } from '../Lozenge/Lozenge';
import { AdoptechTextInput } from '../AdoptechTextInput/AdoptechTextInput';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { ApplicationState } from '../../types/applicationState';

import './ControlsHeader.scss';
import { SelectionOption } from '../../types/selectionOption';
import { useAssigneeFilter } from '../../hooks/compliance/useAssigneeFilter';
import {
  setControlFormModel,
  setControlsFilterApplicable,
  setControlsFilterAssigneeId,
  setControlsFilterFrameworkIdentifier,
  setControlsFilterStatus,
  setControlsSearch,
  setSelectedControlId,
  setShowControlDrawer,
} from '../../store/compliance/complianceSlice';
import { useFrameworkFilter } from '../../hooks/compliance/useFrameworkFilter';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { ControlExtended, ControlModelStatusEnum } from '../../swagger';
import { EditControl } from '../compliance/Types/complianceTypes';
import { ControlStatusText } from '../../features/compliance/controls/EditControlDrawer/ComplianceEditControlForm/complianceStatus';
import { canFeature } from '../../functions/access';
import { AccessObject } from '../../types/accessObject';
import { reactSelectLightStyle } from '../../functions/reactSelectCustomTheme';

const baseCss = 'controlsHeader';

interface Props {
  controls: ControlExtended[];
}

export type ControlPageFilterStatus =
  | ControlModelStatusEnum.Compliant
  | 'not-compliant';

export const ControlsHeader: React.FC<Props> = ({ controls }) => {
  const dispatch = useDispatch();

  const controlsSearch = useSelector(
    (state: ApplicationState) => state.compliance.controlsSearch
  );

  const allControls = useSelector(
    (state: ApplicationState) => state.compliance.controls
  );

  const filters = useSelector(
    (state: ApplicationState) => state.compliance.controlsFilters
  );
  const canManageControls = canFeature(AccessObject.controls_manage);

  const { frameworkSelectOptions, currentFramework } = useFrameworkFilter(
    allControls,
    filters
  );

  const { assigneeSelectOptions, currentAssignee } = useAssigneeFilter(
    allControls,
    filters
  );

  const statusSelectOptions: SelectionOption[] = [
    {
      label: 'No selection',
      value: null,
    },
    {
      label: ControlStatusText.Compliant,
      value: ControlModelStatusEnum.Compliant,
    },
    {
      label: ControlStatusText.NotCompliant,
      value: 'not-compliant',
    },
  ];

  const scopeSelectOptions = [
    {
      label: 'In scope',
      value: 'applicable',
    },
    {
      label: 'Out of scope',
      value: 'notApplicable',
    },
  ];

  const createControl = () => {
    dispatch(setSelectedControlId(undefined));
    dispatch(setControlFormModel(EditControl.createNew()));
    dispatch(setShowControlDrawer(true));
  };

  return (
    <div className={baseCss}>
      <div className={baseCss + '--row'}>
        <div className={baseCss + '--title'}>
          Controls
          <Lozenge intent={Intent.None} value={controls.length} />
        </div>
        <div className={baseCss + '--search'}>
          <AdoptechTextInput
            id="search"
            value={controlsSearch}
            onChange={e => dispatch(setControlsSearch(e.target.value))}
            type="text"
            placeholder="Search controls"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
        {canManageControls && (
          <AdoptechButton
            variant={AdoptechButtonVariant.PrimaryOrange}
            icon={faPlus}
            iconSize="large"
            rounded
            onClick={createControl}
          >
            Add control
          </AdoptechButton>
        )}
      </div>
      <div className={baseCss + '--row'}>
        Controls are processes that you implement to modify risks. They describe
        how you are going to meet policies.
      </div>
      <div className={baseCss + '--row'}>
        <AdoptechReactSelect
          id="frameworkSelect"
          additionalStyling={reactSelectLightStyle}
          options={[
            {
              label: 'No selection',
              value: null,
            },
            ...frameworkSelectOptions,
          ]}
          onChange={(option: SelectionOption) => {
            dispatch(setControlsFilterFrameworkIdentifier(option.value));
          }}
          value={currentFramework}
          placeholder="Select framework"
        />
        <AdoptechReactSelect
          id="assigneeSelect"
          additionalStyling={reactSelectLightStyle}
          options={[
            {
              label: 'No selection',
              value: null,
            },
            ...assigneeSelectOptions,
          ]}
          onChange={(option: SelectionOption) => {
            dispatch(setControlsFilterAssigneeId(option.value));
          }}
          value={currentAssignee}
          placeholder="Select owner"
        />
        <AdoptechReactSelect
          id="statusSelect"
          additionalStyling={reactSelectLightStyle}
          options={statusSelectOptions}
          onChange={(option: SelectionOption) =>
            dispatch(
              setControlsFilterStatus(option.value as ControlPageFilterStatus)
            )
          }
          value={
            filters.status &&
            statusSelectOptions.find(o => o.value === filters.status)
          }
          placeholder="Status"
        />
        <AdoptechReactSelect
          id="scopeSelect"
          additionalStyling={reactSelectLightStyle}
          options={scopeSelectOptions}
          onChange={(option: SelectionOption) => {
            dispatch(setControlsFilterApplicable(option.value));
          }}
          value={scopeSelectOptions.find(o => o.value == filters.applicable)}
          placeholder="Scope"
        />
      </div>
    </div>
  );
};
