import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ComplianceControlPoliciesSimpleList } from '../../../../../components/compliance/ComplianceControlPoliciesSimpleList/ComplianceControlPoliciesSimpleList';
import { ComplianceControlTasksSimpleList } from '../ComplianceControlTasksSimpleList/ComplianceControlTasksSimpleList';
import {
  ComplianceEditControlFormModel,
  minimumAssignedFrameworks,
} from '../../../../../components/compliance/Types/complianceTypes';
import './ComplianceEditControlForm.scss';
import { SelectionOption } from '../../../../../types/selectionOption';
import {
  setControlFormModel,
  setControlFormModelValid,
} from '../../../../../store/compliance/complianceSlice';
import { ApplicationState } from '../../../../../types/applicationState';
import { Policy } from '../../../../../swagger';
import { EditControlMode } from '../../../../../store/compliance/complianceState';
import { selectIsNormalModeControlDrawer } from '../../../../../selectors/compliance/selectIsNormalModeEditControlDrawer';
import { useOwnerOptions } from '../../../../../hooks/useOwnerOptions';
import classNames from 'classnames';
import { AdoptechReactSelect2 } from '../../../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { AdoptechTextArea2 } from '../../../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { AdoptechTextInput2 } from '../../../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { ControlStatusText, getControlStatusText } from './complianceStatus';
import { EditControlReview } from '../EditControlReview/EditControlReview';
import { EditControlTreatmentPlan } from '../EditControlTreatmentPlan/EditControlTreatmentPlan';
import { ComplianceControlFrameworksSimpleList } from '../ComplianceControlFrameworksSimpleList/ComplianceControlFrameworksSimpleList';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';

interface EditControlFormProps {
  control: ComplianceEditControlFormModel;
}

export const ComplianceEditControlForm: React.FC<EditControlFormProps> = ({
  control,
}) => {
  const baseCss = 'complianceEditControlForm';
  const descriptionRowNumber = 2;
  const dispatch = useDispatch();
  const { users, ownerOptions } = useOwnerOptions({
    onlyAdminable: true,
  });

  const {
    editControlFormModel,
    isFetchingControlDetails,
    isPostingReviewHistoryItem,
    editControlMode,
  } = useSelector((state: ApplicationState) => state.compliance);

  const [description, setDescription] = useState<string>(control?.description);
  const [reasonForExclusion, setReasonForExclusion] = useState<string>(
    control?.reasonForExclusion
  );

  const isValid = () => {
    if (!editControlFormModel) return false;

    if (nameError || frameworksRelationsError) return false;

    return true;
  };

  const inputFieldClass = control.create
    ? `${baseCss}--nameInput`
    : `${baseCss}--status`;

  const reasonForExclusionRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    dispatch(setControlFormModelValid(isValid()));
  }, [control, editControlFormModel]);

  useEffect(() => {
    isSettingNotApplicable &&
      reasonForExclusionRef.current &&
      reasonForExclusionRef.current.focus();
  }, [editControlMode]);

  const isNormalMode = useSelector(selectIsNormalModeControlDrawer);

  // We have 3 applicable related states: Applicable, Setting not applicable ( temporary, not saved to the BE yet ), Not applicable
  const isSettingNotApplicable =
    editControlMode === EditControlMode.MarkNotApplicable;
  // If controls is not applicable ( already saved to the BE )
  const isNotApplicable = !control.applicable && !isSettingNotApplicable;

  const isDescriptionDisabled = !isNormalMode;

  const statusText = useMemo(
    () => getControlStatusText(control?.tasks, control?.applicable),
    [control]
  );

  const frameworksRelationsError =
    editControlFormModel?.frameworksRelations?.length <
    minimumAssignedFrameworks;

  const nameError =
    editControlFormModel.create && !editControlFormModel.name.trim();

  const showControlDescriptionTextArea =
    !control.create && editControlFormModel.custom;

  return (
    <div className={baseCss}>
      <div className={baseCss + '--fieldBlocks'}>
        {control.create && (
          <div
            className={classNames(baseCss + '--fieldBlock', {
              [`${baseCss}--fieldBlock-error`]: nameError,
            })}
          >
            <div className={baseCss + '--field'}>
              <>
                <AdoptechTextInput2
                  rounded
                  label="Name"
                  additionalClass={inputFieldClass}
                  id={baseCss + '--controlInput'}
                  autoFocus
                  errorClass="adoptech-error"
                  onChange={e => {
                    dispatch(
                      setControlFormModel({
                        ...editControlFormModel,
                        name: e.currentTarget.value,
                        formTouched: true,
                      })
                    );
                  }}
                  placeholder="Enter control name here"
                  type="text"
                  value={editControlFormModel.name}
                />
              </>
            </div>
          </div>
        )}
        <div className={classNames(baseCss + '--fieldBlock')}>
          <div className={baseCss + '--field'}>
            <AdoptechReactSelect2
              rounded
              id="userSelector"
              isDisabled={!isNormalMode}
              options={ownerOptions}
              onChange={(e: SelectionOption) => {
                dispatch(
                  setControlFormModel({
                    ...editControlFormModel,
                    assignee: users.find(user => user.id === e.value),
                    formTouched: true,
                  })
                );
              }}
              value={ownerOptions.find(
                option => option.value == editControlFormModel?.assignee?.id
              )}
              placeholder="Select assignee"
              label="Owner"
              showUserAvatar
            />
          </div>
        </div>
        {(showControlDescriptionTextArea || control.description) && (
          <div className={baseCss + '--fieldBlock'}>
            <div className={baseCss + '--fieldTitle'}>
              <div>Control description</div>
            </div>
            <div className={baseCss + '--field'}>
              {showControlDescriptionTextArea ? (
                <AdoptechTextArea2
                  rounded
                  disabled={isDescriptionDisabled}
                  label="Control description"
                  onChange={e => {
                    const tempDesc = e.currentTarget.value;
                    dispatch(
                      setControlFormModel({
                        ...editControlFormModel,
                        description: tempDesc,
                        formTouched: true,
                      })
                    );
                    setDescription(tempDesc);
                  }}
                  value={description}
                  placeholder="Add description here"
                  id="description"
                  rows={descriptionRowNumber}
                />
              ) : (
                control.description
              )}
            </div>
          </div>
        )}

        {isSettingNotApplicable || !editControlFormModel.applicable ? (
          <>
            <div
              className={classNames(baseCss + '--fieldBlock', {
                [`${baseCss}--fieldBlock-error`]:
                  isSettingNotApplicable &&
                  !editControlFormModel?.reasonForExclusion?.trim(),
              })}
            >
              <div className={baseCss + '--field'}>
                <div className={baseCss + '--reasonForExclusionField'}>
                  <AdoptechTextArea2
                    label="Reason for exclusion"
                    onChange={e => {
                      dispatch(
                        setControlFormModel({
                          ...editControlFormModel,
                          applicable: false,
                          reasonForExclusion: e.currentTarget.value,
                          formTouched: true,
                        })
                      );
                      setReasonForExclusion(e.currentTarget.value);
                    }}
                    value={reasonForExclusion}
                    placeholder="Provide a reason for why the control was marked as N/A."
                    id="reasonForExclusion"
                    ref={reasonForExclusionRef}
                    disabled={
                      !isSettingNotApplicable &&
                      !editControlFormModel.applicable
                    }
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={baseCss + '--fieldBlock'}>
              <div className={baseCss + '--field'}>
                <EditControlTreatmentPlan control={control} />
              </div>
            </div>
            {!control.create && (
              <div className={baseCss + '--fieldBlock'}>
                <div className={baseCss + '--field'}>
                  <EditControlReview control={control} />
                </div>
              </div>
            )}

            <div className={baseCss + '--fieldBlock'}>
              <div className={baseCss + '--field'}>
                <div className={baseCss + '--control-header'}>
                  <div>Control status</div>
                  {!isFetchingControlDetails && (
                    <div
                      className={classNames(baseCss + '--control-header-icon', {
                        green: statusText === ControlStatusText.Compliant,
                      })}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                      <div>{statusText}</div>
                    </div>
                  )}
                </div>
                <ComplianceControlTasksSimpleList />
              </div>
            </div>

            <div className={baseCss + '--fieldBlock'}>
              <div className={baseCss + '--field'}>
                <ComplianceControlPoliciesSimpleList />
              </div>
            </div>

            <div
              className={classNames(baseCss + '--fieldBlock', {
                [`${baseCss}--fieldBlock-error`]: frameworksRelationsError,
              })}
            >
              <div className={baseCss + '--field'}>
                <ComplianceControlFrameworksSimpleList control={control} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ComplianceEditControlForm;
