/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VendorUserRoles {
  Employee = 'employee',
  AssessmentManager = 'assessment_manager',
  PolicyEditor = 'policy_editor',
  Signatory = 'signatory',
  Admin = 'admin',
  Guest = 'guest',
  Auditor = 'auditor',
  SecurityManager = 'security_manager',
  ExternalConsultant = 'external_consultant',
  TrusthubAdmin = 'trusthub_admin',
  SystemUser = 'system_user',
}

export function VendorUserRolesFromJSON(json: any): VendorUserRoles {
  return VendorUserRolesFromJSONTyped(json, false);
}

export function VendorUserRolesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorUserRoles {
  return json as VendorUserRoles;
}

export function VendorUserRolesToJSON(value?: VendorUserRoles | null): any {
  return value as any;
}
