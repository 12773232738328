import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEntitiesToDelete } from '../../../store/dataRoom/dataRoomSlice';
import { deleteEntities } from '../../../store/dataRoom/dataRoomThunks';
import { DataRoomEntityModelTypeEnum } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import { DataRoomFiles } from '../DataRoomFiles/DataRoomFiles';
import { DataRoomEntitiesTree } from '../DataRoomEntitiesTree/DataRoomEntitiesTree';
import { DataRoomEditEntityModal } from '../DataRoomEditEntityModal/DataRoomEditEntityModal';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import './DataRoom.scss';
import { DataRoomMoveEntitiesModal } from '../DataRoomMoveEntitiesModal/DataRoomMoveEntitiesModal';
import { AccessObject } from '../../../types/accessObject';
import { canFeature } from '../../../functions/access';
import { PaymentLandingPageChecker } from '../../../features/pricing/PaymentLandingPageChecker/PaymentLandingPageChecker';

export const dataRoomCss = 'dataRoom';

export const DataRoom: React.FC = () => {
  const dispatch = useDispatch();

  const canManageRootTree = canFeature(AccessObject.dataroom_root_tree_manage);

  const vendorId = useSelector(
    (state: ApplicationState) => state.vendors.currentVendor.id
  );

  const showEditEntityModal = useSelector(
    (state: ApplicationState) => state.dataRoom.showEditEntityModal
  );

  const entitiesToDelete = useSelector(
    (state: ApplicationState) => state.dataRoom.entitiesToDelete
  );

  const handleDelete = (entityIds: string[]) => {
    dispatch(deleteEntities(vendorId, entityIds, canManageRootTree));
  };

  const getEntityType = (type: DataRoomEntityModelTypeEnum) => {
    return type === DataRoomEntityModelTypeEnum.File ? 'file' : 'folder';
  };

  const clearEntitiesToDelete = () => {
    dispatch(setEntitiesToDelete([]));
  };

  const isFetchingFiles = useSelector(
    (state: ApplicationState) =>
      state.dataRoom.isFetchingFiles || state.dataRoom.isFetchingEntitiesTree
  );

  const isModalOpen = useSelector(
    (state: ApplicationState) =>
      state.dataRoom.showDeleteModal ||
      state.dataRoom.showEditEntityModal ||
      state.dataRoom.showMoveEntitiesModal
  );

  const showMoveEntitiesModal = useSelector(
    (state: ApplicationState) => state.dataRoom.showMoveEntitiesModal
  );

  return (
    <PaymentLandingPageChecker type="dataRoom">
      {isFetchingFiles && !isModalOpen ? <LoadingSpinner /> : null}
      <div
        className={dataRoomCss}
        style={{ display: isFetchingFiles && !isModalOpen ? 'none' : null }}
      >
        <ConfirmModal
          close={() => {
            clearEntitiesToDelete();
          }}
          headerText={`Delete ${getEntityType(
            entitiesToDelete[0]?.entityType
          )}`}
          description={`Are you sure you would like to delete ${getEntityType(
            entitiesToDelete[0]?.entityType
          )}:`}
          show={Boolean(entitiesToDelete.length > 0)}
          confirm={() => {
            handleDelete(entitiesToDelete.map(etd => etd.entityId));
            clearEntitiesToDelete();
          }}
        >
          {entitiesToDelete.map(etd => (
            <div key={etd.entityId} className={dataRoomCss + '--deleteItem'}>
              {etd.entityName}
            </div>
          ))}
        </ConfirmModal>
        <DataRoomEditEntityModal show={showEditEntityModal} />
        <div className={dataRoomCss + '--row'}>
          <DataRoomEntitiesTree />
        </div>
        <div className={dataRoomCss + '--divider'}></div>
        <div className={dataRoomCss + '--row'}>
          <DataRoomFiles vendorId={vendorId} />
        </div>
        <div className={dataRoomCss + '--divider'}></div>
        <DataRoomMoveEntitiesModal show={showMoveEntitiesModal} />
      </div>
    </PaymentLandingPageChecker>
  );
};
