import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValidEmailAddress } from '../../functions/isValidEmailAddress';
import {
  clearProfileSavedToast,
  closeProfileModal,
  initiateUserDeletion,
} from '../../store/user/userSlice';
import { updateProfile } from '../../store/user/userThunks';
import { fetchVendorUsers } from '../../store/vendors/vendorsThunks';
import { UserDetails } from '../../swagger';
import { ApplicationState } from '../../types/applicationState';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../AdoptechButton/AdoptechButton';
import { EditProfileFields } from '../EditProfileFields/EditProfileFields';
import { MessageToast } from '../MessageToast/MessageToast';
import { selectCurrentVendor } from '../../selectors/selectCurrentVendor';
import './EditProfileDrawer.scss';
import AdoptechHS from '../AdoptechHS/AdoptechHS';

export const EditProfileDrawer: React.FC = () => {
  const [showLastUserDeletionToast, setShowLastUserDeletionToast] =
    useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails>();

  const userState = useSelector((state: ApplicationState) => state.user);
  const currentVendor = useSelector(selectCurrentVendor);
  const users = useSelector((state: ApplicationState) => state.vendors.users);

  useEffect(() => {
    if (userState.isProfileModalShowing) {
      dispatch(fetchVendorUsers(currentVendor.id));
    }
  }, [userState.isProfileModalShowing]);

  useEffect(() => {
    setUserDetails(
      userState.userDetails ? userState.userDetails : { email: '' }
    );
  }, [userState]);

  const saveEnabled = userDetails && isValidEmailAddress(userDetails.email);

  const dispatch = useDispatch();

  const deleteUser = () => {
    if (users.length === 1) {
      setShowLastUserDeletionToast(true);
      return;
    }
    dispatch(initiateUserDeletion());
  };

  const save = () => {
    dispatch(updateProfile(userDetails, false));
    dispatch(closeProfileModal());
  };

  const Footer = (
    <>
      <AdoptechButton
        onClick={() => {
          dispatch(closeProfileModal());
        }}
      >
        Cancel
      </AdoptechButton>
      <AdoptechButton
        busy={userState.isUpdatingUserDetails}
        disabled={!saveEnabled}
        onClick={save}
        variant={AdoptechButtonVariant.Primary}
      >
        SAVE
      </AdoptechButton>
    </>
  );

  return (
    <React.Fragment>
      <MessageToast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => dispatch(clearProfileSavedToast())}
        show={userState.isProfileSavedToastShowing}
      >
        Your profile has been updated.
      </MessageToast>
      <MessageToast
        autohide
        delay={+process.env.REACT_APP_SHORT_TOAST_DELAY}
        onClose={() => setShowLastUserDeletionToast(false)}
        show={showLastUserDeletionToast}
      >
        It is not possible to delete this User, please contact Adoptech support.
      </MessageToast>

      <AdoptechHS
        title="Edit profile"
        show={userState.isProfileModalShowing}
        onClose={() => {
          dispatch(closeProfileModal());
        }}
        showConfirmationWarning={false}
        footer={Footer}
        extraClass="editProfileDrawer"
      >
        <>
          <EditProfileFields
            userDetails={userDetails}
            onChange={setUserDetails}
            disabledFields={['email']}
          />
          Please click here if you wish to
          <span
            className="editProfileDrawer--deleteLink"
            onClick={() => deleteUser()}
          >
            delete your account
          </span>
        </>
      </AdoptechHS>
    </React.Fragment>
  );
};
