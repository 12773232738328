import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setControlFormModel } from '../../../../../store/compliance/complianceSlice';
import { ComplianceEditControlFormModel } from '../../../../../components/compliance/Types/complianceTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApplicationState } from '../../../../../types/applicationState';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { AdoptechTextArea2 } from '../../../../../components/AdoptechTextArea2/AdoptechTextArea2';
import AdoptechIcon from '../../AdoptechIcon/AdoptechIcon';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { selectIsViewModeControlDrawer } from '../../../../../selectors/compliance/selectIsViewModeEditControlDrawer';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { useAutoGrowTextArea } from '../../../../../hooks/useAutoGrowTextArea';

interface EditControlTreatmentPlanProps {
  control: ComplianceEditControlFormModel;
}

export const EditControlTreatmentPlan: React.FC<
  EditControlTreatmentPlanProps
> = props => {
  const baseCss = 'complianceEditControlForm';
  const dispatch = useDispatch();
  const { editControlFormModel, isPostingReviewHistoryItem } = useSelector(
    (state: ApplicationState) => state.compliance
  );
  const treatmentPlanRowNumber = 8;
  const handleTreatmentPlanChange = (e: {
    currentTarget: { value: string };
  }) => {
    dispatch(
      setControlFormModel({
        ...editControlFormModel,
        ...{
          formTouched: true,
          treatmentPlan: e.currentTarget.value,
        },
      })
    );
  };

  const isViewMode = useSelector(selectIsViewModeControlDrawer);

  const treatmentPlanRef = useRef<HTMLTextAreaElement>(null);
  useAutoGrowTextArea(treatmentPlanRef.current, props.control.treatmentPlan);
  return (
    <AdoptechTextArea2
      label="Treatment Plan"
      rounded
      ref={treatmentPlanRef}
      onChange={handleTreatmentPlanChange}
      value={props.control.treatmentPlan}
      placeholder="Add treatment plan description here"
      id="treatmentPlan"
      disabled={isViewMode}
      rows={treatmentPlanRowNumber}
    />
  );
};
