/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedEmploymentStatus,
  SharedEmploymentStatusFromJSON,
  SharedEmploymentStatusFromJSONTyped,
  SharedEmploymentStatusToJSON,
  VendorUserExternalIdentifier,
  VendorUserExternalIdentifierFromJSON,
  VendorUserExternalIdentifierFromJSONTyped,
  VendorUserExternalIdentifierToJSON,
  VendorUserRoles,
  VendorUserRolesFromJSON,
  VendorUserRolesFromJSONTyped,
  VendorUserRolesToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorUserCreatePayloadVendorUsers
 */
export interface VendorUserCreatePayloadVendorUsers {
  /**
   *
   * @type {string}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  position?: string;
  /**
   *
   * @type {Array<VendorUserRoles>}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  roles?: Array<VendorUserRoles>;
  /**
   *
   * @type {Array<VendorUserExternalIdentifier>}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  externalIdentifiers?: Array<VendorUserExternalIdentifier>;
  /**
   *
   * @type {string}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  employmentStartedAt?: string;
  /**
   *
   * @type {SharedEmploymentStatus}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  employmentStatus?: SharedEmploymentStatus;
  /**
   *
   * @type {string}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  employmentEndedAt?: string;
  /**
   *
   * @type {string}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  lineManagerId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof VendorUserCreatePayloadVendorUsers
   */
  vendorTeamsIds?: Array<string>;
}

export function VendorUserCreatePayloadVendorUsersFromJSON(
  json: any
): VendorUserCreatePayloadVendorUsers {
  return VendorUserCreatePayloadVendorUsersFromJSONTyped(json, false);
}

export function VendorUserCreatePayloadVendorUsersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorUserCreatePayloadVendorUsers {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    position: !exists(json, 'position') ? undefined : json['position'],
    roles: !exists(json, 'roles')
      ? undefined
      : (json['roles'] as Array<any>).map(VendorUserRolesFromJSON),
    externalIdentifiers: !exists(json, 'external_identifiers')
      ? undefined
      : (json['external_identifiers'] as Array<any>).map(
          VendorUserExternalIdentifierFromJSON
        ),
    employmentStartedAt: !exists(json, 'employment_started_at')
      ? undefined
      : json['employment_started_at'],
    employmentStatus: !exists(json, 'employment_status')
      ? undefined
      : SharedEmploymentStatusFromJSON(json['employment_status']),
    employmentEndedAt: !exists(json, 'employment_ended_at')
      ? undefined
      : json['employment_ended_at'],
    lineManagerId: !exists(json, 'line_manager_id')
      ? undefined
      : json['line_manager_id'],
    vendorTeamsIds: !exists(json, 'vendor_teams_ids')
      ? undefined
      : json['vendor_teams_ids'],
  };
}

export function VendorUserCreatePayloadVendorUsersToJSON(
  value?: VendorUserCreatePayloadVendorUsers | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    first_name: value.firstName,
    last_name: value.lastName,
    position: value.position,
    roles:
      value.roles === undefined
        ? undefined
        : (value.roles as Array<any>).map(VendorUserRolesToJSON),
    external_identifiers:
      value.externalIdentifiers === undefined
        ? undefined
        : (value.externalIdentifiers as Array<any>).map(
            VendorUserExternalIdentifierToJSON
          ),
    employment_started_at: value.employmentStartedAt,
    employment_status: SharedEmploymentStatusToJSON(value.employmentStatus),
    employment_ended_at: value.employmentEndedAt,
    line_manager_id: value.lineManagerId,
    vendor_teams_ids: value.vendorTeamsIds,
  };
}
